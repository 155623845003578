import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Flex, Link, Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import Text from '../common/text'
import { darkSun } from '../constants/colors'
import { textFont } from '../constants/typography'

import Jackpot from './jackpot'

const Ribbon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 68px;
  height: 68px;
  text-align: center;
  white-space: nowrap;
`

const RibbonLabel = styled.div`
  margin-left: -25%;
  margin-right: -25%;
  padding: 2px 10px;
  color: #776915;
  font-family: ${textFont};
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  vertical-align: middle;
  background-color: ${darkSun};
  transform: rotateZ(45deg) translate(17px, 7px);
`

const LargeRibbonLabel = styled.div`
  background-color: ${darkSun};
  color: #776915;
  font-family: ${textFont};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-right: 1em;
  padding-left: 0.3em;
  text-align: center;
  display: inline-block;
`
const LargeRibbonWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 0;
  left: 0;
  pointer-events: none;
`
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  &:hover .login-overlay-wrapper,
  &:active .login-overlay-wrapper {
    transform: translateY(0);
    opacity: 1;
  }
`

const Placeholder = styled(Wrapper)`
  box-sizing: border-box;
  border: 2px dashed #858585;
  height: 156px;
`

const Image = styled.div`
  display: block;
  width: 100%;
  /* Force browser to preserve image’s intrinsic aspect ratio */
  height: auto;
  min-height: 156px;
  background-image: url(${(props) => props.backgroundImageSrc});
  background-size: cover;
  background-position: center;
`

const JackpotWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  pointer-events: none;
`

const Bar = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
`

const BarValue = styled.div`
  padding: 1ex;
  display: flex;
  justify-content: center;
  flex: auto;
  color: #ffffff;
  line-height: 1em;
  background-color: #000000;

  &:empty {
    background-color: transparent;
  }
`

const PreReleaseWrapper = styled.div`
  position: relative;
`

const PreRelease = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  color: white;
  text-align: center;
`

const PreReleaseText = styled.span`
  margin-top: 40%;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  width: 100%;
  background: linear-gradient(-135deg, #f99537 0%, #db5507 100%);
`

const LoginOverlayWrapper = styled(Link)`
  position: absolute;
  padding: inherit;
  width: 100%;
  height: 100%;
  top: 0;
  transform: translateY(-100%);
  left: 0;
  opacity: 0;
  transition: all 0.2s 0.3s ease;
  color: white;
  z-index: 1;
`

const LoginOverlay = styled(Flex)`
  background: rgba(0, 0, 0, 0.75);
  height: inherit;
`

const StyledText = styled.div`
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
`

function GameTile(props) {
  const value =
    props.valueType && `${props.valueCount} ${props.valueType}`.trim()

  if (props.placeholder) {
    return <Placeholder />
  }

  const gameTile = (
    <React.Fragment>
      <Wrapper>
        {props.loginRequired && (
          <LoginOverlayWrapper to="/register" className="login-overlay-wrapper">
            <LoginOverlay column alignItems="center" justifyContent="center">
              <Space all={3}>
                <Text align="center" size="12">
                  {props.translate('live-casino.prompt-login-text')}
                </Text>
              </Space>
              <Space top={3}>
                <Button small variant="primary">
                  {props.translate('live-casino.prompt-login-button')}
                </Button>
              </Space>
            </LoginOverlay>
          </LoginOverlayWrapper>
        )}

        <Image backgroundImageSrc={props.imgSrc} />

        {R.path(['ribbon'], props.decorations) && (
          <Ribbon>
            <RibbonLabel>
              {props.translate(props.decorations.ribbon)}
            </RibbonLabel>
          </Ribbon>
        )}
        {R.path(['label'], props.decorations) && (
          <LargeRibbonWrapper>
            <LargeRibbonLabel>
              {props.translate(props.decorations.label)}
            </LargeRibbonLabel>
          </LargeRibbonWrapper>
        )}

        {props.jackpotValue > 0 && (
          <JackpotWrapper>
            <Jackpot
              jackpotUpdatedAt={props.jackpotUpdatedAt}
              value={props.jackpotValue}
              velocity={props.jackpotVelocity}
            />
          </JackpotWrapper>
        )}
        <Bar>
          <BarValue>{value}</BarValue>
        </Bar>
      </Wrapper>
      {props.title && (
        <Space y={3}>
          <Text color="white" line-height="1.2">
            <StyledText>{props.title}</StyledText>
          </Text>
          {props.provider && (
            <Space top={1}>
              {/* TODO: swap color on provider text for #828c9d or #ffffff with opacity 0.5 when new darker background color #06193c is used as part of landing page changes. */}
              <Text color="dark60">
                <StyledText>{props.provider}</StyledText>
              </Text>
            </Space>
          )}
        </Space>
      )}
    </React.Fragment>
  )

  if (props.preRelease) {
    return (
      <PreReleaseWrapper>
        {gameTile}
        <PreRelease>
          <PreReleaseText>{props.preRelease}</PreReleaseText>
        </PreRelease>
      </PreReleaseWrapper>
    )
  }

  return props.linkTo && !props.loginRequired ? (
    <Link to={props.linkTo}>{gameTile}</Link>
  ) : (
    gameTile
  )
}

const connector = R.compose(withTranslate)

export default connector(GameTile)

// --

GameTile.defaultProps = {
  jackpotValue: 0,
  jackpotVelocity: 0,
}

GameTile.propTypes = {
  decorations: PropTypes.object,
  imgSrc: PropTypes.string,
  title: PropTypes.string,
  jackpotValue: PropTypes.number,
  jackpotVelocity: PropTypes.number,
  jackpotUpdatedAt: PropTypes.number,
  linkTo: PropTypes.object,
  loginRequired: PropTypes.bool,
  placeholder: PropTypes.bool,
  preRelease: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  provider: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  valueCount: PropTypes.number,
  valueType: PropTypes.oneOf(['freebets', 'freespins']),
}
