import * as R from 'ramda'
import * as ReduxEffectsLocalstorage from 'redux-effects-localstorage'
import * as ReduxEffects from 'redux-effects'
import * as ReduxActions from 'redux-actions'
import * as Reselect from 'reselect'
import * as DateFns from 'date-fns'
import * as ReduxEffectsLocation from 'redux-effects-location'
import * as ReactRouterRedux from 'react-router-redux'
import url from 'url'

import * as Notifications from '@rushplay/notifications'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Websockets from '@rushplay/websockets'
import * as Analytics from '@rushplay/analytics'
import * as Locks from '@rushplay/compliance/locks'
import * as Processes from '@rushplay/processes'
import * as ApiClient from '@rushplay/api-client'
import * as Session from '@rushplay/session'
import * as I18n from '@rushplay/i18n'
import * as Store from '@rushplay/store'
import Inventory from '@rushplay/inventory'

import * as ProcessIds from '../constants/processes'
import * as Campaigns from '../store/campaigns'
import * as Config from '../constants/config'
import Triggers from '../store/triggers/'
import { waitRegister } from '../store/sign-up'

import * as Lookup from './lookup'
import * as Player from './player'
import { generateGamerUrl } from './generate-gamer-url'
import { generateGraphqlProxyUrl } from './generate-graphql-proxy-url'
import { generatePayerUrl } from './generate-payer-url'

const COUNTRIES_UPDATED = 'casino-heroes/app/COUNTRIES_UPDATED'
const CLEAR_APP = 'casino-heroes/app/CLEAR_APP'
const CONFIG_UPDATE = 'casino-heroes/app/CONFIG_UPDATE'
const COUNTRY_CODE_UPDATE = 'casino-heroes/app/COUNTRY_CODE_UPDATE'
const LANGUAGE_CHANGE = 'casino-heroes/app/LANGUAGE_CHANGE'
export const LOGIN_SUCCESS = 'casino-heroes/app/LOGIN_SUCCESS'
export const LOGIN_FAIL = 'casino-heroes/app/LOGIN_FAIL'
const METADATA_UPDATED = 'casino-heroes/app/METADATA_UPDATED'
const SET_SERVER_TIME_OFFSET = 'casino-heroes/app/SET_SERVER_TIME_OFFSET'
const UPDATE_IS_LOGIN_IN_PROGRESS =
  'casino-heroes/app/UPDATE_IS_LOGIN_IN_PROGRESS'
const STORE_USERNAME = 'casino-heroes/app/STORE_USERNAME'
const STORE_AFFILIATE_DATA = 'casino-heroes/app/STORE_AFFILIATE_DATA'
const STORE_AVATAR_URL = 'casino-heroes/app/STORE_AVATAR_URL'
const STORE_UTM_SOURCE = 'casino-heroes/app/STORE_UTM_SOURCE'
const STORE_UTM_MEDIUM = 'casino-heroes/app/STORE_UTM_MEDIUM'
const STORE_UTM_CAMPAIGN = 'casino-heroes/app/STORE_UTM_CAMPAIGN'
const STORE_REFERRALS = 'casino-heroes/app/STORE_REFERRALS'
const STORE_LOGGED_IN_ONCE = 'casino-heroes/app/STORE_LOGGED_IN_ONCE'
const WAIT_DASHBOARD = 'casino-heroes/app/WAIT_DASHBOARD'
const TOGGLE_NATIVE_APP_MODE = 'casino-heroes/app/TOGGLE_NATIVE_APP_MODE'
const QUICK_DEPOSIT_UPDATE_VISIBLE =
  'casino-heroes/app/QUICK_DEPOSIT_UPDATE_VISIBLE'
const QUICK_DEPOSIT_UPDATE_SUCCESS =
  'casino-heroes/app/QUICK_DEPOSIT_UPDATE_SUCCESS'
const SET_MOBILE_MENU_VISIBILITY =
  'casino-heroes/app/SET_MOBILE_MENU_VISIBILITY'
const LOCATION_CHANGE = '@@router/LOCATION_CHANGE'

export function updateCountries(payload) {
  return {
    type: COUNTRIES_UPDATED,
    payload,
  }
}

export function quickDepositVisible(isVisible) {
  return {
    type: QUICK_DEPOSIT_UPDATE_VISIBLE,
    payload: isVisible,
  }
}

export function quickDepositSuccess(isEnabled) {
  return {
    type: QUICK_DEPOSIT_UPDATE_SUCCESS,
    payload: isEnabled,
  }
}

export function changeLanguage(language) {
  return {
    type: LANGUAGE_CHANGE,
    payload: language,
  }
}

export function updateConfig(payload) {
  return {
    type: CONFIG_UPDATE,
    payload,
  }
}

export function loginSuccess() {
  return {
    type: LOGIN_SUCCESS,
    payload: {},
  }
}

export function setMobileMenuVisibility(payload) {
  return {
    type: SET_MOBILE_MENU_VISIBILITY,
    payload,
  }
}

export function loginFail(reason) {
  return {
    type: LOGIN_FAIL,
    payload: reason,
  }
}

export function handleLoginErrorMessage(res) {
  const error = res
  const path = R.path(['errors', 'base', 0], error)

  if (path.errorCode === 'loginFailed') {
    return R.mergeRight(error, {
      message: `errors.${path.errorCode}`,
      level: 'error',
    })
  }

  if (R.has('reason', path)) {
    if (
      R.includes(path.reason, [
        'id_check_failed',
        'self_exclusion',
        'spelpaus',
        'timeout',
        'no_verification',
        'gamstop',
        'login_time_limit_exceeded',
        'other',
      ])
    ) {
      return R.mergeRight(error, {
        message: `errors.${path.errorCode}.${path.reason}`,
        variables: {
          expiresAt: DateFns.format(
            R.pathOr('', ['expiresAt'], path),
            'DD.MM.YYYY'
          ),
          limitPeriod: R.pathOr('', ['limitPeriod'], error),
          limitValue: R.pathOr('', ['limitValue'], error) / 6000,
        },
        level: 'error',
      })
    }
  }
  return error
}

export const storeUsername = ReduxActions.createAction(STORE_USERNAME)
export const storeAffiliateData =
  ReduxActions.createAction(STORE_AFFILIATE_DATA)
export const storeAvatarUrl = ReduxActions.createAction(STORE_AVATAR_URL)
export const storeReferrals = ReduxActions.createAction(STORE_REFERRALS)
export const storeLoggedInOnce = ReduxActions.createAction(STORE_LOGGED_IN_ONCE)
export const updateIsLoginInProgress = ReduxActions.createAction(
  UPDATE_IS_LOGIN_IN_PROGRESS
)
export const waitDashboard = ReduxActions.createAction(WAIT_DASHBOARD)

export function storeUtmSource(utmSource) {
  return [
    ReduxEffectsLocalstorage.setItem(
      Config.utmSourceSessionStorageKey,
      utmSource,
      ReduxEffectsLocalstorage.STORAGE_TYPE.session
    ),
    {
      type: STORE_UTM_SOURCE,
      payload: utmSource,
    },
  ]
}

export function storeUtmMedium(utmMedium) {
  return [
    ReduxEffectsLocalstorage.setItem(
      Config.utmMediumSessionStorageKey,
      utmMedium,
      ReduxEffectsLocalstorage.STORAGE_TYPE.session
    ),
    {
      type: STORE_UTM_MEDIUM,
      payload: utmMedium,
    },
  ]
}

export function storeUtmCampaign(utmCampaign) {
  return [
    ReduxEffectsLocalstorage.setItem(
      Config.utmCampaignSessionStorageKey,
      utmCampaign,
      ReduxEffectsLocalstorage.STORAGE_TYPE.session
    ),
    {
      type: STORE_UTM_CAMPAIGN,
      payload: utmCampaign,
    },
  ]
}

export function login(username, password, clientType, redirectTo) {
  return [
    updateIsLoginInProgress(true),
    ApiClient.login(username, password, clientType, {
      success: (res) => [
        handleSessionInitialization(res, redirectTo || true),
        loginSuccess(),
      ],
      failure: (res) => [
        Notifications.add(handleLoginErrorMessage(res.value)),
        updateIsLoginInProgress(false),
      ],
      version: 2,
    }),
  ]
}

function redirectAfterLogin(balanceCents, hasSignedUp, shouldRedirect) {
  if (hasSignedUp && typeof shouldRedirect === 'string') {
    return ReactRouterRedux.push(shouldRedirect)
  }

  if (balanceCents < 1000) {
    return ReactRouterRedux.push('/wallet/deposit')
  }

  if (typeof shouldRedirect === 'string') {
    return ReactRouterRedux.push(shouldRedirect)
  }

  return ReactRouterRedux.push('/casino')
}

function redirectIfPhoneNumberInvalid(sessionResponse, res) {
  if (
    res.status === 401 &&
    R.equals(
      R.path(['value', 'player', 'requiresPhoneValidation'], sessionResponse),
      'restricted'
    )
  ) {
    // requires phone validation
    return ReactRouterRedux.push('/phone-verification')
  }

  return Notifications.add({
    message: res.value.message || 'error.generic',
    level: 'error',
  })
}

export function restoreSession(shouldRedirect) {
  const handleToken = handleGetTokenSuccess(shouldRedirect)

  return ReduxEffects.bind(ReduxEffectsLocation.getUrl(), (location) => {
    const { sessionId } = url.parse(location, true, true).query
    return sessionId
      ? handleToken(sessionId)
      : ReduxEffects.bind(
          ReduxEffectsLocalstorage.getItem(
            'authorizationToken',
            ReduxEffectsLocalstorage.STORAGE_TYPE.session
          ),
          handleToken
        )
  })
}

export function clearApp() {
  return {
    type: CLEAR_APP,
  }
}

export function setServerTimeOffset(serverTimeOffset) {
  return {
    type: SET_SERVER_TIME_OFFSET,
    payload: serverTimeOffset,
  }
}

function handleGetTokenSuccess(shouldRedirect) {
  return (token) => {
    if (token == null) {
      return [Session.session.update(), raiseTrustlyError()]
    }

    const config = {
      success: (res) => handleSessionInitialization(res, shouldRedirect),
      failure: () => [raiseTrustlyError(), Session.session.update()],
      version: 1,
      token,
    }

    return ApiClient.fetchSession(config)
  }
}

export function updateCountryCode(payload) {
  return {
    type: COUNTRY_CODE_UPDATE,
    payload,
  }
}

export function toggleNativeAppMode(payload) {
  return { type: TOGGLE_NATIVE_APP_MODE, payload }
}

function raiseTrustlyError() {
  return ReduxEffects.bind(ReduxEffectsLocation.getUrl(), (pathname) => {
    const urlObj = url.parse(pathname, true, true)

    if (urlObj.query.errMsg) {
      if (urlObj.query.errMsg === 'ERR_DECLINED_LIMIT_OVERDRAWN') {
        return Notifications.add({
          message: 'payment-providers.errors.limit-overdrawn',
          level: 'error',
        })
      }

      try {
        const type = R.path(
          ['errors', 'base', 0, 'reason'],
          JSON.parse(urlObj.query.errMsg)
        )
        return Notifications.add({ message: `error.${type}`, level: 'error' })
      } catch (error) {
        return Notifications.add({ message: 'error.generic', level: 'error' })
      }
    }
  })
}

/**
 * Fetch all player-related data and update session
 * @param {object} sessionResponse
 * @param {boolean} shouldRedirect
 * @return redux-effects action
 */
export function handleSessionInitialization(
  sessionResponse,
  shouldRedirect,
  isWaitingRegister
) {
  const token = sessionResponse.value.token
  const shouldRedirectToSportPage =
    sessionResponse.value?.player?.segments?.productSegment === 'Sports' ||
    sessionResponse.value?.player?.segments?.productSegment === 'Sports&Casino'
  const isHorseRacing =
    window.location?.pathname?.startsWith('/ja/horse-racing')

  return [
    changeLanguage(getPlayerLanguage(sessionResponse.value.player)),
    Locks.fetch({ token }),
    Inventory.newItems.actions.update(
      sessionResponse.value.player.inventoryNotifications
    ),
    Processes.start(ProcessIds.FETCH_PLAYER_INFO),
    ApiClient.fetchPlayerInfo({
      version: 2,
      token,
      success: (playerInfoResponse) => [
        Player.updatePlayerInfo(playerInfoResponse.value.result),
        Processes.stop(ProcessIds.FETCH_PLAYER_INFO),
        isWaitingRegister && waitRegister(false),
      ],
      failure: (res) => [
        Session.session.store(sessionResponse.value),
        redirectIfPhoneNumberInvalid(sessionResponse, res),
        updateIsLoginInProgress(false),
        Processes.stop(ProcessIds.FETCH_PLAYER_INFO),
      ],
    }),
    ApiClient.fetchInventory({
      token,
      success: (res) => Inventory.items.actions.update(res.value),
      version: 1,
      failure: (res) =>
        Notifications.add({
          message: res.value.message || 'error.generic',
          level: 'error',
        }),
    }),
    ApiClient.fetchAllNotifications({
      token,
      success: (res) => {
        const signupNotification = R.find(
          R.both(R.propEq('kind', 'analytics'), R.propEq('event', 'signup')),
          res.value
        )

        return [
          Triggers.actions.update(res.value),
          Session.session.store(sessionResponse.value),
          signupNotification &&
            ApiClient.deleteNotification(signupNotification.id, {
              success: Analytics.register,
              version: 1,
            }),
          !isHorseRacing &&
            shouldRedirect &&
            redirectAfterLogin(
              sessionResponse.value.player.account.balanceCents,
              signupNotification,
              shouldRedirect
            ),
          shouldRedirectToSportPage && ReactRouterRedux.push('/sports'),
          updateIsLoginInProgress(false),
          isWaitingRegister && waitRegister(false),
        ]
      },
      // TODO: log in sequence should fail
      failure: (res) => [
        Notifications.add({
          message: res.value.message || 'error.generic',
          level: 'error',
        }),
        Session.session.store(sessionResponse.value),
        shouldRedirect &&
          redirectAfterLogin(sessionResponse.value.player.account.balanceCents),
        updateIsLoginInProgress(false),
        isWaitingRegister && waitRegister(false),
      ],
      version: 1,
    }),
    ApiClient.fetchStoreItems({
      success: (data) => Store.update(data.value),
      failure: (res) =>
        Notifications.add({
          message: res.value.message || 'error.generic',
          level: 'error',
        }),
      version: 1,
      token,
    }),
    Campaigns.clearCampaigns(),
    ApiClient.fetchAllActiveCampaigns({
      success: (data) =>
        Campaigns.updateCampaigns(R.indexBy(R.prop('id'), data.value)),
      failure: (res) =>
        Notifications.add({
          message: res.value.message || 'error.generic',
          level: 'error',
        }),
      version: 2,
      token,
    }),
    Analytics.authenticate(),
    Player.setPlayerRequiresInfo(sessionResponse.value),
    ReduxEffectsLocalstorage.setItem(
      'USERNAME',
      sessionResponse.value.player.email
    ),
    ReduxEffectsLocalstorage.setItem(
      'AVATAR_URL',
      sessionResponse.value.player.avatarImageUrl
    ),
    ReduxEffectsLocalstorage.setItem(
      'LANGUAGE',
      sessionResponse.value.player.language
    ),
    ReduxEffectsLocalstorage.setItem(
      'DEPOSITED',
      sessionResponse.value.player.deposited
    ),
    storeUsername(sessionResponse.value.player.email),
    raiseTrustlyError(),
    Notifications.dismissAll(),
  ]
}

export function updateMetadata(payload) {
  return {
    type: METADATA_UPDATED,
    payload,
  }
}

export function fetchMetadata(url) {
  return ApiClient.fetchSeoDescription(
    { url },
    {
      success: (res) =>
        updateMetadata({
          title: R.pathOr('', ['result', 'title'], res.value),
          description: R.path(['result', 'description'], res.value),
        }),
      version: 1,
    }
  )
}

const LICENSES = [
  {
    src: 'license.asset.mga',
    jurisdictions: ['mga'],
    url: '//www.authorisation.mga.org.mt/verification.aspx?lang=EN&company=792893a7-8e7d-45e9-bf35-d0c68853439e&details=1',
  },
  {
    src: 'license.asset.gamblingcommision',
    jurisdictions: ['ukgc'],
    url: 'https://secure.gamblingcommission.gov.uk/PublicRegister/Search/Detail/48300',
  },
  {
    src: 'license.asset.18plus',
    jurisdictions: ['mga', 'ukgc', 'curacao'],
    url: 'responsible-gaming',
  },
  {
    src: 'license.asset.ecogra',
    jurisdictions: ['ukgc', 'mga'],
    url: 'http://ecogra.org/ata/policies_procedures.php',
  },
  {
    src: 'license.asset.gamstop',
    jurisdictions: ['ukgc'],
    url: 'https://www.gamstop.co.uk/',
  },
]

const initialState = {
  config: {
    gamerUrl: generateGamerUrl(window.location.hostname),
    graphqlProxyUrl: generateGraphqlProxyUrl(window.location.hostname),
    payerUrl: generatePayerUrl(window.location.hostname),
  },
  // TODO: get from backend on boot
  countryCode: '',
  footerLinks: {
    help: [
      {
        label: 'footer.links.how-to-play',
        to: 'how-to-play',
      },
      {
        label: 'footer.links.payment-methods',
        to: 'payment-methods',
      },
      {
        label: 'footer.links.help',
        to: 'https://faq.casitabi.com',
      },
      {
        label: 'footer.links.chat',
        to: 'support-chat',
      },
      {
        label: 'footer.links.contact-by-email',
        to: '',
      },
    ],
    aboutUs: [
      {
        label: 'footer.links.company',
        to: 'about-us',
      },
      {
        label: 'footer.links.affiliate',
        to: 'https://sweetspotaffiliates.com/ja/',
      },
      {
        label: 'footer.links.ct-free',
        to: 'http://casitabifree.com/',
      },
    ],
    security: [
      {
        label: 'footer.links.responsibleGaming',
        to: 'responsible-gaming',
      },
      {
        label: 'footer.links.terms',
        to: 'terms-conditions',
      },
      {
        label: 'footer.links.terms-sport',
        to: 'terms-conditions-sport',
      },
      {
        label: 'footer.privacy-policy',
        to: 'privacy-policy',
      },
      {
        label: 'footer.links.bonusTerms',
        to: 'bonus-terms',
      },
    ],
  },
  isLoginInProgress: false,
  language: '',
  mainMenuVisibility: false,
  seenNotifications: 0,
  waitDashboard: false,
  nativeApp: {
    isNativeApp: false,
    hasTouchId: false,
  },
  quickDepositVisible: false,
  quickDepositSuccess: false,
  userNavItems: [
    {
      key: 'user-dashboard.edit-details',
      icon: 'edit-board',
      to: '/dashboard/edit-details',
    },
    {
      key: 'user-dashboard.password',
      icon: 'edit-password',
      to: '/dashboard/password',
    },
    {
      key: 'user-dashboard.limits',
      icon: 'limits',
      to: '/dashboard/limits',
    },
    {
      key: 'user-dashboard.history',
      icon: 'history',
      to: '/dashboard/history',
    },
  ],
}

export function appReducer(state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case Websockets.SESSION_EXPIRED:
    case CLEAR_APP: {
      return Object.assign({}, state, {
        mainMenuVisibility: initialState.mainMenuVisibility,
        campaignInfoVisible: initialState.campaignInfoVisible,
      })
    }
    case CONFIG_UPDATE: {
      return Object.assign({}, state, {
        config: R.merge(R.prop('config', state), payload),
      })
    }
    case COUNTRY_CODE_UPDATE: {
      return Object.assign({}, state, { countryCode: payload })
    }
    case COUNTRIES_UPDATED: {
      return Object.assign({}, state, { countries: payload })
    }
    case LANGUAGE_CHANGE: {
      return Object.assign({}, state, { language: payload })
    }
    case METADATA_UPDATED: {
      return R.assocPath(['config', 'metadata'], action.payload, state)
    }
    case SET_SERVER_TIME_OFFSET: {
      return Object.assign({}, state, { serverTimeOffset: payload })
    }
    case UPDATE_IS_LOGIN_IN_PROGRESS: {
      return Object.assign({}, state, { isLoginInProgress: payload })
    }
    case STORE_USERNAME: {
      return Object.assign({}, state, { username: payload })
    }
    case STORE_AFFILIATE_DATA: {
      return Object.assign({}, state, { affiliateData: payload })
    }
    case STORE_AVATAR_URL: {
      return Object.assign({}, state, { avatarUrl: payload })
    }
    case STORE_UTM_SOURCE: {
      return Object.assign({}, state, { utmSource: payload })
    }
    case STORE_UTM_MEDIUM: {
      return Object.assign({}, state, { utmMedium: payload })
    }
    case STORE_UTM_CAMPAIGN: {
      return Object.assign({}, state, { utmCampaign: payload })
    }
    case STORE_REFERRALS: {
      return Object.assign({}, state, { referrals: payload })
    }
    case STORE_LOGGED_IN_ONCE: {
      return Object.assign({}, state, { loggedInOnce: payload })
    }
    case SET_MOBILE_MENU_VISIBILITY: {
      return Object.assign({}, state, { mainMenuVisibility: payload })
    }
    case LOCATION_CHANGE: {
      return Object.assign({}, state, { mainMenuVisibility: false })
    }
    case WAIT_DASHBOARD: {
      return Object.assign({}, state, { waitDashboard: payload })
    }
    case TOGGLE_NATIVE_APP_MODE: {
      return Object.assign({}, state, { nativeApp: payload })
    }
    case QUICK_DEPOSIT_UPDATE_VISIBLE: {
      return R.mergeRight(state, { quickDepositVisible: payload })
    }
    case QUICK_DEPOSIT_UPDATE_SUCCESS: {
      return R.mergeRight(state, { quickDepositSuccess: payload })
    }
    case Processes.UPDATED: {
      return R.over(
        R.lensProp('waiting'),
        (state) => Processes.reducer(state, action),
        state
      )
    }
    default: {
      return state
    }
  }
}

export function getConfig(app) {
  return app.config
}

/**
 * Query CDN asset path
 * @param {object} app
 * @returns {?string}
 */
export function getAssetPath(app) {
  const config = getConfig(app)
  if (config && config.cdnHost && config.cdnPrefix) {
    return `${config.cdnHost}/${config.cdnPrefix}`
  }
}

export function getCasinoLinks(app) {
  return R.path(['config', 'casinoLinks'], app)
}

export function getClientType() {
  const isTouchDevice =
    'ontouchstart' in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0

  return isTouchDevice ? 'mobile-browser' : 'browser'
}

export function getXcmSealScriptId(state) {
  return R.path(['xcmSealScriptId'], getConfig(state))
}

export function getCountryCode(app) {
  return app.countryCode
}

export function getIsIpInternal(app) {
  return R.prop('internal', getConfig(app))
}

export function getIsLoginInProgress(app) {
  return R.prop('isLoginInProgress', app)
}

export function getPusherKey(app) {
  return R.path(['pusher', 'key'], getConfig(app))
}

export function getLiveCasinoChannelKey(app) {
  return R.path(['pusher', 'liveCasinoChannel'], getConfig(app))
}

function getRawPusherConfig(app) {
  return R.path(['pusher'], getConfig(app))
}

export const getPusherConfig = Reselect.createSelector(
  [getRawPusherConfig],
  (config) =>
    R.mergeRight(config, {
      authEndpoint: `${ApiClient.getApiUrl(
        window.location.hostname
      )}/pusher/auth`,
      encrypted: true,
    })
)

export function getPiqConfig(app) {
  return R.path(['config', 'piq'], app)
}

export function getSbTechConfig(app) {
  return R.pathOr('', ['sbtech'], getConfig(app))
}

export function isShuftiEnabled(app) {
  return R.path(['shufti', 'enabled'], getConfig(app))
}

export function getLanguage(app) {
  return app.language
}

export function getUnseenNotifications(app, total) {
  const count = total - app.seenNotifications
  return count > 0 ? count : 0
}

export function getUsername(app) {
  return app.username
}

export function getAffiliateInfo(app) {
  return R.path(['affiliateData', 'text'], app)
}

export function getAffiliateLogo(app) {
  return R.path(['affiliateData', 'logo'], app)
}

export function getAvatarUrl(app) {
  return app.avatarUrl
}

export function getUtmSource(app) {
  return R.path(['utmSource'], app)
}

export function getUtmMedium(app) {
  return R.path(['utmMedium'], app)
}

export function getUtmCampaign(app) {
  return R.path(['utmCampaign'], app)
}

export function getLoggedInOnce(app) {
  return app.loggedInOnce
}

export function getBrand(app) {
  return R.path(['config', 'brand'], app)
}

export function getFastTrackScript(state) {
  if (isProduction(state)) {
    return 'https://crm-lib.fasttrack-solutions.com/loader/fasttrack-crm.js'
  }

  return 'https://crm-lib-staging.fasttrack-solutions.com/loader/fasttrack-crm.js'
}

/**
 * Get brand currency
 *
 * @todo This is a cursed function with awful conditionals. In future we must
 * get currency for logget out player from backend.
 * See: https://github.com/RushPlay/casino-saga/issues/4412
 *
 * @param {Object} state Whole state tree
 * @return {string}
 */
export function getBrandCurrency(state) {
  if (getBrand(state.app) === 'casitabi') {
    return 'USD'
  }

  if (isUk(state)) {
    return 'GBP'
  }

  if (Lookup.getCountryCode(state.lookup) === 'SE') {
    return 'SEK'
  }

  // For the rest it is EUR
  return 'EUR'
}

export function isGdprJurisdiction(state) {
  return getBrand(state.app) !== 'casitabi'
}

export function isUk(state) {
  if (
    !R.includes('uk', R.pathOr([], ['config', 'supportedLanguages'], state.app))
  ) {
    return false
  }
  if (Session.isSessionActive(state.session)) {
    return Session.getCountryCode(state.session) === 'GB'
  }

  return Lookup.getCountryCode(state.lookup) === 'GB'
}

export function isNetherlands(state) {
  return (
    Lookup.getCountryCode(state.lookup) === 'NL' ||
    Session.getCountryCode(state.session) === 'NL'
  )
}

// TODO no need to check for casitabi after brands split
export function isCasitabi() {
  return true
}

export function getEnvironment(state) {
  return R.prop('environment', getConfig(state))
}

export function isProduction(state) {
  return R.propEq('environment', 'production', getConfig(state))
}

export function getQuickDepositVisible(app) {
  return app.quickDepositVisible
}

export function getQuickDepositSuccess(app) {
  return app.quickDepositSuccess
}

export function isWaiting(app, action) {
  const ids = Array.isArray(action) ? action : [action]
  return Processes.isRunning(app.waiting, { ids })
}

export function getSocialLinks(app) {
  return R.mergeRight(app.config.footer, {
    facebook:
      R.path(['config', 'footer', 'facebook', app.language], app) ||
      R.path(['config', 'footer', 'facebook', 'global'], app),
  })
}

export function getGameProviders(app) {
  return R.path(['config', 'footerSections', 'gameProviders'], app)
}

export function getPaymentProviders(app) {
  return R.path(['config', 'footerSections', 'paymentProviders'], app)
}

export function getSortedPaymentProviders(app, sort_order) {
  const paymentProviders = getPaymentProviders(app)

  return sort_order
    .map((item) =>
      paymentProviders?.find(
        (provider) =>
          provider.name
            .replace(/\s+/g, '')
            .replaceAll(/-/g, '')
            .replace(/([a-z])([A-Z])/g, '$1$2')
            .toLowerCase() === item
      )
    )
    .filter((item) => Boolean(item))
}

export function getServerTimeOffset(app) {
  return R.prop('serverTimeOffset', app)
}

export function getSocialMediaLinks(app) {
  return R.path(['config', 'footerSections', 'socialMedia'], app)
}

export function getSupportedLanguage(state, languages = []) {
  const supportedLanguages = getSupportedLanguages(state)
  return (
    R.find((language) => R.includes(language, supportedLanguages), languages) ||
    getDefaultLanguage(state)
  )
}

function getDefaultLanguage(state) {
  return getBrand(state.app) === 'casitabi' ? 'ja' : isUk(state) ? 'uk' : 'en'
}

export function getBaseLocale(state) {
  const ids = {
    en: 'en',
    de: 'de_DE',
    fi: 'fi_FI',
    uk: 'en_GB',
    no: 'nb_NO',
    sv: 'sv_SE',
    ja: 'jp',
  }

  return R.pathOr('en', [state.app.language], ids)
}

export function getLanguageByLocation(app) {
  const languageMappings = {
    DE: 'de',
    FI: 'fi',
    GB: 'uk',
    NO: 'no',
    SE: 'sv',
    JP: 'ja',
  }
  return R.pathOr('en', [app.countryCode], languageMappings)
}

function getPlayerLanguage(player) {
  if (player.countryCode === 'GB') {
    return 'uk'
  }
  return player.language
}

export function getSupportedLanguages(state) {
  const supportedLanguages = R.pathOr(
    [],
    ['config', 'supportedLanguages'],
    state.app
  )

  if (isUk(state)) {
    return R.filter(R.equals('uk'), supportedLanguages)
  }

  return R.reject(R.equals('uk'), supportedLanguages)
}

export function getShowBalanceTypeChangeMessageOnLaunch(state) {
  return Session.isSessionActive(state.session) && isUk(state)
}

export function getShowBalanceTypeChangeMessage(state) {
  if (!Session.isSessionActive(state.session) || !isUk(state)) {
    return false
  }
  return R.path(['player', 'showBalanceTypeChangeMessage'], state) || false
}

export function getLicenses(state, props) {
  return {
    list: R.pipe(
      R.filter((l) => I18n.isExistingTranslation(l.src)),
      R.filter((l) => R.includes(props.jurisdiction, l.jurisdictions)),
      R.map((l) => R.assoc('src', l.src, l))
    )(LICENSES),
    showAppSeal: Jurisdiction.getLicense(state.jurisdiction) === 'curacao',
  }
}

export function getFooterLinks(state) {
  const links = R.pathOr({}, ['app', 'footerLinks'], state)

  return R.map((section) => {
    return R.filter((f) => {
      if (
        f.label === 'footer.links.limits' &&
        !Session.isSessionActive(state.session)
      ) {
        return false
      }

      if (f.label === 'footer.links.affiliate') {
        f.to = R.pathOr('#', ['config', 'footer', 'affiliate'], state.app)
      }

      if (f.label === 'footer.links.terms-sport' && !isSportsbookEnabled()) {
        return false
      }

      return I18n.isExistingTranslation(f.label)
    }, section)
  }, links)
}

export function getUserNavItems(state) {
  return (
    (R.not(hasLimits(state)) &&
      R.reject(
        (obj) => R.equals(obj.to, '/dashboard/limits'),
        state.app.userNavItems
      )) ||
    state.app.userNavItems
  )
}

export const hasLimits = Reselect.createSelector(
  [
    (state) => Jurisdiction.getRealityCheckConfigurability(state.jurisdiction),
    (state) => Jurisdiction.getSelfExclusionValues(state.jurisdiction),
    (state) =>
      Jurisdiction.getSessionLimitPeriods(state.jurisdiction, {
        type: 'loginTime',
      }),
    (state) => Jurisdiction.getMoneyLimitTypes(state.jurisdiction),
  ],
  (
    realityCheckConfigurability,
    selfExclusionValues,
    sessionLimitPeriods,
    moneyLimitTypes
  ) =>
    R.not(
      R.and(
        R.not(realityCheckConfigurability),
        R.isEmpty(selfExclusionValues),
        R.isEmpty(sessionLimitPeriods),
        R.isEmpty(moneyLimitTypes)
      )
    )
)

export function isSportsbookEnabled() {
  return I18n.isExistingTranslation('menu-links.sportsbook')
}

export function getHorseRacingEnabled() {
  return I18n.isExistingTranslation('menu-links.horse-racing')
}

export function isFeatureEnabled(state, props) {
  return R.includes(
    props.id,
    R.pathOr([], ['config', 'enabledFeatures'], state)
  )
}

export { start as startWaiting, stop as stopWaiting } from '@rushplay/processes'

export function getCurrency(state, props) {
  const country = R.find(R.propEq('alpha2', props.countryCode))(
    R.pathOr([], ['countries'], state.app)
  )
  return R.path(['currency'], country)
}

export function getLiveChatKey(state) {
  return R.path(['config', 'liveChatKey'], state)
}

const EMPTY_OBJECT = Object.freeze({})

export function getLiveChatDepartments(state) {
  return R.pathOr(EMPTY_OBJECT, ['config', 'liveChatDepartments'], state)
}

export const getLiveChatDepartment = Reselect.createSelector(
  [getLanguage, getLiveChatDepartments],
  (language, departments) => R.pathOr({}, [language], departments)
)

export function getMetadataTitle(state) {
  return R.path(['config', 'metadata', 'title'], state)
}

export function getMetadataDescription(state) {
  return R.path(['config', 'metadata', 'description'], state)
}

export function getReferrals(state) {
  return R.path(['referrals'], state)
}

export function getGamerUrl(state) {
  return R.pathOr('', ['config', 'gamerUrl'], state)
}

export function getGraphqlProxyUrl(state) {
  return R.pathOr('', ['config', 'graphqlProxyUrl'], state)
}

export function getPayerUrl(state) {
  return R.pathOr('', ['config', 'payerUrl'], state)
}

export function getMobileMenuVisibility(state) {
  return R.pathOr('', ['app', 'mainMenuVisibility'], state)
}
