export const berry = '#ce4c9b'
export const berryDark = '#a82675'
export const berryLight = '#f472c1'
export const blackish = '#373737'
export const dark = '#2a2a2a'
export const dark20 = '#5d5d5d'
export const dark40 = '#909090'
export const dark60 = '#c3c3c3'
export const dark80 = '#f6f6f6'
export const deepBerry = '#bf0056'
export const darkSun = '#f2da45'
export const error = '#8e0029'
export const errorSecondary = '#d0011b'
export const errorLightOld = '#ff4f65'
export const errorLight = '#ffacc4'
export const gradientBlue = 'linear-gradient(-135deg, #183363 0%, #2653A1 100%)'
export const gradientBlueDown =
  'linear-gradient(-180deg, #082049 0%, #2653A1 100%)'
export const navy = '#2f4774'
export const darkNavy = '#223d70'
export const whitestBlue = '#3f557d'
export const lightBlue = '#cdecf8'
export const lightBorder = '#2a436f'
export const lightGrey = '#d8d8d8'
export const mulberry = '#d34ead'
export const night = '#183363'
export const nightDark = '#000d3d'
export const nightLight = '#3e5989'
export const ocean = '#209ace'
export const oceanDark = '#0074a8'
export const oceanLight = '#46c0f4'
export const popupBg = '#ffe8c7'
export const popupBorder = '#9a3c00'
export const stone = '#7d838b'
export const success = '#84b41f'
export const successDark = '#5e8e00'
export const successLight = '#99d327'
export const gradientMetallic =
  'linear-gradient(-180deg, #ffffff 0%, #bdbdbd 84%)'
export const gradientOrange =
  'linear-gradient(-180deg, #FBDA61 0%, #F76B1C 100%)'
export const gradientOrangeDark =
  'linear-gradient(90deg, #F99537 0%, #DB5507 100%)'
export const gray = '#CCCCCC'
export const disabledGray = '#EBEBE4'
export const deepBlue = '#000E38'
export const gradientDeepBlueDown = `linear-gradient(180deg, ${deepBlue} 0%, #1e457c 100%)`
export const gradientWhiteTransparent = `radial-gradient(47.33% 36292.9% at 49.48% 50%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.12) 53.12%, rgba(255, 255, 255, 0) 100%)`
export const gradientTransparentTop = `linear-gradient(180deg, rgba(6, 25, 60, 0) 0%, #06193c 100%)`
export const warning = '#F1C40F'
export const warningPrimary = '#9F6000'
export const warningSecondary = '#FEEFB3'
export const successPrimary = '#84b41f'
export const successSecondary = '#DFF2BF'
export const white = '#FFFFFF'
export const black = '#000000'
export const navyDark = '#091e51'
export const green = '#019F66'

/* Colors below for new designs CT/CH 4.0 */

export const fabGreen = '#00ca1b'
export const fabGreenPale = '#33d448'

export const powderBlue = '#2b67d2'
export const powderBluePale = '#5585db'

export const skyBlue = '#0087e0'

export const yellow = '#ffe666'

export const pink = '#ce4c9b'

export const fabMagenta = '#e02b6c'

export const coralRed = '#ed5646'
export const coralRedPale = '#f0776b'

export const ghostGrey = '#e4e5e7'
export const blueGrey = '#6a758a'
export const blueGreyPale = '#8890a1'

export const blue = '#1c3e7b'
export const lightBlue1 = '#2B67D2'
export const darkBlue1 = '#0d2858'
export const darkBlue2 = '#06193c'
export const darkBlue3 = '#02102a'

// TODO: Tidy up colors: Remove unused colors; Unify dark-colored backgrounds to use just one color.
// TODO: Find places in this codebase where we import colors and change these to use the color function from @rushplay/theme
