import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Common from '@rushplay/common'
import * as Session from '@rushplay/session'
import styled from '@emotion/styled'

import { GameLauncher } from './casino/game-launcher'
import { PageSpinner } from './common/spinner'
import { generateGamerUrl } from './store/generate-gamer-url'
import { getClientType } from './store/app'

const StyledGameLauncher = styled(GameLauncher)`
  flex: 1 1 auto;
`

function useHorseRacingUrl() {
  const token = ReactRedux.useSelector((state) =>
    Session.getSessionToken(state.session)
  )

  const host = generateGamerUrl(window.location.hostname)
  const clientType = ReactRedux.useSelector((state) => {
    getClientType(state.app)
  })
  const origin = typeof window !== 'undefined' ? window?.location?.origin : null

  if (!origin || token === undefined) {
    return null
  }

  const url = new URL('/pages/hub88_horseracing/launch-url', host)
  url.searchParams.set('client_type', clientType)
  url.searchParams.set('language', 'ja-JP')
  url.searchParams.set('token', token === null ? '' : token)

  return url
}

export function HorseRacing() {
  const horseReacingUrl = useHorseRacingUrl()

  if (!horseReacingUrl) {
    return (
      <Common.Box width="100%">
        <PageSpinner />
      </Common.Box>
    )
  }

  return <StyledGameLauncher gameUrl={horseReacingUrl?.href} />
}
