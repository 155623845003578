import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Colors from '../../constants/colors'
import Arrow from '../../images/arrow.svg'
import Button from '../../common/button'

export function AffiliateBar(props) {
  const i18n = I18n.useI18n()

  React.useEffect(() => {
    props.onFetch(props.btag)
  }, [props.btag])

  if (!i18n.isExistingTranslation(props.affiliateInfo)) {
    return null
  }

  const isSignupPage = R.path(['params', 'step'], props) === 'create-account'

  return (
    <React.Fragment>
      <Common.Box
        as={!isSignupPage ? Common.Link : Common.Box}
        to="/register"
        display={['block', null, 'none']}
        width={1}
        backgroundColor={Colors.skyBlue}
      >
        <Common.Box
          maxWidth="1200px"
          margin="auto"
          display="flex"
          padding={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <Common.Box display="flex" alignItems="center">
            <Common.Box
              size="40px"
              minWidth="40px"
              backgroundImage={`url(${props.affiliateLogo})`}
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              marginRight={3}
            />
            <Common.Text fontWeight={700} fontSize={[0, 1]} lineHeight="normal">
              {i18n.translate(props.affiliateInfo)}
            </Common.Text>
          </Common.Box>
          {!isSignupPage && (
            <Common.Box
              size="16px"
              backgroundImage={`url(${Arrow})`}
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              paddingLeft={3}
            />
          )}
        </Common.Box>
      </Common.Box>

      <Common.Box
        display={['none', null, 'block']}
        width={1}
        backgroundColor={Colors.skyBlue}
      >
        <Common.Box
          maxWidth="1200px"
          margin="auto"
          display="flex"
          padding={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <Common.Box display="flex" alignItems="center">
            <Common.Box
              size="64px"
              minWidth="40px"
              backgroundImage={`url(${props.affiliateLogo})`}
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              marginRight="5"
            />
            <Common.Text
              fontWeight={700}
              fontSize={[null, null, 3, 4]}
              lineHeight="normal"
            >
              {i18n.translate(props.affiliateInfo)}
            </Common.Text>
          </Common.Box>
          {!isSignupPage && (
            <Common.Box>
              <Button
                as={Common.Link}
                to="/register"
                variant="primary"
                minimalPadding
              >
                {i18n.translate('landing-page.affiliate-bar.cta')}
              </Button>
            </Common.Box>
          )}
        </Common.Box>
      </Common.Box>
    </React.Fragment>
  )
}

AffiliateBar.propTypes = {
  affiliateInfo: PropTypes.string,
  affiliateLogo: PropTypes.string,
  btag: PropTypes.string,
  onFetch: PropTypes.func.isRequired,
}
