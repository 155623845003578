import * as ReactRedux from 'react-redux'
import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import * as App from '../store/app'
import * as CombinedSelectors from '../combined-selectors'
import * as Colors from '../constants/colors'

function BarLine(props) {
  return (
    <Common.Box
      position="absolute"
      borderRadius="1px"
      height="4px"
      backgroundColor={Colors.white}
      display="block"
      width="100%"
      top={props.offset ? `${props.offset}px` : '8px'}
      transition="transform 0.25s ease-out"
      opacity={props.opacity}
      style={{ transform: `rotate(${props.angle}deg)` }}
    />
  )
}

BarLine.defaultProps = {
  angle: 0,
}

BarLine.propTypes = {
  opacity: PropTypes.number,
  offset: PropTypes.number,
  angle: PropTypes.number,
}

const Notification = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  pointer-events: none;
  background-color: ${(props) => props.theme.variants.notification};
  border-radius: 50%;
  animation: ${keyframes`
    from {
      transform: scale3d(0, 0, 0);
    }
    to {
      transform: scale3d(1, 1, 1);
    }
  `} 0.5s 1 cubic-bezier(0.175, 0.885, 0.32, 1.175);
`

export function HamburgerMenu() {
  const hasNotifications = ReactRedux.useSelector(
    CombinedSelectors.getNotificationsState
  )
  const isMenuActive = ReactRedux.useSelector((state) =>
    App.getMobileMenuVisibility(state)
  )
  const dispatch = ReactRedux.useDispatch()

  return (
    <Common.Box
      onClick={() => dispatch(App.setMobileMenuVisibility(!isMenuActive))}
      cursor="pointer"
      padding="10px 10px 10px 0"
    >
      <Common.Box
        width="20px"
        height="20px"
        padding="5px"
        backgroundColor={Colors.nightDark}
        borderRadius="4px"
        position="relative"
        overflow="hidden"
      >
        <Common.Box position="relative">
          <BarLine offset={1} opacity={isMenuActive ? 0 : 1} />
          <BarLine angle={isMenuActive ? 45 : 0} />
          <BarLine angle={isMenuActive ? -45 : 0} />
          <BarLine offset={15} opacity={isMenuActive ? 0 : 1} />
        </Common.Box>
        {!isMenuActive && hasNotifications && <Notification />}
      </Common.Box>
    </Common.Box>
  )
}
