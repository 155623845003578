// TODO: import from payer when available
export const TransactionType = {
  AUTH: 'auth',
  DEPOSIT: 'deposit',
  WITHDRAWAL: 'withdrawal',
}

export const CookieKeys = {
  DEVICE_ID: '_hgdid',
  GID: '_gid',
}

export const CookieOptions = {
  // Hero Gaming Device ID
  // Used to track visitor’s device
  [CookieKeys.DEVICE_ID]: {
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
    httpOnly: false,
  },
}

export const DECIMAL_SEPARATORS = ['.', ',']
