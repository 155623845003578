import * as R from 'ramda'
import { createSelector } from 'reselect'

import * as processIds from '../constants/processes'
import triggers from '../store/triggers'

import { getActiveDepositCampaign as _getActiveDepositCampaign } from './campaigns'
import { getClaimedCampaigns as _getClaimedCampaigns } from './promotions'
import { getDepositCount } from './player'
import { isCasitabi, isUk, isWaiting } from './app'

export function bindSelector(key, selector) {
  return (state, props) => selector(state[key], props)
}

const getClaimedCampaigns = bindSelector('promotions', _getClaimedCampaigns)

export const getCasinoHeaderLinks = createSelector(
  [R.path(['app', 'config', 'casinoLinks']), isUk, isCasitabi],
  (allLinks, hideBlitz) =>
    R.reduce(
      (acc, link) => {
        if (link.label === 'casino.nav.blitz' && hideBlitz) {
          return acc
        } else {
          return R.append(link, acc)
        }
      },
      [],
      allLinks
    )
)

export const getActiveDepositCampaign = createSelector(
  [getClaimedCampaigns, R.prop('campaigns')],
  (claimed, campaigns) =>
    _getActiveDepositCampaign(R.omit(R.map(R.toString, claimed), campaigns))
)

export function selectCanAccessInventory(state) {
  return state.player.canAccessInventory
}

export const getInventoryLockedStatus = createSelector(
  [
    (state) => isWaiting(state.app, processIds.FETCH_PLAYER_INFO),
    R.compose(getDepositCount, R.prop('player')),
    (state) =>
      triggers.selectors.getByEvent('inventory_unlocked', state.triggers),
    (state) => selectCanAccessInventory(state),
  ],
  (
    isFetchingDepositCount,
    depositCount,
    unlockedNotification,
    canAccessInventory
  ) => {
    if (!isFetchingDepositCount) {
      if (unlockedNotification) {
        return 'unlocked'
      } else if (depositCount === 0 && !canAccessInventory) {
        return 'locked'
      }
    }
  }
)
