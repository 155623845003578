import PropTypes from 'prop-types'
import React from 'react'
import { v4 as generateUuid } from 'uuid'

import styled from '@emotion/styled'

import { night, success } from '../constants/colors'

import HtmlContent from './html-content'

const MarkerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80%"
    height="80%"
    viewBox="0 0 100 100"
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeDasharray="130"
      strokeDashoffset="130"
      strokeWidth="10"
      strokeMiterlimit="20"
      d="M15 60l25 20 45-60"
    >
      <animate
        attributeName="stroke-dashoffset"
        from="130"
        to="0"
        fill="freeze"
        dur="300ms"
      />
    </path>
  </svg>
)

const Wrapper = styled.div`
  position: relative;
  text-align: left;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`

const Marker = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 5px;
  border: 2px solid ${(props) => (props.checked ? success : night)};
  margin-right: 0.6em;
`

const Input = styled.input`
  display: none;
`

function LabelText(props) {
  if (typeof props.children === 'string') {
    return <HtmlContent html={{ __html: props.children }} />
  } else {
    return props.children
  }
}

LabelText.propTypes = {
  children: PropTypes.node,
}

function Checkbox(props) {
  const uuid = generateUuid()

  return (
    <Wrapper>
      <Input
        checked={props.value}
        id={uuid}
        type="checkbox"
        onChange={() => props.onChange(!props.value)}
      />
      <Label htmlFor={uuid}>
        <Marker checked={props.value}>{props.value && <MarkerIcon />}</Marker>
        <LabelText>{props.label}</LabelText>
      </Label>
    </Wrapper>
  )
}

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default Checkbox
