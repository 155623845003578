import * as PropTypes from 'prop-types'
import * as React from 'react'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

import LogOutLink from '../../log-out-link'
import LoginForm from '../../login-form/login-form-provider'
import { CurrentSessionTime } from '../../current-session-time'
import { headingFont } from '../../../constants/typography'
import { nightLight } from '../../../constants/colors'

import MenuLinks from './menu-links'
import UserDashboardLink from './user-dashboard-link'

const Wrapper = styled.div`
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  font-family: ${headingFont};
  font-size: 16px;
  font-weight: 300;
  border-top: 1px solid ${nightLight};
  color: white;
  padding-bottom: 100px;
`

const Divider = styled.div`
  height: 1px;
  background-color: ${nightLight};
`

const Section = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 16px 26px;
  max-width: 300px;
`

const MenuLinksSection = styled(Section)`
  padding-top: 20px;
  padding-bottom: 20px;
`

export class MobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this)
  }

  handleChangeLanguage(language) {
    const supportedLanguage = this.props.getSupportedLanguage([language])

    if (supportedLanguage === this.props.language) {
      return
    }

    this.props.setItem('LANGUAGE', supportedLanguage)

    if (this.props.authenticated) {
      this.props.onUpdateLanguage(supportedLanguage)
    }

    this.props.onChangeLanguage(supportedLanguage)
    this.props.redirectTo('/casino')
  }

  render() {
    return (
      <Wrapper isActive={this.props.isActive}>
        {this.props.authenticated ? (
          <Section>
            <UserDashboardLink
              userAvatar={this.props.userAvatar}
              displayName={this.props.displayName}
            />
          </Section>
        ) : (
          <Section>
            <LoginForm name="login" />
          </Section>
        )}
        <Divider />
        <MenuLinksSection>
          <MenuLinks
            authenticated={this.props.authenticated}
            isSportsbookEnabled={this.props.isSportsbookEnabled}
            isHorseRacingEnabled={this.props.isHorseRacingEnabled}
            license={this.props.license}
            promotionsCount={this.props.promotionsCount}
            treasureCount={this.props.treasureCount}
          />
        </MenuLinksSection>
        <Divider />
        <Common.Space top={4}>
          <CurrentSessionTime withTranslation />
        </Common.Space>
        {this.props.authenticated && (
          <div>
            <Section>
              <LogOutLink />
            </Section>
          </div>
        )}
      </Wrapper>
    )
  }
}

MobileMenu.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  displayName: PropTypes.string,
  footerLinks: PropTypes.array.isRequired,
  getSupportedLanguage: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isHorseRacingEnabled: PropTypes.bool,
  isSportsbookEnabled: PropTypes.bool,
  language: PropTypes.string.isRequired,
  license: PropTypes.string,
  licenses: PropTypes.object.isRequired,
  onChangeLanguage: PropTypes.func.isRequired,
  onLiveChatClick: PropTypes.func.isRequired,
  onUpdateLanguage: PropTypes.func.isRequired,
  promotionsCount: PropTypes.number,
  redirectTo: PropTypes.func.isRequired,
  setItem: PropTypes.func.isRequired,
  socialLinks: PropTypes.object.isRequired,
  supportedLanguages: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
  treasureCount: PropTypes.number,
  unreadChatNotifications: PropTypes.number,
  userAvatar: PropTypes.string,
}
