import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

export function GoogleTagManager(props) {
  const initialDataLayer = {
    'site.environment': props.environment,
    'site.version': props.version,
    'site.websiteId': props.language,
    'site.websiteName': props.websiteName,
    'user.acceptedCookies': props.cookieConsent,
    'user.isInternal': props.isIpInternal,
  }

  return (
    <Helmet>
      <script>
        {`
          window.dataLayer.push(${JSON.stringify(initialDataLayer)});
        `}
      </script>
      <script>
        {`document.addEventListener('DOMContentLoaded', () => {
            setTimeout(initGTM, 3500);
          });
          document.addEventListener('scroll', initGTMOnEvent);
          document.addEventListener('mousemove', initGTMOnEvent);
          document.addEventListener('touchstart', initGTMOnEvent);
          function initGTMOnEvent(event) {
            initGTM();
            // remove the event listener that got triggered
            event.currentTarget.removeEventListener(event.type, initGTMOnEvent);
          }
          function initGTM() {
            if (window.gtmDidInit) {
              return false;
            }
            window.gtmDidInit = true;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.onload = () => {
              dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime(), 'gtm.uniqueEventId': 0 });
            };
            script.src = 'https://www.googletagmanager.com/gtm.js?id=${props.gtmKey}';
            document.head.appendChild(script);
          }`}
      </script>
    </Helmet>
  )
}

GoogleTagManager.propTypes = {
  cookieConsent: PropTypes.bool.isRequired,
  environment: PropTypes.string.isRequired,
  gtmKey: PropTypes.string.isRequired,
  isIpInternal: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  websiteName: PropTypes.string.isRequired,
}
