import PropTypes from 'prop-types'
import React from 'react'

import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Colors from '../../constants/colors'
import FingerprintContainer from '../native-app/fingerprint-container'
import { ButtonWithStroke } from '../../common/button-with-stroke'
import { InputField } from '../forms/input-field'
import { fullWidthToHalfWidth } from '../forms/full-width-to-half-width'

import UsernameField from './username-field'

const FormWrapper = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: url('/images/sport-welcome-offer-background.svg'),
    linear-gradient(247.62deg, #e1e3e3 11.04%, #b4b4b4 51.71%, #c7c5cb 74.52%);
  opacity: 0.9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  height: 367px;
  margin: 0 auto;
  border: 2px solid #ffffff;
  box-shadow: 0 0 0 2px #06193c;
  ${css({
    mt: ['8px', null, '27px'],
    mb: ['-10px', null, '102px'],
    py: ['15px', '20px'],
    px: ['8px', '19px'],
    width: ['310px', '337px'],
  })};
`
const FormTitle = styled.h2`
  color: #000000;
  font-family: 'M PLUS 1p', sans-serif;
  font-weight: 500;
  font-size: 28px;
  text-align: center;
`
const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 18px;
  width: 100%;
`
const FieldWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
`
const RecoverPasswordWrapper = styled.div`
  text-align: end;
  padding-bottom: 20px;
`

export function LandingPageLoginForm(props) {
  const i18n = I18n.useI18n()

  return (
    <FormWrapper>
      <FormTitle>{i18n.translate('login-form.title')}</FormTitle>
      <FieldsWrapper>
        <FieldWrapper>
          <UsernameField
            returningUserAvatar={props.returningUserAvatar}
            returningUsername={props.returningUsername}
            isBig
          />
        </FieldWrapper>
        <FieldWrapper>
          <InputField
            dark
            autoComplete="password"
            inputMode="password"
            scope="#/properties/password"
            type="password"
            placeholder={i18n.translate('login-form.password')}
            normalize={fullWidthToHalfWidth}
            borderGrey
          />
        </FieldWrapper>
      </FieldsWrapper>
      <RecoverPasswordWrapper>
        <Common.Link to="/reset-password">
          <Common.Box
            as={Common.Text}
            color={Colors.black}
            textDecoration="underline"
            textDecorationColor={Colors.black}
            lineHeight="24px"
            fontFamily="'M PLUS 1p', sans-serif"
            fontWeight="400"
            fontSize="16px"
          >
            {i18n.translate('login-form.forgot-password')}
          </Common.Box>
        </Common.Link>
      </RecoverPasswordWrapper>
      <Common.Box
        display="flex"
        alignItems="center"
        justifyContent="enter"
        margin="0 auto"
      >
        <ButtonWithStroke
          variant="tertiary"
          type="submit"
          loading={props.isLoginInProgress}
        >
          {i18n.translate('login-form.login')}
        </ButtonWithStroke>
      </Common.Box>
      <FingerprintContainer />
    </FormWrapper>
  )
}

LandingPageLoginForm.propTypes = {
  clientType: PropTypes.string,
  isLoginInProgress: PropTypes.bool,
  returningUsername: PropTypes.string,
  returningUserAvatar: PropTypes.string,
}
