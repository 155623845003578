import PropTypes from 'prop-types'
import React from 'react'

import { Box } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import HtmlContent from '../../../common/html-content'
import LicenseLogos from '../../license-logos'

export function LegalInfo(props) {
  const i18n = useI18n()

  return (
    <Box
      display="flex"
      flexDirection={['column-reverse', null, null, null, 'row']}
      paddingTop={7}
    >
      <LicenseLogos licenses={props.licenses} license={props.licenseId} />
      <Box
        opacity="0.3"
        paddingLeft={['0px', null, null, null, 8]}
        paddingBottom={['17px', null, null, null, '0px']}
        fontSize={0}
        lineHeight="20px"
      >
        <HtmlContent
          html={{
            __html: i18n.isExistingTranslation(
              `footer.legalText.${props.licenseId}`
            )
              ? i18n.translate(`footer.legalText.${props.licenseId}`)
              : i18n.translate('footer.legalText'),
          }}
        />
      </Box>
    </Box>
  )
}

LegalInfo.propTypes = {
  licenses: PropTypes.object.isRequired,
  licenseId: PropTypes.string,
}
