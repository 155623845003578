import { normalizers } from '@rushplay/newForms'

/**
 * @param {string} [format] JSON schema format key
 * @param {Function} [normalizer] Normalizer
 * @returns {?Function} normalizer if exist, otherwise nothing
 */
export function getFieldNormalizer(format, normalizer) {
  return format ? normalizers[format] : normalizer
}
