import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import { TranslationProvider } from '@rushplay/i18n'
import { toAmount } from '@rushplay/common'

import * as App from './store/app'
import { useConfiguration } from './configuration'

export function Translation(props) {
  const { brand, currency, currencies, locale, supportEmail } =
    useConfiguration()

  const isProduction = ReactRedux.useSelector((state) =>
    App.isProduction(state.app)
  )

  const globals = React.useMemo(() => {
    if (locale) {
      return {
        brandEmail: supportEmail,
        brandName: brand,
        currencyCode: currency.code,
        locale: locale.slug,
      }
    }
  }, [locale, currency])

  const processors = React.useMemo(
    () => ({
      currency(value, data) {
        const currency = currencies[currency.code]

        if (!isProduction) {
          if (currency == null) {
            // eslint-disable-next-line no-console
            console.error(`"${data.currencyCode}" is an unknown currency`)
          }
        }

        return toAmount({ currency, locale: locale.slug }, value)
      },
    }),
    [currency, locale]
  )

  if (!locale || !locale.code) {
    return null
  }

  return (
    <Herz.I18n.TranslationProvider
      // TODO: use translation environmental switcher
      debug={!isProduction && 'missing'}
      globals={globals}
      language={locale.code}
      processors={processors}
    >
      <TranslationProvider>{props.children}</TranslationProvider>
    </Herz.I18n.TranslationProvider>
  )
}

Translation.propTypes = {
  children: PropTypes.element.isRequired,
}
