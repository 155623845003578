import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Urql from 'urql'
import PropTypes from 'prop-types'

import * as Session from '@rushplay/session'
import { cacheExchange } from '@urql/exchange-graphcache'
import { devtoolsExchange } from '@urql/devtools'

import * as App from './store/app'
import * as Lookup from './store/lookup'
import * as Player from './store/player'

function applyWhen(cond, exchange) {
  if (cond()) {
    return exchange
  }

  return ({ forward }) => forward
}

export function GraphqlProvider(props) {
  const clientType = ReactRedux.useSelector((state) =>
    App.getClientType(state.app)
  )
  const playerCountryCode = ReactRedux.useSelector((state) =>
    Player.getCountryCode(state)
  )
  const lookupCountryCode = ReactRedux.useSelector((state) =>
    Lookup.getCountryCode(state.lookup)
  )
  const countryCode = playerCountryCode || lookupCountryCode

  const language = ReactRedux.useSelector((state) => App.getLanguage(state.app))
  const url = ReactRedux.useSelector((state) =>
    App.getGraphqlProxyUrl(state.app)
  )

  const token = ReactRedux.useSelector((state) =>
    Session.getSessionToken(state.session)
  )

  const client = React.useMemo(
    () =>
      // eslint-disable-next-line import/namespace
      Urql.createClient({
        exchanges: [
          applyWhen(
            () => process.env.NODE_ENV !== 'production',
            devtoolsExchange
          ),
          // eslint-disable-next-line import/namespace
          Urql.dedupExchange,
          cacheExchange({
            keys: {
              Catalog: (data) => data.kind,
              Configuration: (data) => data.brand,
              Country: (data) => data.alpha2,
              Currency: (data) => data.code,
              Locale: (data) => data.slug,
              Moment: (data) => `${data.gameId}-${data.settleTimestamp}`,
              SeoMetadata: (data) => data.url,
            },
          }),
          // eslint-disable-next-line import/namespace
          Urql.fetchExchange,
        ],
        fetchOptions: {
          headers: R.reject(R.isNil, {
            Authorization: token,
            'Frontend-Client-Type': clientType,
            'Frontend-Country-Code': countryCode,
            'Frontend-Language': language,
          }),
        },
        url,
      }),
    [clientType, countryCode, language, token, url]
  )

  return <Urql.Provider value={client}>{props.children}</Urql.Provider>
}

GraphqlProvider.propTypes = {
  children: PropTypes.element,
  ssrCache: PropTypes.func,
  url: PropTypes.string,
}
