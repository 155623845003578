import * as React from 'react'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import * as ApiClient from '@rushplay/api-client'

import { Translation } from '../translation'

export function HerzTranslation(props) {
  const apiUrl = ApiClient.getApiUrl(window.location.hostname)

  const i18nLoader = new Herz.I18n.Loader({
    url: `${apiUrl}/translations/search`,
  })

  return (
    <Herz.I18n.LoaderProvider loader={i18nLoader}>
      <Translation>{props.children}</Translation>
    </Herz.I18n.LoaderProvider>
  )
}

HerzTranslation.propTypes = {
  children: PropTypes.object.isRequired,
}
