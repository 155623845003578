import PropTypes from 'prop-types'
import React from 'react'

import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'
import { Box, Link, Space } from '@rushplay/common'

const FooterLink = styled(Link)`
  opacity: 0.5;
  font-size: 14px;
  line-height: 24px;
`

const MailContactLink = styled.a`
  opacity: 0.5;
  font-size: 14px;
  line-height: 24px;
`

export function FooterSection(props) {
  const labels =
    (props.sectionLinks && props.sectionLinks.map((item) => item.label)) || []
  const translate = Herz.I18n.useTranslate(
    () => [...labels, props.heading],
    [labels, props.heading]
  )

  return (
    <Box
      width={['50%', 'auto']}
      paddingBottom="32px"
      paddingRight={[null, '90px']}
    >
      <Box paddingBottom={2} fontSize={[1, null, null, null, 2]}>
        {translate(props.heading)}
      </Box>
      {props.sectionLinks &&
        props.sectionLinks.map((link) => (
          <Space key={link.label} top={4}>
            {link.label === 'footer.links.contact-by-email' ? (
              <MailContactLink href={`mailto:support@casitabi.com`}>
                {translate(link.label)}
              </MailContactLink>
            ) : (
              <FooterLink to={link.to}>{translate(link.label)}</FooterLink>
            )}
          </Space>
        ))}
    </Box>
  )
}

Herz.I18n.Loader.preload(['support-email'], FooterSection)

FooterSection.propTypes = {
  heading: PropTypes.string.isRequired,
  sectionLinks: PropTypes.array.isRequired,
}
