import * as ReactRedux from 'react-redux'
import * as React from 'react'
import * as R from 'ramda'
import { withRouter } from 'react-router'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import * as App from '../../store/app'
import LoginForm from '../login-form/login-form-provider'
import { ButtonWithStroke } from '../../common/button-with-stroke'

const FormWrapper = styled.div`
  display: none;

  @media screen and (min-width: 1372px) {
    display: inline-flex;
  }
`

const BigLoginWrapper = styled.div`
  display: block;
  margin-right: 4px;

  @media screen and (min-width: 1372px) {
    display: none;
  }
`

const RegisterButtonWrapper = styled.div`
  @media screen and (min-width: 1372px) {
    padding: 4px 0 0 4px;
  }
`

function Component(props) {
  const translate = Herz.I18n.useTranslate()
  const dispatch = ReactRedux.useDispatch()
  const showLoginButton = !ReactRedux.useSelector(App.getMobileMenuVisibility)
  const isLoginInProgress = ReactRedux.useSelector((state) =>
    App.getIsLoginInProgress(state.app)
  )
  const isSignupPage = R.path(['params', 'step'], props) === 'create-account'

  return (
    <Common.Box
      display="flex"
      alignItems="start"
      alignSelf="stretch"
      marginLeft="auto"
      paddingLeft={['8px', null, null, '0px']}
      paddingRight={['8px', null, null, '10px']}
    >
      <Common.Box
        display={[!isSignupPage ? 'inline-flex' : 'none', null, null, 'none']}
        alignItems="center"
      >
        <Common.Link to="/register">
          <ButtonWithStroke small variant="primary">
            {translate('header.register')}
          </ButtonWithStroke>
        </Common.Link>
        {showLoginButton && (
          <Common.Space left={1} component="div">
            <ButtonWithStroke
              variant="secondary"
              disabled={isLoginInProgress}
              loading={isLoginInProgress}
              small
              onClick={() => dispatch(App.setMobileMenuVisibility(true))}
            >
              {translate('header.login')}
            </ButtonWithStroke>
          </Common.Space>
        )}
      </Common.Box>
      <FormWrapper>
        <LoginForm />
      </FormWrapper>
      <Common.Box display={['none', null, null, 'flex']} alignItems="center">
        {showLoginButton && (
          <BigLoginWrapper>
            <ButtonWithStroke
              variant="secondary"
              disabled={isLoginInProgress}
              loading={isLoginInProgress}
              small
              onClick={() => dispatch(App.setMobileMenuVisibility(true))}
            >
              {translate('header.login')}
            </ButtonWithStroke>
          </BigLoginWrapper>
        )}
        <RegisterButtonWrapper
          as={Common.Link}
          to="/register"
          display={!isSignupPage ? 'block' : 'none'}
        >
          <ButtonWithStroke small variant="primary">
            {translate('header.register')}
          </ButtonWithStroke>
        </RegisterButtonWrapper>
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(['header.register', 'header.login'], Authentication)

export const Authentication = withRouter(Component)
