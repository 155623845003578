import './assets/add-card.icon.svg'
import './assets/alert.icon.svg'
import './assets/arrows-alt.icon.svg'
import './assets/arrows-in.icon.svg'
import './assets/arrows-out.icon.svg'
import './assets/bank-card.icon.svg'
import './assets/bankid.icon.svg'
import './assets/bet-range-chip.icon.svg'
import './assets/blackjack.icon.svg'
import './assets/blitz.icon.svg'
import './assets/bonus-eur.icon.svg'
import './assets/bonus-gbp.icon.svg'
import './assets/bonus-usd.icon.svg'
import './assets/calendar.icon.svg'
import './assets/caret-down.icon.svg'
import './assets/casinoheroes.icon.svg'
import './assets/certificate.icon.svg'
import './assets/check-circle-o.icon.svg'
import './assets/check-green.icon.svg'
import './assets/check-no-circle.icon.svg'
import './assets/check.icon.svg'
import './assets/chevron-left.icon.svg'
import './assets/chevron-right.icon.svg'
import './assets/chevron-up.icon.svg'
import './assets/chip.icon.svg'
import './assets/clock-o.icon.svg'
import './assets/clover.icon.svg'
import './assets/close.icon.svg'
import './assets/cog.icon.svg'
import './assets/crown.icon.svg'
import './assets/dealer-suit.icon.svg'
import './assets/dice.icon.svg'
import './assets/edit-board.icon.svg'
import './assets/edit-password.icon.svg'
import './assets/edit.icon.svg'
import './assets/envelope.icon.svg'
import './assets/eur.icon.svg'
import './assets/exclamation-triangle.icon.svg'
import './assets/featured.icon.svg'
import './assets/exit.icon.svg'
import './assets/eye-slash.icon.svg'
import './assets/eye.icon.svg'
import './assets/facebook.icon.svg'
import './assets/fc2.icon.svg'
import './assets/fingerprint.icon.svg'
import './assets/flag-de.icon.svg'
import './assets/flag-en.icon.svg'
import './assets/flag-fi.icon.svg'
import './assets/flag-ja.icon.svg'
import './assets/flag-no.icon.svg'
import './assets/flag-sv.icon.svg'
import './assets/flag-uk.icon.svg'
import './assets/gbp.icon.svg'
import './assets/gplus.icon.svg'
import './assets/history.icon.svg'
import './assets/info.icon.svg'
import './assets/instagram.icon.svg'
import './assets/jackpot.icon.svg'
import './assets/limits.icon.svg'
import './assets/loading.icon.svg'
import './assets/logout.icon.svg'
import './assets/money.icon.svg'
import './assets/multi-devices.icon.svg'
import './assets/new.icon.svg'
import './assets/padlock.icon.svg'
import './assets/paytable.icon.svg'
import './assets/plus-green.icon.svg'
import './assets/pointing-up.icon.svg'
import './assets/refresh.icon.svg'
import './assets/repeat.icon.svg'
import './assets/right-arrow.icon.svg'
import './assets/rss.icon.svg'
import './assets/search.icon.svg'
import './assets/sek.icon.svg'
import './assets/shuffle.icon.svg'
import './assets/slots.icon.svg'
import './assets/spinner.icon.svg'
import './assets/smiley.icon.svg'
import './assets/sword.icon.svg'
import './assets/thumb-up.icon.svg'
import './assets/tick-active.icon.svg'
import './assets/tick-inactive.icon.svg'
import './assets/tick.icon.svg'
import './assets/times.icon.svg'
import './assets/tutorial.icon.svg'
import './assets/twitter.icon.svg'
import './assets/usd.icon.svg'
import './assets/volume-off.icon.svg'
import './assets/volume-on.icon.svg'
import './assets/youtube.icon.svg'
import './assets/other-games.icon.svg'

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'

function size(props) {
  if (!props.size) {
    return ''
  }

  return `font-size: ${props.size === 'big' ? '30px' : '20px'};`
}

const DefaultIcon = styled.span`
  ${size} ${(props) => props.onClick && 'cursor: pointer;'};
`

const SvgIconWrapper = styled(DefaultIcon)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
`

const SvgIcon = styled.svg`
  width: 1em;
  height: 1em;
`

const PngIcon = styled(DefaultIcon)`
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: bottom;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('${(props) => props.backgroundImage}');
`

const SVG_ICONS = [
  'add-card',
  'alert',
  'arrows-alt',
  'arrows-in',
  'arrows-out',
  'bank-card',
  'bankid',
  'bet-range-chip',
  'blackjack',
  'blitz',
  'bonus-eur',
  'bonus-gbp',
  'bonus-usd',
  'calendar',
  'caret-down',
  'casinoheroes',
  'certificate',
  'check-circle-o',
  'check-green',
  'check-no-circle',
  'check',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'chip',
  'clock-o',
  'clover',
  'close',
  'cog',
  'crown',
  'dealer-suit',
  'dice',
  'edit-board',
  'edit-password',
  'edit',
  'envelope',
  'eur',
  'exclamation-triangle',
  'exit',
  'eye-slash',
  'eye',
  'facebook',
  'fc2',
  'featured',
  'fingerprint',
  'flag-de',
  'flag-en',
  'flag-fi',
  'flag-ja',
  'flag-no',
  'flag-sv',
  'flag-uk',
  'gbp',
  'gplus',
  'history',
  'info',
  'instagram',
  'jackpot',
  'limits',
  'loading',
  'logout',
  'money',
  'multi-devices',
  'new',
  'padlock',
  'paytable',
  'plus-green',
  'pointing-up',
  'refresh',
  'repeat',
  'right-arrow',
  'rss',
  'search',
  'sek',
  'shuffle',
  'slots',
  'spinner',
  'smiley',
  'sword',
  'thumb-up',
  'tick-active',
  'tick-inactive',
  'tick',
  'times',
  'tutorial',
  'twitter',
  'usd',
  'volume-off',
  'volume-on',
  'youtube',
  'other-games',
]

const PNG_ICONS = [
  'bonus-coin',
  'ch-adventure',
  'ch-casino',
  'ch-sports',
  'ch-how-to-play',
  'ch-live-casino',
  'ch-offers',
  'ch-treasure-chest',
  'ch-worlds',
  'horse-racing',
  'coin',
  'ruby',
]

function Icon(props) {
  const iconName = props.name.toLowerCase()

  if (R.includes(iconName, SVG_ICONS)) {
    return (
      <SvgIconWrapper
        className={props.className}
        size={props.size}
        onClick={props.onClick}
      >
        <SvgIcon>
          <use xlinkHref={`#${iconName}.icon`} />
        </SvgIcon>
      </SvgIconWrapper>
    )
  } else if (R.includes(iconName, PNG_ICONS)) {
    const { default: icon } = require(`./assets/${iconName}.icon.png`)

    return (
      <PngIcon
        backgroundImage={icon}
        className={props.className}
        size={props.size}
        onClick={props.onClick}
      />
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(`Cannot find icon "${iconName}"`)
  }

  return (
    <DefaultIcon
      className={props.className}
      size={props.size}
      onClick={props.onClick}
    />
  )
}

export default Icon

Icon.propTypes = {
  className: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'big']),
  onClick: PropTypes.func,
}
