import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Box, Image, Link } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

const LicenseImage = styled.img`
  max-height: 45px;
  margin-left: 6px;
  margin-right: 6px;
`

function LicenseLogos(props) {
  return (
    <Box
      display="inline-flex"
      justifyContent="inherit"
      alignItems="inherit"
      flexWrap="inherit"
    >
      <Box
        as="a"
        href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbUl6YTNOREszaGplVVZhWWxrNFVtWXljSEE0Y1VFOVBTSXNJblpoYkhWbElqb2ljMVJhVm1KM01WcENZMWgxY2tKWlRWQjZZV1JxZHowOUlpd2liV0ZqSWpvaVlUZGxaVFl3TnpnNU5qWTBOREl6WkRVeFpUWm1aRGhpTVRKa056QTNNV0l4WXpReE5tRTVPVEUwTW1Rd056RXdORFV6TlRBM056WTRNbVJsWVRBd01pSXNJblJoWnlJNklpSjk="
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/gcb.avif" alt="GCB license" height="42.5px" />
      </Box>

      {R.map(
        (license) =>
          license.url ? (
            <Link key={license.src} to={license.url}>
              <LicenseImage src={props.translate(license.src)} />
            </Link>
          ) : (
            <LicenseImage
              key={license.src}
              src={props.translate(license.src)}
            />
          ),
        props.licenses.list
      )}

      {props.license === 'mga' && (
        <Link to="https://www.gamblingtherapy.org/en" target="_blank">
          <Image
            size="fixed"
            height={45}
            src={props.translate('gamblingtherapy-logo')}
            alt="Gambling Therapy"
          />
        </Link>
      )}
    </Box>
  )
}

export default withTranslate(LicenseLogos)

LicenseLogos.propTypes = {
  license: PropTypes.string.isRequired,
  licenses: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
}
