import * as R from 'ramda'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { goBack, push as navigateTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import { actions, connectForm, selectors } from '@rushplay/legacy-forms'
import {
  getAffiliateClickId,
  isPhoneVerificationRequired,
  isSessionActive,
  session,
} from '@rushplay/session'
import {
  login as loginApi,
  registerPlayer as registerPlayerApi,
  requestSmsVerification as requestSmsVerificationApi,
  validatePhoneNumber as validatePhoneNumberApi,
} from '@rushplay/api-client'
import { withTranslate } from '@rushplay/i18n'

import * as CombinedSelectors from '../combined-selectors'
import errorKey from '../error-key'
import {
  fetchCountries,
  fetchCountryCode,
  getCountries,
  getPrivacyPolicyVisible,
  getTermsConditionsVisible,
  getWaitingRegister,
  registrationFail,
  registrationSuccess,
  togglePrivacyPolicy,
  toggleTermsConditions,
  waitRegister,
} from '../store/sign-up'
import { fullWidthToHalfWidth } from '../components/forms/full-width-to-half-width'
import {
  getClientType,
  getLanguage,
  getUtmCampaign,
  getUtmMedium,
  getUtmSource,
  handleSessionInitialization,
  isGdprJurisdiction,
} from '../store/app'

const isStepValid = R.curry((formsState, group) => {
  return selectors.isAllFieldsHaveStatus(formsState, {
    form: 'sign-up',
    group,
    status: 'valid',
  })
})

function mapStateToProps(state, ownProps) {
  return {
    authenticated: isSessionActive(state.session),
    clickId: getAffiliateClickId(state.session),
    subID: CombinedSelectors.getAffiliateSubId(state),
    clientType: getClientType(state.app),
    steps: ['signup-type', 'create-account', 'additional-information'],
    countries: R.map(
      (country) =>
        R.assoc(
          'name',
          ownProps.translate(
            `country.${R.toLower(R.replace(/\s/g, '-', country.name))}`
          ),
          country
        ),
      getCountries(state.signUp)
    ),
    countryCode: 'JP',
    countryCallingCode: R.replace(
      '+',
      '',
      selectors.value(state.forms, {
        form: 'sign-up',
        field: 'countryCallingCode',
      })
    ),
    currentPage: ownProps.params.step,
    displayGdprContent: isGdprJurisdiction(state),
    isStepValid: isStepValid(state.forms),
    language: getLanguage(state.app),
    manualRegistration: jurisdiction.getManualSignUpAllowed(state.jurisdiction),
    isPhoneVerificationRequired: isPhoneVerificationRequired(state.session),
    trustlySignUpCountries: ['FI'],
    selectedCountryCode: R.path(['fields', 'countryCode', 'value'], ownProps),
    translate: ownProps.translate,
    termsConditionsVisible: getTermsConditionsVisible(state.signUp),
    phoneNumber: selectors.value(state.forms, {
      form: 'sign-up',
      field: 'mobile',
    }),
    privacyPolicyVisible: getPrivacyPolicyVisible(state.signUp),
    userData: R.pluck(
      'value',
      selectors.fields(state.forms, { form: ownProps.form })
    ),
    utmCampaign: getUtmCampaign(state.app),
    utmMedium: getUtmMedium(state.app),
    utmSource: getUtmSource(state.app),
    waitingRegister: getWaitingRegister(state.signUp),
  }
}

function formatUserData(data = {}) {
  const { building, street, ...userData } = data
  const streetAddress = `${street} ${building}`
  const formattedUserData = {
    ...userData,
    street: streetAddress,
  }

  const correctedUserData = Object.fromEntries(
    Object.entries(formattedUserData).map(([key, value]) => [
      key,
      typeof value === 'string' && key !== 'seon_session'
        ? fullWidthToHalfWidth(value)
        : value,
    ])
  )

  return correctedUserData
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCountries,
      fetchCountryCode,
      onDidMount: () => {
        return [fetchCountries()]
      },
      onPhoneValidation: (callingCode, value, onSuccess) => {
        const config = {
          success: () => {
            onSuccess()
            return [
              actions.validate('sign-up', 'mobile', {
                status: true,
                value,
              }),
            ]
          },
          failure: (response) => {
            return [
              waitRegister(false),
              actions.validate('sign-up', 'mobile', {
                value,
                status: false,
                errors: errorKey('sign-up', 'mobile', response.value.message),
              }),
            ]
          },
          version: 2,
        }
        return [
          waitRegister(true),
          validatePhoneNumberApi(callingCode, value, config),
        ]
      },
      onPlayerRegister: (userData, clientType, isPhoneVerificationRequired) => {
        const formattedUserData = formatUserData(userData)
        const data = R.merge(formattedUserData, { generate_username: true })
        const registerAction = registerPlayerApi(data, {
          success: () => {
            return loginApi(userData.email, userData.password, clientType, {
              version: 2,
              success: (payload) => {
                const actions = [registrationSuccess(payload.value.player)]
                if (isPhoneVerificationRequired) {
                  actions.push(
                    requestSmsVerificationApi({
                      success: () => [
                        session.store(payload.value),
                        navigateTo('/phone-verification'),
                      ],
                      failure: () =>
                        Notifications.add({
                          message: 'error.generic',
                          level: 'error',
                        }),
                      version: 1,
                      token: payload.value.token,
                    }),
                    waitRegister(false)
                  )
                } else {
                  actions.push(
                    handleSessionInitialization(payload, false, true)
                  )
                }
                return actions
              },
              failure: (error) => {
                // we go inside nested objects safely to access array of errors, and then
                // we rename it to errList along with assigning default empty array value
                const { value: { errors: { base: errList = [] } = {} } = {} } =
                  error

                if (errList.find((err) => err.reason === 'seon')) {
                  return [
                    waitRegister(false),
                    navigateTo('/'),
                    Notifications.add({
                      message: 'errors.login.seon-lock',
                      level: 'error',
                    }),
                  ]
                }

                const reasonedErr = errList.find(
                  (err) => err.reason !== undefined
                )
                if (reasonedErr) {
                  return [
                    waitRegister(false),
                    Notifications.add({
                      message: `errors.${reasonedErr.errorCode}.${reasonedErr.reason}`,
                      level: 'error',
                    }),
                  ]
                }

                return [
                  waitRegister(false),
                  Notifications.add({
                    message: 'error.generic',
                    level: 'error',
                  }),
                ]
              },
            })
          },
          failure: (response) => {
            const actions = [
              waitRegister(false),
              registrationFail(response.value.errors),
            ]
            if (response.value.errors) {
              actions.push(
                R.pipe(
                  R.map((error) =>
                    Notifications.add({
                      message: `sign-up.errors.${R.head(error)}`,
                      level: 'error',
                    })
                  ),
                  R.values
                )(response.value.errors)
              )
            }

            return actions
          },
          version: 1,
        })
        return [waitRegister(true), registerAction]
      },
      onClose: goBack,
      onPrivacyPolicyToggle: togglePrivacyPolicy,
      onTermsAndConditionsToggle: toggleTermsConditions,
      onNavigateTo: navigateTo,
    },
    dispatch
  )
}

export default R.compose(
  (component) => connectForm(component, { name: 'sign-up' }),
  withTranslate,
  connect(mapStateToProps, mapDispatchToProps)
)
