import * as React from 'react'
import * as ReactRouterRedux from 'react-router-redux'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import { locationShape } from 'react-router/lib/PropTypes'

import * as Analytics from '@rushplay/analytics'
import styled from '@emotion/styled'

// Background should always be black. It's game default
const Iframe = styled.iframe`
  background-color: #000000;
`

export function GameLauncher(props) {
  const iframeRef = React.useRef(null)
  // TODO replace to react-router hooks after updating react router
  const dispatch = ReactRedux.useDispatch()
  const location = props.location

  React.useEffect(() => {
    function gameEventListener(event) {
      const action = event.data || {}
      switch (action.type) {
        case '@rushplay/gamer/GAME_CLOSED': {
          const referrer = location.query.referrer
          dispatch(ReactRouterRedux.push(referrer ? `/${referrer}` : '/casino'))
          break
        }
        case '@rushplay/gamer/BET_HISTORY_OPENED': {
          dispatch(ReactRouterRedux.push('/dashboard/history'))
          break
        }
        case '@rushplay/gamer/CLASSIC_MODE_OPENED': {
          dispatch(
            ReactRouterRedux.push({
              ...location,
              query: { ...location.query, mode: 'classic' },
            })
          )
          break
        }
        case '@rushplay/gamer/LIMITS_OPENED': {
          dispatch(ReactRouterRedux.push('/dashboard/limits/money-limits/'))
          break
        }
        case '@rushplay/gamer/WALLET_OPENED': {
          dispatch(ReactRouterRedux.push('/wallet/deposit'))
          break
        }
      }
    }
    window.addEventListener('message', gameEventListener)
    return () => window.removeEventListener('message', gameEventListener)
  }, [location])

  React.useEffect(() => {
    dispatch(
      Analytics.openGame({
        gameCategory: props.gameCategory,
        gameId: props.gameId,
        gameImage: props.gameImage,
        gameProvider: props.gameProvider,
        gameTitle: props.title,
      })
    )
  }, [
    props.gameCategory,
    props.gameId,
    props.gameProvider,
    props.gameImage,
    props.title,
  ])

  return (
    <Iframe
      sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
      src={props.gameUrl}
      ref={iframeRef}
      title={props.gameTitle}
      className={props.className}
    />
  )
}

GameLauncher.propTypes = {
  gameCategory: PropTypes.string,
  gameId: PropTypes.number,
  gameProvider: PropTypes.string,
  gameImage: PropTypes.string,
  title: PropTypes.string,
  gameTitle: PropTypes.string.isRequired,
  gameUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  location: locationShape,
}
