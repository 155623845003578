import * as R from 'ramda'
import { createSelector } from 'reselect'
import { differenceInMinutes } from 'date-fns'

import * as Api from '@rushplay/api-client'
import * as Processes from '@rushplay/processes'
import { FETCH_SESSION_PROCESS, session } from '@rushplay/session'

import { handleSessionInitialization } from './app'

function currentTime(state, props) {
  return props.currentTime
}

export const getCurrentSessionTime = createSelector(
  [(state) => R.path(['loggedInAt'], state.session), currentTime],
  (loggedInAt, currentTime) => {
    return loggedInAt && differenceInMinutes(currentTime, new Date(loggedInAt))
  }
)

export function refresh(token) {
  return [
    Processes.start(FETCH_SESSION_PROCESS),
    Api.fetchSession({
      token,
      success: (response) => [
        session.update(response.value),
        handleSessionInitialization(response),
        Processes.stop(FETCH_SESSION_PROCESS),
      ],
      failure: () => [
        // TODO: dispatch notification
        Processes.stop(FETCH_SESSION_PROCESS),
      ],
      version: 1,
    }),
  ]
}

export { getSsn, getSessionToken } from '@rushplay/session'
