import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Session from '@rushplay/session'

import * as App from '../../store/app'

function getBrandedLogo(brand) {
  return brand === 'casitabi'
    ? '/images/logo-casitabi.svg'
    : '/images/logo-casinoheroes.svg'
}

export function Logo() {
  const authenticated = ReactRedux.useSelector((state) =>
    Session.isSessionActive(state.session)
  )
  const brand = ReactRedux.useSelector(
    (state) => App.getBrand(state.app) || 'casinoheroes'
  )

  return (
    <Common.Box as={Common.Link} to="/" padding="10px">
      <Common.Box
        width={[authenticated ? '34px' : '68px', null, null, '100px']}
        height={[authenticated ? '36px' : '38px', null, null, '60px']}
        margin={['0', '0px 5px']}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize="contain"
        backgroundImage={[
          authenticated
            ? `url('/images/logo-crown.svg')`
            : `url('${getBrandedLogo(brand)}')`,
          null,
          null,
          `url('${getBrandedLogo(brand)}')`,
        ]}
      />
    </Common.Box>
  )
}
