import * as colors from '../constants/colors'

const theme = {
  breakpoints: ['500px', '800px', '1000px', '1280px'],
  fonts: {
    headings: '"Montserrat", Verdana, Geneva, sans-serif',
    body: '"Open Sans", Helvetica, Arial, sans-serif',
    sizes: ['12px', '16px', '18px', '24px', '30px', '38px', '40px'],
  },
  fontSizes: ['12px', '14px', '16px', '18px', '24px', '30px', '38px', '40px'],
  colors,
  variants: {
    // primary
    primary: colors.fabGreen,
    primaryHover: colors.fabGreenPale,
    // secondary
    secondary: colors.white,
    secondaryHover: colors.blueGreyPale,
    // default
    default: colors.powderBlue,
    defaultHover: colors.powderBluePale,
    // disabled
    disabled: colors.blueGrey,
    disabledText: colors.ghostGrey,
    // Notification
    notification: colors.skyBlue,
  },
  borderRadius: ['5px', '10px', '20px'],
  paddings: ['8px', '12px', '16px'],
  space: [
    '0px',
    '4px',
    '6px',
    '8px',
    '12px',
    '16px',
    '20px',
    '24px',
    '30px',
    '40px',
    '50px',
    '60px',
    '70px',
    '80px',
    '100px',
    '200px',
  ],
}

export default theme
