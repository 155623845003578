import * as React from 'react'
import Cookies from 'universal-cookie'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { nanoid } from 'nanoid'

import * as Herz from '@rushplay/herz'

import * as Constants from './constants'

export function generateSessionId() {
  const timestamp = Date.now()
  const id = nanoid()
  return `HG.${timestamp}.${id}`
}

// This wrapper enables SEON
export function SeonProvider(props) {
  const logger = Herz.Logging.useInstance()
  const cookies = new Cookies()
  const gid = cookies.get(Constants.CookieKeys.GID)
  // we need this state to trigger updates
  const [deviceId, setDeviceId] = React.useState(
    cookies.get(Constants.CookieKeys.DEVICE_ID)
  )
  logger.trace(`[seon] SeonProvider's deviceId: "${deviceId}"`)

  React.useEffect(() => {
    if (!deviceId) {
      const newSessionId = gid || generateSessionId()
      cookies.set(
        Constants.CookieKeys.DEVICE_ID,
        newSessionId,
        Constants.CookieOptions[Constants.CookieKeys.DEVICE_ID]
      )
      setDeviceId(newSessionId)
    }
  }, [deviceId, gid])

  // It is required for initializing SEON, make sure it exists
  if (!deviceId) {
    return props.children
  }

  return (
    <Herz.Seon.FingerprintProvider
      {...props}
      disabled={!process.browser}
      // Sometimes default host is failing on casitabi stage/prod.
      // If default host doesn't work we can use one of other supported CDNs
      // Refer to Herz.Seon source code for the list of hosts
      renderScript={(props) => (
        <Helmet>
          <script {...props} />
        </Helmet>
      )}
      sessionId={deviceId}
    />
  )
}

SeonProvider.propTypes = {
  children: PropTypes.node,
}
