import * as React from 'react'
import * as R from 'ramda'
import * as PropTypes from 'prop-types'
import { timingFunctions } from 'polished'
import { v4 as uuid4 } from 'uuid'

import * as Forms from '@rushplay/newForms'
import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'

import * as Colors from '../../constants/colors'
import Icon from '../../common/icon/icon'
import { textFont } from '../../constants/typography'

import { Input } from './input'
import { getFieldNormalizer } from './get-field-normalizer'
import { getFieldParser } from './get-field-parser'

function inputBorderColor(props) {
  if (!props.empty) {
    if (props.valid) {
      return props.borderGrey ? Colors.dark60 : Colors.success
    }
  }

  if (props.processing) {
    return Colors.night
  }

  if (props.visited && !props.valid) {
    return props.dark ? Colors.errorLight : Colors.error
  }

  if (props.isLoginPage || props.borderGrey) {
    return Colors.dark60
  }

  if (props.dark) {
    return Colors.white
  }

  if (props.active) {
    return Colors.night
  }

  return Colors.dark60
}

function boxShadow(props) {
  if (!props.active) {
    return 'none'
  }

  return props.dark
    ? `0 0 8px 2px ${Colors.ocean}`
    : '0 2px 4px 0 rgba(0, 0, 0, .20)'
}

function inputPadding(props) {
  if (!props.small && !props.externalLabel) {
    return '11px 19px'
  }

  if (props.currency) {
    return '18px 12px'
  }

  return '12px'
}

function iconName(valid, empty) {
  if (valid) {
    return empty ? 'info' : 'check'
  }

  return 'alert'
}

function toolTipColor(props) {
  if (props.valid) {
    if (props.empty) {
      return props.dark ? Colors.oceanLight : Colors.night
    }

    return Colors.success
  }

  return props.dark ? Colors.errorLight : Colors.error
}

export function InputField(props) {
  const i18n = I18n.useI18n()
  const form = Forms.useFormContext()
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: getFieldNormalizer(props.format, props.normalize),
    parse: getFieldParser(props.format, props.parse),
  })
  const [fieldType, setFieldType] = React.useState(props.type)
  const [uuid] = React.useState(uuid4())
  const [fieldFocused, setFieldFocus] = React.useState(false)
  const [touched, setTouched] = React.useState(false)

  const isSmall = props.currency || props.small
  const hasExternalLabel = props.hasExternalLabel
  const processing = field.status === 'processing'
  const valid = field.errors.length === 0
  const visuallyValid = !touched || valid
  const displayTooltip = (!visuallyValid || fieldFocused) && props.tooltip
  const empty = field.value === ''
  const active = fieldFocused

  return (
    <Common.Box textAlign="left" display={props.type === 'hidden' && 'none'}>
      {hasExternalLabel ? (
        <Common.Box
          fontFamily={textFont}
          color={Colors.black}
          display="block"
          whiteSpace="nowrap"
          overflow="hidden"
          textAlign="left"
          paddingTop="10px"
          paddingBottom="10px"
          style={{
            'text-overflow': 'ellipsis',
            'pointer-events': 'none',
          }}
        >
          {props.label}
        </Common.Box>
      ) : null}
      <Common.Box>
        <Common.Box
          padding={inputPadding({
            externalLabel: hasExternalLabel,
            small: props.small,
            currency: props.currency,
            type: props.type,
          })}
          textAlign="left"
          display="flex"
          backgroundColor={props.disabled ? Colors.disabledGray : Colors.white}
          fontSize="16px"
          borderRadius="8px"
          overflow="hidden"
          style={{
            transition: `border 0.3s ${timingFunctions('easeOutQuart')}`,
            'box-sizing': 'border-box',
            'box-shadow': boxShadow({ active, dark: props.dark }),
            border: `2px solid ${inputBorderColor({
              empty,
              active,
              valid: visuallyValid,
              processing,
              visited: !R.includes(field.status, [
                Forms.FieldStatus.PRISTINE,
                Forms.FieldStatus.ABSENT,
              ]),
              dark: props.dark,
              borderGrey: props.borderGrey,
            })}`,
          }}
        >
          {props.currency && (
            <Common.Box
              as={Icon}
              name={props.currency}
              fontSize="19px"
              color={Colors.dark40}
            />
          )}
          <Common.Box position="relative" flex="1">
            {hasExternalLabel
              ? null
              : !isSmall && (
                  <Common.Box
                    active={!empty || active}
                    currency={props.currency}
                    empty={empty}
                    htmlFor={uuid}
                    fontFamily={textFont}
                    fontSize="16px"
                    color={Colors.dark40}
                    display="block"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textAlign="left"
                    position="absolute"
                    top="50%"
                    left="0"
                    right="0"
                    style={{
                      'text-overflow': 'ellipsis',
                      'pointer-events': 'none',
                      transform: `${
                        active
                          ? 'translateY(-100%) scale(0.8, 0.8)'
                          : 'translateY(-50%)'
                      }`,
                      'transform-origin': 'left top',
                      transition: `transform 0.3s ${timingFunctions(
                        'easeOutCubic'
                      )}`,
                    }}
                  >
                    {props.label}
                  </Common.Box>
                )}
            <Input
              visited={
                !R.includes(field.status, [
                  Forms.FieldStatus.PRISTINE,
                  Forms.FieldStatus.ABSENT,
                ])
              }
              valid={R.isEmpty(field.errors)}
              autoComplete={props.autoComplete}
              autoCorrect={props.autoCorrect}
              autoFocus={props.autoFocus}
              disabled={props.disabled}
              maxLength={props.maxLength}
              minLength={props.minLength}
              name={field.name}
              id={`${form.name}-${field.name}`}
              placeholder={
                !props.label ? i18n.translate(props.placeholder) : ''
              }
              type={fieldType}
              initialType={props.type}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              onFocus={(focus) => setFieldFocus(focus)}
              onTouch={() => setTouched(true)}
              small={props.small}
              dark={props.dark}
            />
            {props.type === 'password' && (
              <Common.Box
                as={Icon}
                name={fieldType === 'text' ? 'eye-slash' : 'eye'}
                onClick={() => {
                  setFieldType(fieldType === 'text' ? 'password' : 'text')
                }}
                position="absolute"
                top="50%"
                right="0"
                marginTop="-.5em"
                fontSize="20px"
                color={Colors.dark40}
                cursor="pointer"
              />
            )}
          </Common.Box>
        </Common.Box>
      </Common.Box>
      {!processing && (props.tooltip || !visuallyValid) && (
        <Common.Box
          fontFamily={textFont}
          textAlign="left"
          maxHeight={displayTooltip ? '100px' : '0'}
          minHeight={displayTooltip ? '15px' : 'auto'}
          opacity={displayTooltip ? 1 : 0}
          overflow="hidden"
          fontSize="13px"
          marginTop=".438em"
          marginBottom="-.438em"
          color={toolTipColor({
            visuallyValid,
            empty,
            dark: props.dark,
          })}
          wordBreak="break-word"
          style={{
            transition: `max-height 0.8s ${timingFunctions(
              'easeInOutQuart'
            )}, opacity 0.5s ${timingFunctions('easeInOutQuart')}`,
          }}
        >
          <Common.Box
            as={Icon}
            name={iconName(visuallyValid, empty)}
            fontSize="13px"
            marginRight=".3em"
            color={visuallyValid && !empty ? Colors.success : 'currentColor'}
          />
          {props.tooltip}
        </Common.Box>
      )}
    </Common.Box>
  )
}

InputField.defaultProps = {
  disabled: false,
  type: 'text',
  normalize: (value) => value || undefined,
}

InputField.propTypes = {
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  disabled: PropTypes.bool,
  format: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  placeholder: PropTypes.string,
  scope: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'hidden']),
  tooltip: PropTypes.string,
  dark: PropTypes.bool,
  small: PropTypes.bool,
  currency: PropTypes.oneOf(['eur', 'sek', 'usd', 'gbp']),
  label: PropTypes.string,
  hasExternalLabel: PropTypes.bool,
  centered: PropTypes.bool,
  borderGrey: PropTypes.bool,
}
