import * as R from 'ramda'
import PropTypes from 'prop-types'
import memoize from 'fast-memoize'

import styled from '@emotion/styled'

function color(props) {
  return R.pathOr(props.color, ['theme', 'colors', props.color], props)
}

const maybePixels = memoize((value) => {
  if (typeof value === 'number') {
    return `${value}px`
  }

  if (value === String(Number.parseFloat(value))) {
    return `${value}px`
  }

  return value
})

export default styled.div`
  width: 100%;
  text-align: ${(props) => props.align};
  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace}`};
  ${(props) => props.bold && `font-weight: bold;`};
  ${(props) => props.color && `color: ${color(props)};`};
  ${(props) => props.underlined && `text-decoration: underline;`};
  ${(props) => props.size && `font-size: ${maybePixels(props.size)};`};
  ${(props) => props.font && `font-family: ${props.font};`};
  ${(props) =>
    props.lineHeight && `line-height: ${maybePixels(props.lineHeight)};`};
`

Text.defaultProps = {
  align: 'left',
}

Text.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  bold: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  font: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  whiteSpace: PropTypes.oneOf([
    'normal',
    'nowrap',
    'pre',
    'pre-wrap',
    'pre-line',
  ]),
  underlined: PropTypes.bool,
}
