import * as React from 'react'

import css from '@styled-system/css'
import styled from '@emotion/styled'

const Button = styled('button')`
  ${css({
    width: ['48px', null, null, '215px'],
    height: ['48px', null, null, '50px'],
  })}
  position: fixed;
  right: 20px;
  bottom: 10px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #183363;
  color: #fff;
  fill: #fff;
  border: none;
  border-radius: 999rem;
  outline: none;
  z-index: 9999;
  cursor: pointer;
`

const IconWrapper = styled('span')`
  ${css({
    paddingTop: [null, null, null, 1],
    paddingRight: [null, null, null, '0.6rem'],
  })}
`

const SVGIcon = styled('svg')`
  width: 20px;
  height: 20px;
`

const ButtonText = styled('span')`
  ${css({
    display: ['none', null, null, 'block'],
  })}
  font-weight: 700;
  font-size: 15px;
`

function ChatIcon() {
  return (
    <IconWrapper>
      <SVGIcon viewBox="0 0 15 16" ariaHidden="true">
        <path d="M1.3,16c-0.7,0-1.1-0.3-1.2-0.8c-0.3-0.8,0.5-1.3,0.8-1.5c0.6-0.4,0.9-0.7,1-1c0-0.2-0.1-0.4-0.3-0.7c0,0,0-0.1-0.1-0.1 C0.5,10.6,0,9,0,7.4C0,3.3,3.4,0,7.5,0C11.6,0,15,3.3,15,7.4s-3.4,7.4-7.5,7.4c-0.5,0-1-0.1-1.5-0.2C3.4,15.9,1.5,16,1.5,16 C1.4,16,1.4,16,1.3,16z M3.3,10.9c0.5,0.7,0.7,1.5,0.6,2.2c0,0.1-0.1,0.3-0.1,0.4c0.5-0.2,1-0.4,1.6-0.7c0.2-0.1,0.4-0.2,0.6-0.1 c0,0,0.1,0,0.1,0c0.4,0.1,0.9,0.2,1.4,0.2c3,0,5.5-2.4,5.5-5.4S10.5,2,7.5,2C4.5,2,2,4.4,2,7.4c0,1.2,0.4,2.4,1.2,3.3 C3.2,10.8,3.3,10.8,3.3,10.9z"></path>
      </SVGIcon>
    </IconWrapper>
  )
}

export function SupportChatActionButton(props) {
  return (
    <Button {...props}>
      <ChatIcon />
      <ButtonText>チャット・サポート</ButtonText>
    </Button>
  )
}
