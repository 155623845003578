import PropTypes from 'prop-types'
import React from 'react'

import { Field, FormPropTypes } from '@rushplay/legacy-forms'
import { useI18n } from '@rushplay/i18n'

import InputField from '../../common/input-field'
import PasswordValidationHints from '../../components/password-validation-hints'
import getFormTranslationKey from '../../util/get-form-translation-key'
import validators from '../../validators'
import { fullWidthToHalfWidth } from '../../components/forms/full-width-to-half-width'

import StepTemplate from './step-template'
import { Row } from './styles'

function Step1(props) {
  const hasExternalLabel = props.isCasitabi
  const i18n = useI18n()

  return (
    <StepTemplate
      {...props.stepTemplate}
      nextStepDisabled={!props.isValid}
      nextStepLabel={i18n.translate('sign-up.continue')}
      title={i18n.translate('sign-up.create-account')}
      onNextStep={props.onNextStep}
    >
      <div>
        <Row>
          <Field
            component={InputField}
            group="step1"
            label={i18n.translate('sign-up.email.label')}
            placeholder={i18n.translate('sign-up.email.placeholder')}
            name="email"
            tooltip={i18n.translate(
              getFormTranslationKey('sign-up')(
                props.fields.email,
                'email',
                'choose-valid-email'
              )
            )}
            type="email"
            validator={[
              validators.required,
              validators.email,
              validators.emailAsync,
            ]}
            hasExternalLabel={hasExternalLabel}
          />
        </Row>
        <Row>
          <Field
            component={InputField}
            group="step1"
            label={
              hasExternalLabel
                ? i18n.translate('sign-up.password.external-label')
                : i18n.translate('sign-up.password.label')
            }
            name="password"
            placeholder={i18n.translate('sign-up.password.placeholder')}
            tooltip={i18n.translate(
              getFormTranslationKey('sign-up')(
                props.fields.password,
                'password',
                'password-help'
              ),
              { min: 8 }
            )}
            type="password"
            validator={[
              validators.required,
              validators.password,
              validators.noJapaneeseCharacters,
            ]}
            hasExternalLabel={hasExternalLabel}
            normalize={fullWidthToHalfWidth}
          />
        </Row>
        <Row>
          <PasswordValidationHints
            currentPassword={
              props.fields?.password?.value ? props.fields.password.value : ''
            }
          />
        </Row>
      </div>
    </StepTemplate>
  )
}

export default Step1

Step1.propTypes = {
  fields: PropTypes.shape({
    email: FormPropTypes.field,
    password: FormPropTypes.field,
  }).isRequired,
  isValid: PropTypes.bool.isRequired,
  isCasitabi: PropTypes.bool.isRequired,
  stepTemplate: PropTypes.shape({
    backgroundImage: PropTypes.string,
    backgroundOverlay: PropTypes.string,
    titleBarBackgroundImage: PropTypes.string,
    titleBarText: PropTypes.string,
  }),
  onNextStep: PropTypes.func.isRequired,
}
