import * as React from 'react'
import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Forms from '@rushplay/newForms'
import * as Notifications from '@rushplay/notifications'

import { getClientType, getIsLoginInProgress, login } from '../../store/app'

import { LandingPageLoginForm } from './landing-page-login-form'
import { NewLoginForm } from './login-form'

const dataSchema = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
    },
    emailHidden: {
      type: 'string',
    },
    password: {
      type: 'string',
      minLength: 8,
    },
  },
  required: ['password'],
}

export default function LoginFormProvider(props) {
  const dispatch = ReactRedux.useDispatch()
  const clientType = ReactRedux.useSelector((state) => getClientType(state.app))
  const isLoginInProgress = ReactRedux.useSelector((state) =>
    getIsLoginInProgress(state.app)
  )
  const returningUsername = ReactRedux.useSelector(
    (state) => state.app.username
  )
  const returningUserAvatar = ReactRedux.useSelector(
    (state) => state.app.avatarUrl
  )

  return (
    <Forms.Provider
      name="login"
      schema={dataSchema}
      onSubmit={(errors, userData) => {
        if (R.isEmpty(errors)) {
          const email = userData.email || userData.emailHidden
          dispatch(login(email, userData.password, clientType))
        } else {
          dispatch(
            Notifications.add({
              message: 'errors.loginFailed',
              level: 'error',
            })
          )
        }
      }}
    >
      {props.isLandingPage ? (
        <LandingPageLoginForm
          isLoginInProgress={isLoginInProgress}
          clientType={clientType}
          returningUsername={returningUsername}
          returningUserAvatar={returningUserAvatar}
        />
      ) : (
        <NewLoginForm
          isLoginInProgress={isLoginInProgress}
          clientType={clientType}
          returningUsername={returningUsername}
          returningUserAvatar={returningUserAvatar}
        />
      )}
    </Forms.Provider>
  )
}

LoginFormProvider.propTypes = {
  isLandingPage: PropTypes.bool,
}
