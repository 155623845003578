import * as React from 'react'
import * as R from 'ramda'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Colors from '../../../constants/colors'
import Button from '../../../common/button'

import { ProofImage } from './proof-image'

export function AddressAndIdentityStatus(props) {
  const i18n = I18n.useI18n()

  return (
    <React.Fragment>
      <Common.Box
        textTransform="uppercase"
        textAlign="center"
        fontSize={[0, 2]}
        lineHeight="1.25"
        fontWeight="bold"
        py={5}
      >
        {i18n.translate(`my-identity.status.${props.type}.title`)}
      </Common.Box>
      <Common.Box
        pb={5}
        textAlign="center"
        fontSize={4}
        lineHeight="1.25"
        fontWeight="bold"
        color={!props.verified ? Colors.warning : undefined}
      >
        {i18n.translate(
          props.verified
            ? `my-identity.status.${props.type}.verified`
            : `my-identity.status.${props.type}.not-verified`
        )}
      </Common.Box>

      {!R.isEmpty(props.proofs) && props.verified && (
        <Common.Box
          display="grid"
          gridTemplateColumns={`repeat(${
            R.path(['proofs', 'length'], props) === 1 ? 1 : 2
          }, minmax(6rem, 1fr))`}
          gridGap={[3, null, null, 4]}
        >
          {R.map(
            (proof) => (
              <ProofImage
                key={proof.url}
                url={proof.url}
                width="100%"
                height={R.length(props.proofs) > 1 ? '80px' : '97px'}
                style={{
                  objectFit: 'cover',
                  objectPosition: '0 0',
                }}
              />
            ),
            props.proofs
          )}
        </Common.Box>
      )}

      {!props.verified && (
        <Common.Box
          display="flex"
          flexDirection="column"
          flex="1"
          justifyContent="space-between"
          width="100%"
        >
          <Common.Box display="flex" flexDirection="column">
            <Common.Box
              fontSize={[0, 1]}
              lineHeight="1.25"
              mb={6}
              textAlign="center"
            >
              {i18n.translate(
                `my-identity.status.${props.type}.upload-description`
              )}
            </Common.Box>
            <Common.Box
              display="grid"
              gridTemplateColumns={[
                'repeat(2, minmax(0, 1fr))',
                null,
                null,
                'repeat(2, 120px)',
              ]}
              gridGap={[3, null, null, 4]}
              mx="auto"
              mb={1}
            >
              {/* Allowing up to 4 placeholder images */}
              {[1, 2, 3, 4].map((item) =>
                i18n.isExistingTranslation(
                  `my-identity.status.${props.type}.placeholder-${item}`
                ) ? (
                  <Common.Box
                    as="img"
                    alt={`${props.type}-placeholder-${item}`}
                    key={`${props.type}-placeholder-${item}`}
                    src={i18n.translate(
                      `my-identity.status.${props.type}.placeholder-${item}`
                    )}
                    width={['110px', null, null, '120px']}
                    height={['65px', null, null, '75px']}
                  />
                ) : null
              )}
            </Common.Box>
          </Common.Box>
          <Common.Box
            display="flex"
            justifyContent="center"
            pt={5}
            pb={7}
            px={7}
          >
            <Button onClick={props.onContinue} stretch>
              {i18n.translate(`my-identity.status.${props.type}.upload-button`)}
            </Button>
          </Common.Box>
        </Common.Box>
      )}
    </React.Fragment>
  )
}

AddressAndIdentityStatus.propTypes = {
  proofs: PropTypes.array,
  type: PropTypes.string,
  verified: PropTypes.bool,
  onContinue: PropTypes.func,
}
