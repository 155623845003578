import * as React from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'
import { Field } from '@rushplay/legacy-forms'
import { isExistingTranslation, withTranslate } from '@rushplay/i18n'

import CheckboxField from '../../common/checkbox-field'
import HtmlContent from '../../common/html-content'
import InputField from '../../common/input-field'
import Select from '../../common/select'
import fieldError from '../../field-error'
import formatCountryOptions from '../lib/format-country-options'
import getCountryCode from '../lib/get-country-code'
import validators from '../../validators'
import { ZipCodeInputField } from '../zip-code-input-field'
import { baseFontSizeSmall } from '../../constants/typography'
import { berry } from '../../constants/colors'

import StepTemplate from './step-template'
import { Description, InlineRow, InlineRowItem, Row } from './styles'

const BIRTH_YEAR_MAX = new Date().getFullYear() - 18
const BIRTH_YEAR_MIN = new Date().getFullYear() - 100

const CheckboxRow = styled(Row)`
  font-size: 12px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.5;
`
const CountryCode = styled(InlineRowItem)`
  flex-grow: 0.5;

  @media screen and (min-width: 375px) {
    flex-grow: 0.4;
  }
`
const ZipCode = styled(InlineRowItem)`
  flex-grow: 0.6;
`
const BuildingField = styled(InlineRowItem)`
  flex-grow: 0.5;
`
const TermsCondition = styled.a`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${berry};
  }
`
const PrivacyPolicy = styled.a`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${berry};
  }
`
const FieldWrapper = styled.div`
  display: inline-block;
`

const LegalInformation = styled(HtmlContent)`
  text-align: left;
  margin-left: 36px;
  line-height: 1.5;
  font-size: ${baseFontSizeSmall};
`

// Country code input is hidden because we don't have other options than Japan at the moment. We still need to send country code to BE and we use its value in zipCode input field component
const CountryCodeRow = styled(Row)`
  display: none;
`

function Step2(props) {
  if (!props.countryCode) {
    return null
  }

  const initialCountryCallingCode = getCountryCode(
    props.countryCode,
    props.countries
  )

  const tacLabelLink = (
    <span>
      {`${props.translate('register.form.agree-terms')} `}
      <TermsCondition onClick={props.onTermsConditionsClick}>
        {props.translate('sign-up.terms-conditions')}
      </TermsCondition>
      {props.displayGdprContent && (
        <span>
          {` ${props.translate('sign-up.and-our')} `}
          <PrivacyPolicy onClick={props.onPrivacyPolicyClick}>
            {props.translate('sign-up.privacy-policy')}
          </PrivacyPolicy>
        </span>
      )}
    </span>
  )

  return (
    <StepTemplate
      {...props.stepTemplate}
      nextStepDisabled={!props.isValid}
      nextStepLabel={props.translate('register.continue')}
      title={props.translate('register.form.information')}
      onBack={props.onBack}
      onNextStep={props.onNextStep}
      waitingRegister={props.waitingRegister}
    >
      <div>
        <Row>
          <Field
            autoFocus
            component={InputField}
            group="step2"
            label={props.translate('sign-up.last-name.external-label')}
            placeholder={props.translate('sign-up.last-name.placeholder')}
            tooltip={
              fieldError(props.fields.lastName) &&
              props.translate(fieldError(props.fields.lastName))
            }
            name="lastName"
            type="text"
            validator={[validators.required, validators.name]}
            hasExternalLabel
          />
        </Row>
        <Row>
          <Field
            component={InputField}
            group="step2"
            label={props.translate('sign-up.first-name.external-label')}
            placeholder={props.translate('sign-up.first-name.placeholder')}
            tooltip={
              fieldError(props.fields.firstName) &&
              props.translate(fieldError(props.fields.firstName))
            }
            name="firstName"
            type="text"
            validator={[validators.required, validators.name]}
            hasExternalLabel
          />
        </Row>
        <InlineRow>
          <InlineRowItem>
            <Field
              component={InputField}
              group="step2"
              label={props.translate('sign-up.birth-year.label')}
              placeholder={props.translate('sign-up.birth-year.placeholder')}
              name="year"
              tooltip={
                fieldError(props.fields.year) &&
                props.translate(fieldError(props.fields.year), {
                  min: BIRTH_YEAR_MIN,
                  max: BIRTH_YEAR_MAX,
                })
              }
              type="text"
              validator={[
                validators.required,
                validators.min(BIRTH_YEAR_MIN),
                validators.max(BIRTH_YEAR_MAX),
                validators.digitsOnly,
              ]}
              hasExternalLabel
            />
          </InlineRowItem>
          <InlineRowItem>
            <Field
              component={InputField}
              group="step2"
              label={props.translate('sign-up.birth-month.label')}
              placeholder={props.translate('sign-up.birth-month.placeholder')}
              name="month"
              tooltip={
                fieldError(props.fields.month) &&
                props.translate(fieldError(props.fields.month), {
                  min: 1,
                  max: 12,
                })
              }
              validator={[
                validators.required,
                validators.min(1),
                validators.max(12),
                validators.digitsOnly,
              ]}
              hasExternalLabel
            />
          </InlineRowItem>
          <InlineRowItem>
            <Field
              component={InputField}
              group="step2"
              label={props.translate('sign-up.birth-day.label')}
              placeholder={props.translate('sign-up.birth-day.placeholder')}
              name="day"
              tooltip={
                fieldError(props.fields.day) &&
                props.translate(fieldError(props.fields.day), {
                  min: 1,
                  max: 31,
                })
              }
              validator={[
                validators.required,
                validators.min(1),
                validators.max(31),
                validators.digitsOnly,
              ]}
              hasExternalLabel
            />
          </InlineRowItem>
        </InlineRow>
        <CountryCodeRow>
          <Field
            component={Select}
            group="step2"
            countries={props.countries}
            initialValue={props.countryCode}
            name="countryCode"
            label={props.translate('sign-up.country.label')}
            validator={validators.required}
            options={formatCountryOptions(props.countries)}
            hasExternalLabel
          />
        </CountryCodeRow>
        <InlineRow>
          <ZipCode>
            <ZipCodeInputField
              group="step2"
              label={props.translate('sign-up.zip.label')}
              placeholder={props.translate('sign-up.zip.placeholder')}
              name="zip"
              tooltip={
                fieldError(props.fields.zip) &&
                props.translate(fieldError(props.fields.zip))
              }
              type="text"
              validator={[
                validators.required,
                validators.noJapaneeseCharacters,
              ]}
              logo={props.translate('google-logo')}
              hasExternalLabel
            />
          </ZipCode>
          <InlineRowItem>
            <Field
              component={InputField}
              group="step2"
              label={props.translate('sign-up.city.label')}
              placeholder={props.translate('sign-up.city.placeholder')}
              name="city"
              tooltip={
                fieldError(props.fields.city) &&
                props.translate(fieldError(props.fields.city))
              }
              type="text"
              validator={[validators.required]}
              hasExternalLabel
            />
          </InlineRowItem>
        </InlineRow>
        <InlineRow>
          <InlineRowItem>
            <Field
              component={InputField}
              group="step2"
              label={props.translate('sign-up.street.label')}
              placeholder={props.translate('sign-up.street.placeholder')}
              name="street"
              tooltip={
                fieldError(props.fields.street) &&
                props.translate(fieldError(props.fields.street))
              }
              type="text"
              validator={[validators.required]}
              hasExternalLabel
            />
          </InlineRowItem>
          <BuildingField>
            <Field
              component={InputField}
              group="step2"
              label={props.translate('sign-up.building.label')}
              placeholder={props.translate('sign-up.building.placeholder')}
              name="building"
              tooltip={
                fieldError(props.fields.building) &&
                props.translate(fieldError(props.fields.building))
              }
              type="text"
              validator={[validators.required]}
              hasExternalLabel
            />
          </BuildingField>
        </InlineRow>
        <Row>
          <Description>
            {props.translate(
              props.phoneVerificationRequired
                ? 'registration.phone-validation-true'
                : 'registration.phone-validation-false'
            )}
          </Description>
        </Row>
        <InlineRow>
          <CountryCode>
            <Field
              component={InputField}
              group="step2"
              label={props.translate('sign-up.country-code.label')}
              countryCode={props.countryCode}
              initialValue={initialCountryCallingCode}
              name="countryCallingCode"
              disabled
              flat
              hasExternalLabel
            />
          </CountryCode>
          <InlineRowItem>
            <Field
              component={InputField}
              group="step2"
              label={props.translate('sign-up.phone-number.external-label')}
              placeholder={props.translate('sign-up.phone-number.placeholder')}
              initialValue={props.mobile}
              name="mobile"
              tooltip={
                fieldError(props.fields.mobile) &&
                props.translate(fieldError(props.fields.mobile))
              }
              type="text"
              validator={[validators.required, validators.digitsOnly]}
              hasExternalLabel
            />
          </InlineRowItem>
        </InlineRow>
        <CheckboxRow>
          <FieldWrapper>
            <Field
              component={CheckboxField}
              group="step2"
              name="termsConditions"
              label={tacLabelLink}
              validator={validators.required}
            />
          </FieldWrapper>
        </CheckboxRow>

        {isExistingTranslation('sign-up.legal-information') && (
          <LegalInformation
            html={{
              __html: props.translate('sign-up.legal-information'),
            }}
          />
        )}

        {props.displayGdprContent && (
          <CheckboxRow>
            <Field
              component={CheckboxField}
              group="step2"
              name="allowMarketing"
              label={props.translate('register.allow-marketing')}
            />
          </CheckboxRow>
        )}
      </div>
    </StepTemplate>
  )
}

export default withTranslate(Step2)

Step2.propTypes = {
  countries: PropTypes.array.isRequired,
  countryCode: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    day: PropTypes.string,
    mobile: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string,
    street: PropTypes.string,
    building: PropTypes.string,
    apartment: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
  }).isRequired,
  displayGdprContent: PropTypes.bool,
  isValid: PropTypes.bool.isRequired,
  mobile: PropTypes.string,
  phoneVerificationRequired: PropTypes.bool,
  stepTemplate: PropTypes.shape({
    backgroundImage: PropTypes.string,
    backgroundOverlay: PropTypes.string,
    titleBarBackgroundImage: PropTypes.string,
    titleBarText: PropTypes.string,
    waitingRegister: PropTypes.bool,
  }),
  translate: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPrivacyPolicyClick: PropTypes.func.isRequired,
  onTermsConditionsClick: PropTypes.func.isRequired,
  waitingRegister: PropTypes.bool,
}
