import { useI18n } from '@rushplay/i18n'
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Icon from '../common/icon/icon'

const Check = styled(Icon)`
  font-size: ${(props) => props.theme.fontSizes[4]};
  vertical-align: center;
  margin-right: 5px;
  color: ${(props) =>
    props.name === 'check'
      ? props.theme.colors.success
      : props.theme.colors.dark60};
`

const ValidationRow = styled.p`
  display: flex;
  align-items: center;
  margin: 12px 5px;
`

export default function PasswordValidationHints(props) {
  const i18n = useI18n()

  const checkedHint = {
    eightOrMore: /^.{8,}$/.test(props.currentPassword),
    oneNumber: /[0-9]/.test(props.currentPassword),
    oneUppercase: /[A-Z]/.test(props.currentPassword),
  }

  return (
    <React.Fragment>
      <ValidationRow>
        <Check name={checkedHint.eightOrMore ? 'check' : 'check-circle-o'} />
        {i18n.translate('sign-up.password.validation-hint.eight-or-more')}
      </ValidationRow>
      <ValidationRow>
        <Check name={checkedHint.oneNumber ? 'check' : 'check-circle-o'} />
        {i18n.translate('sign-up.password.validation-hint.one-number')}
      </ValidationRow>
      <ValidationRow>
        <Check name={checkedHint.oneUppercase ? 'check' : 'check-circle-o'} />
        {i18n.translate('sign-up.password.validation-hint.one-uppercase')}
      </ValidationRow>
    </React.Fragment>
  )
}

PasswordValidationHints.propTypes = {
  currentPassword: PropTypes.string,
}
