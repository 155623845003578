import * as recompose from 'recompose'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import isAtCasinoGame from '../../util/at-casino-game'
import { getGameProviders } from '../../store/app'

import { Footer as Component } from './footer'

const isAtMap = R.test(/\/adventure(\/|$)/i)

const isAtMapPromo = R.test(/\/adventure-promo(\/|$)/i)

function mapStateToProps(state) {
  return {
    gameProviders: getGameProviders(state.app),
  }
}

const connector = R.compose(
  withRouter,
  connect(mapStateToProps),
  recompose.branch(
    (props) =>
      isAtMap(props.location.pathname) ||
      isAtCasinoGame(props.location.pathname) ||
      isAtMapPromo(props.location.pathname),
    recompose.renderNothing
  )
)

export const Footer = connector(Component)
