import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as I18n from '@rushplay/i18n'
import * as Session from '@rushplay/session'
import {
  SupportChat as Component,
  getTabVisibility,
  setTabVisibility,
} from '@rushplay/support-chat'

import * as App from './store/app'
import { SupportChatActionButton } from './components/support-chat-action-button'
import { useConfiguration } from './configuration'

export function SupportChat(props) {
  const i18n = I18n.useI18n()

  const { liveChatDepartment, liveChatKey } = useConfiguration()
  const email = ReactRedux.useSelector((state) =>
    Session.getEmail(state.session)
  )
  const language = ReactRedux.useSelector((state) => App.getLanguage(state.app))
  const name = ReactRedux.useSelector((state) =>
    Session.getDisplayName(state.session)
  )
  const username = ReactRedux.useSelector((state) =>
    Session.getUsername(state.session)
  )
  const birthdate = ReactRedux.useSelector(
    (state) => state.session.player && state.session.player.birthdate
  )
  const birthdateTag = birthdate ? `DOB: ${birthdate}` : null
  const valueSegment = ReactRedux.useSelector((state) =>
    Session.getValueSegment(state.session)
  )
  const valueSegmentTag = valueSegment ? `Value segment: ${valueSegment}` : null

  const translations = {
    'chat.concierge.avatarPath': i18n.translate('chat.concierge.avatar'),
    'chat.concierge.name': i18n.translate('chat.concierge.name'),
    'chat.concierge.title': i18n.translate('chat.concierge.title'),
    'chat.offlineForm.greeting': i18n.translate('chat.offline-form.greeting'),
    'chat.title': i18n.translate('chat.title'),
    'launcher.chatLabel': i18n.translate('chat.launcher.chat-label'),
    'launcher.label': i18n.translate('chat.launcher.label'),
  }

  if (!liveChatKey) {
    return null
  }

  return (
    <Chat
      {...props}
      addScriptInHead
      appKey={liveChatKey}
      departmentId={(liveChatDepartment && liveChatDepartment.primary) || null}
      email={email}
      fallbackDepartmentId={
        (liveChatDepartment && liveChatDepartment.fallback) || null
      }
      language={language}
      mountPoint="supportChat"
      name={name && `${name} (${username})`}
      tags={[birthdateTag, valueSegmentTag].filter((item) => item !== null)}
      translations={translations}
      visibleAt={0}
    />
  )
}

function Chat(props) {
  const authenticated = Session.isSessionActive()
  const [allowLoad, setAllowLoad] = React.useState(false)
  const visible = ReactRedux.useSelector((state) =>
    getTabVisibility(state.supportChat)
  )
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    if (visible || authenticated) {
      setAllowLoad(true)
    }
  }, [visible, authenticated])

  if (allowLoad) {
    return <Component {...props} />
  }

  if (!props.disabled) {
    return (
      <SupportChatActionButton
        onClick={() => dispatch(setTabVisibility(true))}
      />
    )
  }

  return null
}

Chat.propTypes = {
  disabled: PropTypes.bool,
}
