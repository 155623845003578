import * as R from 'ramda'

const EMPTY_REWARD = [null, null]

const mapIndexed = R.addIndex(R.map)

function toPackage(reward) {
  const limits = reward.depositAmountFilter || {}

  return {
    id: 'reward', // same ID to select all or nothing
    rangeLimits: [
      limits.deposit_amount_from_cents || 0,
      limits.deposit_amount_to_cents || Number.POSITIVE_INFINITY,
    ],
    gameTitle: reward?.gameTitle,
    bonusMoneyPercent:
      reward.kind === 'BonusPercentItem' ? reward.amount : null,
    bonusMoneyPercentMaxCents:
      reward.kind === 'BonusPercentItem' ? reward.maximumAmountCents : null,
    fixedFeatureTriggers:
      reward.kind === 'FeatureTriggers' ? [reward.amount, 0] : EMPTY_REWARD,
    fixedBonusMoney:
      reward.kind === 'BonusItem' ? [reward.amount, 0] : EMPTY_REWARD,
    fixedFreespins:
      reward.kind === 'Freespins' ? [reward.amount, 0] : EMPTY_REWARD,
    fixedFreebet: reward.kind === 'Freebet' ? [reward.amount, 0] : EMPTY_REWARD,
    fixedFreeGamesVoucher:
      reward.kind === 'FreeGamesVoucher' ? [reward.amount, 0] : EMPTY_REWARD,
    bonusRubies:
      reward.kind === 'RubiesBonus' ? [reward.amount, 0] : EMPTY_REWARD,
  }
}

const toPackages = mapIndexed(toPackage)

function toRewards(packages) {
  return [{ maxDepositNumber: Number.POSITIVE_INFINITY, packages }]
}

export default R.when(
  R.propEq('rewardTrigger', 'deposit'),
  R.over(
    R.lensProp('rewards'),
    R.unless(R.all(R.has('packages')), R.pipe(toPackages, toRewards))
  )
)
