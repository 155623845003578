import * as React from 'react'
import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as App from '../../../store/app'
import Button from '../../../common/button'

import { ProofImage } from './proof-image'

export function AdditionalStatus(props) {
  const i18n = I18n.useI18n()
  const language = ReactRedux.useSelector((state) => App.getLanguage(state.app))

  return (
    <React.Fragment>
      <Common.Box
        textTransform="uppercase"
        textAlign="center"
        fontSize={[0, 2]}
        fontWeight="bold"
        py={5}
        lineHeight="1.25"
      >
        {i18n.translate('my-identity.status.additional.title')}
      </Common.Box>

      {R.isEmpty(props.proofs) && (
        <Common.Box
          pb={5}
          textAlign="center"
          fontSize={4}
          lineHeight="1.25"
          fontWeight="bold"
        >
          {i18n.translate('my-identity.status.additional.not-verified')}
        </Common.Box>
      )}

      <Common.Box
        display="flex"
        flexDirection="column"
        flex="1"
        justifyContent="space-between"
        width="100%"
      >
        <Common.Box>
          {!R.isEmpty(props.proofs) && (
            <Common.Box
              height="275px"
              width="100%"
              display="flex"
              flexDirection="column"
              flex="auto"
              my="1"
              position="relative"
            >
              <Common.Box overflow="auto" height="100%">
                {props.proofs.map((proof) => (
                  <Common.Box
                    display="flex"
                    key={proof.url}
                    color={
                      {
                        rejected: 'warning',
                        verified: 'green',
                        pending: 'white',
                      }[proof.status]
                    }
                    mb={5}
                  >
                    <ProofImage
                      url={proof.url}
                      height="64px"
                      minWidth="64px"
                      width="64px"
                      border={`2px solid currentColor`}
                      mr={3}
                      style={{
                        objectFit: 'cover',
                        objectPosition: '0 0',
                      }}
                    />

                    <Common.Box>
                      <Common.Box fontSize={0} mb={2}>
                        {i18n.translate(
                          `my-identity.status.additional.proof.title.${proof.type}`,
                          {
                            date: Common.toLocaleDate(proof.date, language)
                              .date,
                          }
                        )}
                      </Common.Box>
                      <Common.Box fontSize={2} mb={2}>
                        {i18n.translate(
                          `my-identity.status.additional.proof.status-${proof.status}`
                        )}
                      </Common.Box>
                      {proof.rejectionReason && (
                        <Common.Box fontSize={0}>
                          {i18n.translate(
                            `my-identity.status.additional.proof.rejection-reason.${proof.rejectionReason}`
                          )}
                        </Common.Box>
                      )}
                    </Common.Box>
                  </Common.Box>
                ))}
              </Common.Box>
            </Common.Box>
          )}

          {R.isEmpty(props.proofs) && (
            <Common.Box display="flex" flexDirection="column">
              <Common.Box
                textAlign="center"
                fontSize={[0, 1]}
                lineHeight="1.25"
                mb={6}
              >
                {i18n.translate(
                  'my-identity.status.additional.upload-description'
                )}
              </Common.Box>
              <Common.Box
                display="grid"
                gridTemplateColumns={[
                  'repeat(2, minmax(0, 1fr))',
                  null,
                  null,
                  'repeat(2, 120px)',
                ]}
                gridGap={[3, null, null, 4]}
                mx="auto"
                mb={1}
              >
                {/* Allowing up to 4 placeholder images */}
                {[1, 2, 3, 4].map((item) =>
                  i18n.isExistingTranslation(
                    `my-identity.status.additional.placeholder-${item}`
                  ) ? (
                    <Common.Box
                      as="img"
                      alt={`additional-placeholder-${item}`}
                      key={`additional-placeholder-${item}`}
                      src={i18n.translate(
                        `my-identity.status.additional.placeholder-${item}`
                      )}
                      width={['110px', null, null, '120px']}
                      height={['65px', null, null, '75px']}
                    />
                  ) : null
                )}
              </Common.Box>
            </Common.Box>
          )}
        </Common.Box>
        <Common.Box display="flex" justifyContent="center" pt={5} pb={7} px={7}>
          <Button onClick={props.onContinue} stretch>
            {i18n.translate('my-identity.status.additional.upload-button')}
          </Button>
        </Common.Box>
      </Common.Box>
    </React.Fragment>
  )
}

AdditionalStatus.propTypes = {
  proofs: PropTypes.array,
  verified: PropTypes.bool,
  onContinue: PropTypes.func,
}
