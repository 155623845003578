import * as React from 'react'
import PropTypes from 'prop-types'

import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

import * as Colors from '../../constants/colors'
import FingerprintContainer from '../native-app/fingerprint-container'
import { ButtonWithStroke } from '../../common/button-with-stroke'
import { InputField } from '../forms/input-field'
import { fullWidthToHalfWidth } from '../forms/full-width-to-half-width'

import UsernameField from './username-field'

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (min-width: 1372px) {
    flex-direction: row;
    align-items: center;
  }
`

const FieldsWrapper = styled.div`
  padding-top: 15px;
  width: 100%;

  @media screen and (min-width: 1372px) {
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    height: 62px;
    justify-content: center;
    padding-top: 0px;
  }
`

const FieldWrapper = styled.div`
  width: 100%;
  padding-bottom: 15px;

  @media screen and (min-width: 1372px) {
    width: 185px;
    padding-bottom: 0;
    padding-left: 5px;
    position: relative;
  }
`

const RecoverPasswordWrapper = styled.div`
  @media screen and (min-width: 1372px) {
    display: none;
  }
`

const ButtonWrapper = styled.div`
  display: inline-block;
  float: right;

  @media screen and (min-width: 1372px) {
    margin: -5px 0 0 36px;
  }
`

export function NewLoginForm(props) {
  const i18n = I18n.useI18n()

  return (
    <FormWrapper>
      {props.returningUsername && <Common.Space right="17px" />}
      <FieldsWrapper>
        <FieldWrapper>
          <UsernameField
            returningUserAvatar={props.returningUserAvatar}
            returningUsername={props.returningUsername}
          />
        </FieldWrapper>
        <Common.Space right="10px" />
        <FieldWrapper>
          <InputField
            small
            dark
            autoComplete="password"
            inputMode="password"
            scope="#/properties/password"
            type="password"
            placeholder={i18n.translate('login-form.password')}
            normalize={fullWidthToHalfWidth}
            borderGrey
          />
          <Common.Link to="/reset-password">
            <Common.Box
              as={Common.Text}
              display={['none', null, null, null, 'block']}
              color={Colors.white}
              fontSize=".75em"
              position="absolute"
              right="0"
              bottom="-16px"
            >
              {i18n.translate('login-form.forgot-password')}
            </Common.Box>
          </Common.Link>
        </FieldWrapper>
      </FieldsWrapper>
      <RecoverPasswordWrapper>
        <Common.Link to="/reset-password">
          <Common.Box
            as={Common.Text}
            color={Colors.gray}
            paddingTop="15px"
            paddingBottom="20px"
          >
            {i18n.translate('login-form.forgot-password')}
          </Common.Box>
        </Common.Link>
      </RecoverPasswordWrapper>
      <ButtonWrapper>
        <ButtonWithStroke
          variant="secondary"
          disabled={props.isLoginInProgress}
          loading={props.isLoginInProgress}
          type="submit"
          small
        >
          {i18n.translate('login-form.login')}
        </ButtonWithStroke>
      </ButtonWrapper>
      <FingerprintContainer />
    </FormWrapper>
  )
}

NewLoginForm.propTypes = {
  clientType: PropTypes.string,
  isLoginInProgress: PropTypes.bool,
  returningUsername: PropTypes.string,
  returningUserAvatar: PropTypes.string,
}
