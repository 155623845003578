import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router'
import * as ReactRouterRedux from 'react-router-redux'
import PropTypes from 'prop-types'

import * as ShuftiPro from '@rushplay/shufti-pro'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import Button from '../../../common/button'
import HtmlContent from '../../../common/html-content'
import PageNav from '../../../common/page-nav'
import ProgressionContainer from '../../../common/progression'

import { MyIdentityStatus } from './identity-status'

function getView(selected, verified) {
  if (selected === 'popups') {
    return 'popups'
  } else if (!selected || selected === 'status') {
    return 'status'
  } else if (
    typeof verified === 'undefined' &&
    selected &&
    selected !== 'status'
  ) {
    return 'iframe'
  }
}

export function Component(props) {
  const i18n = I18n.useI18n()

  const dispatch = ReactRedux.useDispatch()

  const view = getView(
    R.path(['query', 'selected'], props.location),
    R.path(['query', 'verified'], props.location)
  )
  return (
    <Common.Box width="95%" maxWidth="1000px" mx="auto" px={2} my={5}>
      {i18n.isExistingTranslation('my-identity.description') && (
        <Common.Box
          fontSize={[1, 3]}
          lineHeight="24px"
          mt="8"
          mb="10"
          maxWidth={[null, '66%', null, '50%']}
          mx="auto"
          textAlign="center"
        >
          <HtmlContent
            html={{
              __html: i18n.translate('my-identity.description'),
            }}
          />
        </Common.Box>
      )}

      {view === 'status' && (
        <MyIdentityStatus
          documentTypes={props.documentTypes}
          onSuccessful={props.onSuccessful}
        />
      )}

      {view === 'iframe' && (
        <Common.Box width="100%" display="flex" justifyContent="center">
          <Common.Box
            display="flex"
            flexDirection="column"
            maxWidth="700px"
            width="100%"
            height={['900px', '700px']}
          >
            <ShuftiPro.Iframe
              height="100%"
              type={R.path(['query', 'selected'], props.location)}
              onSuccessful={() =>
                dispatch(
                  ReactRouterRedux.push({
                    ...location,
                    search: `?selected=status&type=${R.path(
                      ['query', 'selected'],
                      props.location
                    )}&verified=true`,
                  })
                )
              }
              onFailure={() =>
                dispatch(
                  ReactRouterRedux.push({
                    ...location,
                    search: '?selected=status',
                  })
                )
              }
            />

            <Common.Box mt={5} display="flex" justifyContent="center">
              <Button
                onClick={() =>
                  dispatch(
                    ReactRouterRedux.push({
                      ...location,
                      search: '?selected=status',
                    })
                  )
                }
              >
                {i18n.translate('my-identity.iframe.cancel')}
              </Button>
            </Common.Box>
          </Common.Box>
        </Common.Box>
      )}
    </Common.Box>
  )
}

Component.propTypes = {
  documentTypes: PropTypes.array,
  location: PropTypes.object,
  onSuccessful: PropTypes.func,
}

export const Identity = ReactRouter.withRouter(Component)

export function MyIdentity() {
  const i18n = I18n.useI18n()

  const items = [
    {
      label: i18n.translate('dashboard.profile'),
      to: '/dashboard',
    },
    {
      label: i18n.translate('dashboard.my-identity'),
      to: '/dashboard/my-identity',
    },
  ]

  return (
    <React.Fragment>
      <ProgressionContainer />
      <PageNav items={items} />
      <Identity />
    </React.Fragment>
  )
}
