import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styled from '@emotion/styled'

import { baseFontSizeLarge, baseFontSizeSmall } from '../constants/typography'
import { dark40, dark60, success } from '../constants/colors'

import Icon from './icon/icon'
import { ExternalLabel } from './input-field'

const Wrapper = styled.div`
  position: relative;
  text-align: left;
`
const Label = styled.label`
  color: ${dark40};
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  position: absolute;
  top: 17.5%;
  left: 22px;
  right: 0;
  pointer-events: none;
  transform-origin: left top;
  font-size: ${baseFontSizeSmall};
`

const StyledSelect = styled.select`
  font-size: ${baseFontSizeLarge};
  border: 2px solid ${(props) => (props.value ? success : dark60)};
  border-radius: 8px;
  background-image: none;
  background-color: white;
  box-shadow: none;
  appearance: none;
  width: 100%;
  padding: ${(props) =>
    props.externalLabel ? '14px' : props.hasLabel ? '28px 20px 12px' : '20px'};
  &:focus {
    outline: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
`
const Arrow = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -0.5em;
  margin-top: ${(props) => (props.externalLabel ? '0.4em' : '-0.5em')};
  pointer-events: none;
  font-size: 20px;
  color: ${dark40};

  @media screen and (min-width: 375px) {
    right: 20px;
  }
`
class Select extends Component {
  handleChange(event) {
    this.props.onChange(event.target.value)
  }

  render() {
    const { value, name, options, hasExternalLabel, firstOption } = this.props

    if (options == null || options.length === 0) {
      return null
    }

    return (
      <Wrapper>
        {hasExternalLabel ? (
          <ExternalLabel>{this.props.label}</ExternalLabel>
        ) : (
          this.props.label && <Label>{this.props.label}</Label>
        )}
        <StyledSelect
          hasLabel={Boolean(this.props.label)}
          externalLabel={hasExternalLabel}
          name={name}
          value={value}
          onChange={(event) => this.handleChange(event)}
        >
          {firstOption && <option value="">{firstOption}</option>}
          {options.map(renderOption)}
        </StyledSelect>
        <Arrow name="caret-down" externalLabel={hasExternalLabel} />
      </Wrapper>
    )
  }
}

export default Select

function renderOption(option, i) {
  const { value, label } = option

  return (
    <option key={i} value={value}>
      {label}
    </option>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  firstOption: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  hasExternalLabel: PropTypes.bool,
}
