import * as recompose from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { color, font } from '@rushplay/theme'
import { css } from '@emotion/core'

import Modal from '../modal'
import { CloseButton } from '../close-button'
import { headingFont } from '../../constants/typography'
import { withThemeForLightBG } from '../../theme/withThemeForLightBG'

const Wrapper = styled.div`
  max-width: 573px;
  color: ${(props) => (props.themeDark ? color('white') : color('blackish'))};
  font-family: ${font('body')};
  text-align: center;
  margin: 1em;
  padding: 2em;
  background-color: ${(props) =>
    props.themeDark ? color('darkBlue2') : color('white')};
  border-radius: 4px;
  ${(props) =>
    props.hasCloseButton &&
    css`
      position: relative;
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 15px;
    `};
`

const Header = styled.div`
  position: relative;
  margin-bottom: 0.7em;
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 36px;
  ${(props) =>
    props.padding &&
    `
    margin-left: 2em;
    margin-right: 2em;
  `};
`

const Title = styled.div`
  margin-left: 36px;
  margin-right: 36px;
  flex-grow: 1;
  font-family: ${headingFont};
  font-size: 20px;
  font-weight: 700;
`

const PositionTopRight = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
`

function Popup(props) {
  return (
    <Modal
      isOpen={props.isOpen}
      parentSelector={props.parentNode ? () => props.parentNode : undefined}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      onRequestClose={props.onRequestClose}
    >
      <Wrapper
        className={props.className}
        themeDark={props.themeDark}
        hasCloseButton={Boolean(props.onRequestClose)}
      >
        {props.onRequestClose && (
          <PositionTopRight>
            <CloseButton onClick={props.onRequestClose} />
          </PositionTopRight>
        )}
        {props.title && (
          <Header padding={props.headerPadding}>
            <Title>{props.title}</Title>
          </Header>
        )}
        {props.children}
      </Wrapper>
    </Modal>
  )
}

const addTheme = recompose.branch(
  (props) => !props.themeDark,
  recompose.renderComponent(withThemeForLightBG(Popup))
)

export default addTheme(Popup)

Popup.propTypes = {
  themeDark: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headerPadding: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  parentNode: PropTypes.object,
  shouldCloseOnOverlayClick: PropTypes.bool,
  title: PropTypes.string,
  onRequestClose: PropTypes.func,
}
