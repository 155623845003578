import * as PropTypes from 'prop-types'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import * as App from '../../../store/app'
import Badge from '../../../common/badge'
import Icon from '../../../common/icon/icon'

const MenuItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const StyledLink = styled(Common.Link)`
  width: 100%;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `} &:hover .menu-icon {
    ${(props) =>
      !props.disabled &&
      css`
        transform: scale(1.1);
      `};
  }

  &:focus {
    outline: 0;
    transform: scale(1.1);
  }
`

function renderCount(link, authenticated, treasureCount, promotionsCount) {
  if (authenticated) {
    if (link.to === 'promotions') {
      return promotionsCount
    }
    if (link.to === 'treasure-chest') {
      return treasureCount
    }
  }
  return 0
}

function renderLink(
  link,
  authenticated,
  license,
  treasureCount,
  promotionsCount,
  translate
) {
  return (
    <MenuItem key={link.label}>
      <StyledLink
        disabled={link.disableOnLoggedOut && !authenticated}
        to={link.to}
      >
        <Badge
          count={renderCount(
            link,
            authenticated,
            treasureCount,
            promotionsCount
          )}
        >
          <Common.Box
            as={Icon}
            className="menu-icon"
            name={link.icon}
            display="inline-block"
            fontSize="30px"
          />
        </Badge>
        <Common.Box verticalAlign="super" paddingLeft="20px">
          {translate((authenticated && link.loggedInLabel) || link.label)}
        </Common.Box>
      </StyledLink>
    </MenuItem>
  )
}

function MenuLinks({
  authenticated,
  isSportsbookEnabled,
  license,
  treasureCount,
  promotionsCount,
}) {
  const translate = Herz.I18n.useTranslate()
  const isHorseRacingEnabled = App.getHorseRacingEnabled()

  const links = [
    authenticated && {
      icon: 'coin',
      label: 'menu-links.wallet',
      to: '/wallet/deposit',
    },
    {
      icon: 'ch-adventure',
      label: 'menu-links.my-adventure',
      to: authenticated ? '/adventure' : '/adventure-promo',
    },
    {
      icon: 'ch-casino',
      label: 'menu-links.casino-games',
      to: '/casino',
    },
    isSportsbookEnabled && {
      icon: 'ch-sports',
      label: 'menu-links.sportsbook',
      to: '/sports',
    },
    {
      icon: 'ch-offers',
      label: 'menu-links.offers',
      to: 'promotions',
    },
    {
      icon: 'ch-live-casino',
      label: 'menu-links.live-casino-games',
      to: '/live-casino',
    },
    isHorseRacingEnabled && {
      icon: 'horse-racing',
      label: 'menu-links.horse-racing',
      to: '/horse-racing',
    },
    {
      icon: 'ch-treasure-chest',
      label: 'menu-links.treasure-chest',
      to: 'treasure-chest',
      disableOnLoggedOut: true,
    },
    {
      icon: 'ch-how-to-play',
      label: 'menu-links.how-to-play',
      to: 'how-to-play',
    },
  ]

  return (
    <ul>
      {links
        .filter(Boolean)
        .map((link) =>
          renderLink(
            link,
            authenticated,
            license,
            treasureCount,
            promotionsCount,
            translate
          )
        )}
    </ul>
  )
}

export default MenuLinks

Herz.I18n.Loader.preload(
  [
    'menu-links.wallet',
    'menu-links.my-adventure',
    'menu-links.casino-games',
    'menu-links.sportsbook',
    'menu-links.live-casino-games',
    'menu-links.horse-racing',
    'menu-links.offers',
    'menu-links.treasure-chest',
    'menu-links.how-to-play',
  ],
  MenuLinks
)

MenuLinks.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  isSportsbookEnabled: PropTypes.bool,
  license: PropTypes.string,
  treasureCount: PropTypes.number.isRequired,
  promotionsCount: PropTypes.number.isRequired,
}
