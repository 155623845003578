import * as R from 'ramda'
import effects from 'redux-effects'
import localStorage from 'redux-effects-localstorage'
import location from 'redux-effects-location'
import multi from 'redux-multi'
import soundsMiddleware from 'redux-sounds'
import thunk from 'redux-thunk'
import timeout from 'redux-effects-timeout'
import fetch, { fetchEncodeJSON } from 'redux-effects-fetch'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { format, parse } from 'date-fns'
import {
  push as redirectTo,
  routerMiddleware,
  routerReducer,
} from 'react-router-redux'

import * as analytics from '@rushplay/analytics'
import * as locks from '@rushplay/compliance/locks'
import * as websockets from '@rushplay/websockets'
import * as api from '@rushplay/api-client'
import * as netrefer from '@rushplay/analytics/netrefer'
import * as maps from '@rushplay/analytics/maps'
import * as gtm from '@rushplay/analytics/gtm'
import * as gamblingHistory from '@rushplay/compliance/gambling-history'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import * as limits from '@rushplay/compliance/limits'
import * as processes from '@rushplay/processes'
import * as supportChat from '@rushplay/support-chat'
import * as forms from '@rushplay/forms'
import * as Notifications from '@rushplay/notifications'
import inventory from '@rushplay/inventory'
import { reducer as casinoReducer } from '@rushplay/casino'
import { debounceMiddleware } from '@rushplay/common'
import { reducer as formsReducer } from '@rushplay/legacy-forms'
import { getSessionToken, reducer as sessionReducer } from '@rushplay/session'
import { reducer as storeReducer } from '@rushplay/store'

import bombSound from '../images/bossfight/sounds/silly-explosion.mp3'
import bossHitsHero from '../images/bossfight/sounds/boss-hits-hero.mp3'
import buttonClick from '../images/bossfight/sounds/click-button.mp3'
/*
 * TODO: Move these to CDN when we've changed
 * to fetch the config before we configure the store
 */
import healthPicked from '../images/bossfight/sounds/health-picked.mp3'
import heroHitsBoss from '../images/bossfight/sounds/hero-hits-boss.mp3'
import popupsReducer from '../common/popup/popups.js'
import prependLanguage from '../prepend-language'
import serverTimeOffsetMiddleware from '../server-time-offset-middleware'
import shuffleSound from '../images/bossfight/sounds/shuffle.mp3'
import swooshSound from '../images/bossfight/sounds/swoosh.mp3'
import tileSound from '../images/bossfight/sounds/pick-box.mp3'
import timestampMiddleware from '../redux-effects-timestamp'
import treasurePicked from '../images/bossfight/sounds/treasure-picked.mp3'
import weaponPicked from '../images/bossfight/sounds/weapon-picked.mp3'
import { gtmEvents, mapsEvents, netreferEvents } from '../analytics'

import * as lookup from './lookup'
import * as receipts from './receipts'
import adventure from './adventure'
import home from './home'
import liveCasino from './live-casino'
import playerReducer from './player'
import promotionsReducer from './promotions'
import triggers from './triggers/'
import { appReducer } from './app'
import { boardReducer } from './board'
import { campaignsReducer } from './campaigns'
import { reducer as depositOffersReducer } from './deposit-offers'
import { onboardingReducer } from './onboarding'
import { paytableReducer } from './paytable'
import { signUpReducer } from './sign-up'
import { reducer as translationReducer } from './translations'

function shouldValidateDebounce(action) {
  if (
    action.type === 'EFFECT_COMPOSE' &&
    action.payload.type === 'EFFECT_FETCH_API'
  ) {
    const url = action.payload.payload.url
    // TODO: remove `url.indexOf('/validate/') === 0` check when all backend endpoints
    // are updated to have url which starts with `/validations/`
    if (url.indexOf('/validate/') === 0 || url.indexOf('/validations/') === 0) {
      return { key: url, time: 500 }
    }
  }
}

/**
 *
 * @param {Object} asyncReducers - Object of reducers keyed by reducer's name.
 * e.g {bossfight: bossfightReducer, audio: audioReducer}
 */
function createReducer(asyncReducers) {
  const reducers = {
    adventure: adventure.reducer,
    analytics: analytics.reducer,
    app: appReducer,
    depositOffers: depositOffersReducer,
    board: boardReducer,
    campaigns: campaignsReducer,
    casino: casinoReducer,
    forms: formsReducer,
    formsv2: forms.reducer,
    gamblingHistory: gamblingHistory.reducer,
    home: home.reducer,
    inventory: inventory.reducer,
    jurisdiction: jurisdiction.reducer,
    liveCasino,
    lookup: lookup.reducer,
    locks: locks.reducer,
    triggers: triggers.reducer,
    notifications: Notifications.reducer,
    onboarding: onboardingReducer,
    paytable: paytableReducer,
    player: playerReducer,
    popups: popupsReducer,
    processes: processes.reducer,
    promotions: promotionsReducer,
    receipts: receipts.reducer,
    routing: routerReducer,
    session: sessionReducer,
    signUp: signUpReducer,
    store: storeReducer,
    supportChat: supportChat.reducer,
    translations: translationReducer,
    limits: limits.reducer,
  }

  return combineReducers(R.mergeRight(reducers, asyncReducers))
}

/**
 *
 * @param {Object} store - redux store
 * @param {Object} reducers - Object of reducers keyed by reducer's name.
 * e.g {bossfight: bossfightReducer, audio: audioReducer}
 */
export function injectAsyncReducers(store, reducers) {
  store.asyncReducers = R.mergeRight(store.asyncReducers, reducers)
  store.replaceReducer(createReducer(store.asyncReducers))
}

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function addSessionLimitNotification(limitPeriod, limitValue, expiresAt) {
  return Notifications.add({
    message: 'notification.login-time-limit-exceeded',
    variables: {
      limitPeriod,
      limitValue: limitValue / 6000,
      expiresAt: format(parse(expiresAt), 'DD.MM.YYYY'),
    },
    level: 'error',
  })
}

export function configureStore({ history, initialState }) {
  const apiPath = api.getApiUrl(window.location.hostname)
  const middleware = applyMiddleware(
    multi,
    thunk,
    websockets.middleware({
      bindings: {
        [websockets.LOCKS_CHANGED]: () => locks.fetch(),
        [websockets.SESSION_EXPIRED]: (payload) => [
          redirectTo('/logout'),
          Notifications.add({
            message: 'session-expired',
            level: 'error',
          }),
          R.propEq('reason', 'login_time_limit_exceeded', payload) &&
            addSessionLimitNotification(
              payload.limitPeriod,
              payload.limitValue,
              payload.expiresAt
            ),
        ],
        [websockets.SHUFTI_PRO_UPLOAD_FEEDBACK]: (data) =>
          Notifications.add({
            message: data.validUpload
              ? `my-identity.status.verification.${data.lastUploadedDocumentType}.success`
              : `my-identity.status.verification.${data.lastUploadedDocumentType}.failure`,
            level: data.validUpload ? 'success' : 'error',
            variables: {
              declinedReasons: data.declinedReasons
                ? `
                    <ul>
                      ${R.map(
                        (reason) => `<li>${reason}</li>`,
                        data.declinedReasons
                      )}
                    </ul>
                  `
                : null,
            },
          }),
      },
    }),
    soundsMiddleware({
      shuffle: {
        urls: [shuffleSound],
        rate: 0.8,
      },
      tile: tileSound,
      button: buttonClick,
      bomb: {
        urls: [bombSound],
        volume: 0.75,
      },
      melee: swooshSound,
      treasure_picked: treasurePicked,
      weapon_picked: weaponPicked,
      boss_hits_hero: bossHitsHero,
      health_picked: healthPicked,
      hero_hits_boss: heroHitsBoss,
    }),
    prependLanguage,
    routerMiddleware(history),
    debounceMiddleware([shouldValidateDebounce, api.shouldFetchDebounce]),
    api.middleware({
      host: apiPath,
      substateSelector: (state) => state.api,
      tokenSelector: (state) => getSessionToken(state.session),
    }),
    effects,
    fetch,
    fetchEncodeJSON,
    localStorage(window.localStorage),
    location(),
    analytics.eventQueuingMiddleware({
      events: [gtmEvents, netreferEvents, mapsEvents],
      queueSelector: (state) => analytics.getEventsQueue(state.analytics),
      onError: (error) => {
        if (process.browser && window.onerror) {
          window.onerror(error)
        }
      },
    }),
    gtm.middleware(gtmEvents),
    netrefer.middleware(netreferEvents, {
      clientId: '8d908ecd-f205-408a-9f5a-acbb0c8c11c9',
      endpoint: 'http://tracking.netrefer.com/Tracking.svc/Track',
    }),
    maps.middleware(mapsEvents, {
      endpoint: 'https://creatives.heropartners.io/masterpostback.aspx',
      key: 'so9kmdauot7syi2x',
      reportId: '2',
    }),
    serverTimeOffsetMiddleware,
    timeout(),
    timestampMiddleware
  )

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(middleware)
  )

  store.asyncReducers = {}

  return store
}
