import * as React from 'react'
import * as Urql from 'urql'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import screenfull from 'screenfull'
import url from 'url'
import { locationShape } from 'react-router/lib/PropTypes'

import * as Analytics from '@rushplay/analytics'
import styled from '@emotion/styled'
import { isSessionActive } from '@rushplay/session'

import Icon from '../common/icon/icon'
import { PageSpinner } from '../common/spinner'
import { SeoTextArea } from '../components/seo-text-area'
import { getClientType, getGamerUrl, getLanguage } from '../store/app'

import { GameLauncher } from './game-launcher'

const clientTypes = ['mobile-browser', 'browser']

const getGame = `
  query Game($id: ID!) {
    game(id: $id) {
      category
      displayProvider
      id
      imageUrl
      key
      provider
      title
    }
  }
`

const FULLSCREEN_BTN_SM = '40px'
const FULLSCREEN_BTN_LG = '48px'

const RootContainer = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: calc(
    var(--window-inner-height, 100vh) - var(--window-top-bar-height, 0)
  );

  @media (orientation: landscape) {
    flex-direction: row;
  }

  ${(props) => {
    if (props.isFullscreen) {
      return `
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        height: var(--window-inner-height, 100vh);
        max-height: var(--window-inner-height, 100vh);
        width: 100vw;
      `
    }
  }};
`

const StretchedGameLauncher = styled(GameLauncher)`
  width: 100%;
  height: 100%;

  @media (orientation: landscape) {
    padding-bottom: env(safe-area-inset-bottom, 0);
  }
`

const FullscreenButton = styled.button`
  width: 100%;
  height: ${(props) =>
    props.isFullscreen ? FULLSCREEN_BTN_SM : FULLSCREEN_BTN_LG};
  background-color: ${(props) => (props.isFullscreen ? '#0C2659' : '#0a0a0a')};
  margin: 0 0 env(safe-area-inset-bottom, 0);
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: ${(props) => props.theme.fontSizes[5]};
  cursor: pointer;
  border: none;
  padding: ${(props) => (props.isFullscreen ? '0 10px' : '0 6px')};

  @media (orientation: landscape) {
    width: ${(props) =>
      props.isFullscreen ? FULLSCREEN_BTN_SM : FULLSCREEN_BTN_LG};
    height: auto;
    padding: ${(props) => (props.isFullscreen ? '10px 0' : '6px 0')};
    margin: 0;
    flex-direction: column;

    /* align-items:end is not supported on ios */
    & > :first-child {
      margin-top: auto;
    }
  }
`

function GameContainer(props) {
  const [isFullscreen, setIsFullscreen] = React.useState(false)
  const rootContainerRef = React.useRef(null)

  const [response] = Urql.useQuery({
    query: getGame,
    variables: { id: Number.parseInt(props.params.gameId) },
  })

  const game = React.useMemo(() => {
    if (!response.fetching && !response.error) {
      return response.data.game
    }
    return null
  }, [response.fetching, response.error, response.data])

  const gameTitle = React.useMemo(
    () =>
      game !== null
        ? `${game.title} - ${game.displayProvider || game.provider}`
        : 'Gameplay',
    [game]
  )

  const dispatch = ReactRedux.useDispatch()
  const sessionState = ReactRedux.useSelector(
    (state) => state.session,
    ReactRedux.shallowEqual
  )
  const appState = ReactRedux.useSelector(
    (state) => state.app,
    ReactRedux.shallowEqual
  )

  // TODO replace with useLocation after react router upgrade
  const location = props.location
  const clientType = getClientType(appState)
  const allowFullscreen = clientType !== 'browser'
  const authenticated = isSessionActive(sessionState)
  const language = getLanguage(appState)
  const sessionToken = sessionState.token

  function toggleFullscreen() {
    setIsFullscreen((prevState) => !prevState)

    if (screenfull.enabled) {
      screenfull.toggle(rootContainerRef.current)
    }
  }

  const gameUrl = React.useMemo(() => {
    if (!clientTypes.includes(clientType) || game === null) {
      return null
    }

    return url.format({
      pathname: `${getGamerUrl(appState)}/games/${game.id}`,
      query: {
        client_type: clientType,
        free_games: location.query.freeGames,
        language,
        mode: 'classic',
        token: sessionToken,
      },
    })
  }, [game, clientType, appState, language, location, sessionState])

  // this is needed when user exits fullscreen with native UI
  React.useEffect(() => {
    function exitFullscreen() {
      if (!screenfull.isFullscreen) {
        setIsFullscreen(false)
      }
    }

    if (screenfull.enabled) {
      screenfull.on('change', exitFullscreen)
    }

    return () => {
      if (screenfull.enabled) {
        screenfull.off('change', exitFullscreen)
      }
    }
  }, [screenfull.enabled])

  React.useEffect(() => {
    if (!authenticated) {
      dispatch(
        Analytics.playForFun({
          title: game !== null ? game.key : '',
          logged: authenticated,
        })
      )
    }
  }, [authenticated, dispatch, game])

  if (response.fetching) {
    return <PageSpinner />
  }

  if (game === null) {
    return null
  }

  return (
    <React.Fragment>
      <RootContainer ref={rootContainerRef} isFullscreen={isFullscreen}>
        <StretchedGameLauncher
          gameCategory={game.category}
          gameId={game.id}
          gameImage={game.imageUrl}
          gameProvider={game.provider || game.displayProvider}
          gameTitle={gameTitle}
          gameUrl={gameUrl}
          location={location}
          title={game.title}
        />
        {allowFullscreen && (
          <FullscreenButton
            onClick={toggleFullscreen}
            isFullscreen={isFullscreen}
            isEmbedded
          >
            <Icon name={isFullscreen ? 'arrows-in' : 'arrows-out'} />
          </FullscreenButton>
        )}
      </RootContainer>
      <SeoTextArea
        translationKey={`casino-slots-${game.provider.toLowerCase()}-${game.key.toLowerCase()}-page.seo-content`}
      />
    </React.Fragment>
  )
}

GameContainer.propTypes = {
  children: PropTypes.node,
  location: locationShape,
  params: PropTypes.objectOf(PropTypes.string),
}

export default GameContainer
