import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Session from '@rushplay/session'
import styled from '@emotion/styled'

import * as App from '../../store/app'
import * as Colors from '../../constants/colors'
import { HamburgerMenu } from '../hamburger-menu'

import { Authentication } from './authentication'
import { Logo } from './logo'
import { MainMenu } from './main-menu'
import { MobileMenu } from './mobile-menu'
import { UserMenu } from './user-menu'

const HamburgerWrapper = styled.div`
  display: block;

  @media screen and (min-width: 1280px) {
    display: none;
  }
`

const MobileMenuWrapper = styled.div`
  height: ${(props) => (props.isMenuActive ? '100%' : 0)};
  opacity: ${(props) => (props.isMenuActive ? 1 : 0)};
  display: block;
  position: fixed;
  width: 100%;
  background-color: ${Colors.darkBlue1};
  transition: all 0.3s ease;
  overflow: auto;
  padding-bottom: ${(props) => (props.isMenuActive ? '150px' : 0)};

  @media screen and (min-width: 1372px) {
    display: none;
  }
`

export function TopBar() {
  const rootRef = React.useRef(null)

  const authenticated = ReactRedux.useSelector((state) =>
    Session.isSessionActive(state.session)
  )
  const isMenuActive = ReactRedux.useSelector((state) =>
    App.getMobileMenuVisibility(state)
  )
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    document.body.style.overflow = isMenuActive ? 'hidden' : 'auto'
    document.body.style.position = isMenuActive ? 'fixed' : 'relative'
    document.body.style.width = isMenuActive ? '100%' : 'auto'

    dispatch(App.setMobileMenuVisibility(isMenuActive))
  }, [isMenuActive])

  React.useLayoutEffect(() => {
    function handleResize() {
      if (rootRef.current !== null) {
        const height = `${rootRef.current.offsetHeight}px`
        document.documentElement.style.setProperty(
          '--window-top-bar-height',
          height
        )
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Common.Box
      position="sticky"
      zIndex="101"
      top="0"
      backgroundColor={Colors.darkBlue1}
      ref={rootRef}
    >
      <React.Suspense fallback={null}>
        <Common.Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          maxWidth="1440px"
          margin="auto"
        >
          <Common.Box display="flex" alignItems="center" width="100%">
            <Logo />
            <MainMenu />

            <Common.Box marginLeft="auto">
              {authenticated ? <UserMenu /> : <Authentication />}
            </Common.Box>
          </Common.Box>

          <HamburgerWrapper>
            <HamburgerMenu />
          </HamburgerWrapper>
        </Common.Box>
        <MobileMenuWrapper isMenuActive={isMenuActive}>
          <MobileMenu isActive={isMenuActive} />
        </MobileMenuWrapper>
      </React.Suspense>
    </Common.Box>
  )
}
