import PropTypes from 'prop-types'
import React from 'react'
import { timingFunctions } from 'polished'

import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { white } from '../constants/colors'

import Spinner from './spinner'
import { TextWithStroke } from './text-with-stroke'

function shadow(shadowColor, small) {
  if (small) {
    return css`
      box-shadow: inset 0 0 0 2px ${shadowColor}, inset 0 4px 0 ${white},
        inset 0 0 0 3px ${white};
    `
  }
  return css`
    box-shadow: inset 0 0 0 4px ${shadowColor}, inset 0 7px 0 ${white},
      inset 0 0 0 5px ${white};
  `
}

function borderWidth(small) {
  if (small) {
    return css`
      left: 2px;
      top: 2px;
      bottom: 2px;
      right: 2px;
    `
  }
  return css`
    left: 3px;
    top: 3px;
    bottom: 3px;
    right: 3px;
  `
}

function padding(small) {
  if (small) {
    return css`
      padding: 12px 24px;
    `
  }
  return css`
    padding: 12px 32px;
  `
}

function background(bgColor1, bgColor2, bgColor3) {
  return css`
    background: linear-gradient(
      180deg,
      ${bgColor1} 0%,
      ${bgColor2} 54.69%,
      ${bgColor3} 75.52%
    );
  `
}

function after(shadowColor, bgColor1, bgColor2, bgColor3, small) {
  return css`
    &:after {
      content: ' ';
      position: absolute;
      cursor: pointer;
      z-index: -1;
      border-radius: 100px;
      ${background(bgColor1, bgColor2, bgColor3)}
      ${shadow(shadowColor, small)};
      ${borderWidth(small)}
    }
  `
}

function hover(bgColor1, bgColor2, bgColor3) {
  return css`
    &:hover:after {
      ${background(bgColor1, bgColor2, bgColor3)};
    }
  `
}

function active(bgColor1, bgColor2, bgColor3) {
  return css`
    &:active:after {
      ${background(bgColor1, bgColor2, bgColor3)};
    }
  `
}

function primaryButton(small) {
  return css`
    position: relative;
    z-index: 0;
    ${background('#E7D31B', '#73572D', '#C3B31A')}
    ${padding(small)}
    ${hover('#D9FFB3', '#14C725', '#6FEDC8')}
    ${active('#39D4A6', '#09A218', '#367217')}
    ${after('#004135', '#9AFF35', '#09A218', '#39D4A6', small)}
  `
}

function secondaryButton(small) {
  return css`
    position: relative;
    z-index: 0;
    ${background('#B4B4B4', '#616161', '#A7A7A7')}
    ${padding(small)}
    ${hover('#91D7F5', '#4671BD', '#6C8BF7')}
    ${active('#30BAF5', '#2965D1', '#324BA5')}
    ${after('#23375C', '#30BAF5', '#2965D1', '#5176F7', small)}
  `
}

function tertiaryButton(small) {
  return css`
    position: relative;
    z-index: 0;
    ${background('#B4B4B4', '#616161', '#73BBFE')}
    ${padding(small)}
    ${hover('#91D7F5', '#4671BD', '#6C8BF7')}
    ${active('#30BAF5', '#2965D1', '#324BA5')}
    ${after('#23375C', '#30BAF5', '#2965D1', '#5176F7', small)}
  `
}

function disabledButton(small) {
  return css`
    position: relative;
    z-index: 0;
    cursor: not-allowed;
    ${background('#B4B4B4', '#616161', '#A7A7A7')}
    ${padding(small)}
    ${hover('#B4B4B4', '#616161', '#A7A7A7')}
    ${active('#B4B4B4', '#616161', '#A7A7A7')}
    ${after('#23375C', '#B4B4B4', '#616161', '#A7A7A7', small)}
  `
}

function buttonTheme(props) {
  if (props.disabled) {
    return disabledButton(props.small)
  }

  if (props.variant === 'primary') {
    return primaryButton(props.small)
  }

  if (props.variant === 'tertiary') {
    return tertiaryButton(props.small)
  }

  return secondaryButton(props.small)
}

const Wrapper = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'M PLUS 1p', sans-serif;
  font-style: normal;
  border-radius: 100px;
  box-sizing: border-box;
  font-size: ${(props) => (props.small ? 16 : 24)}px;
  font-weight: ${(props) => (props.small ? 700 : 800)};
  width: ${(props) => (props.stretch ? '100%' : 'auto')};
  min-width: ${(props) => (props.small ? 34 : 46)}px;
  min-height: ${(props) => (props.small ? 34 : 46)}px;

  @media (max-width: 1000px) {
    font-size: ${(props) => props.small && 14}px;
    padding: ${(props) => props.small && '8px 20px'};
  }

  ${buttonTheme};
`

const ButtonContent = styled.div`
  opacity: ${(props) => (props.loading ? '0' : '1')};
  transition: opacity 0.3s ${timingFunctions('easeInSine')};
  display: inline-flex;
  align-items: center;
  flex: 1;
  justify-content: center;
`

const ButtonSpinner = styled(Spinner)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1.5em;
  color: white;
  opacity: ${(props) => (props.loading ? '1' : '0')};
  transition: opacity 0.3s ${timingFunctions('easeInSine')};
`

export function ButtonWithStroke(props) {
  return (
    <Wrapper
      variant={props.variant}
      onClick={props.onClick}
      stretch={props.stretch}
      small={props.small}
      disabled={props.disabled}
    >
      <ButtonContent loading={props.loading}>
        <TextWithStroke
          textColor="white"
          borderColor={props.variant !== 'primary' ? '#2D4675' : '#005307'}
          borderWidth={props.small ? '5px' : '7px'}
          borderMobileWidth={props.small ? '4px' : '6px'}
        >
          {props.children}
        </TextWithStroke>
      </ButtonContent>
      {props.loading ? <ButtonSpinner loading={props.loading} /> : null}
    </Wrapper>
  )
}

ButtonWithStroke.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  onClick: PropTypes.func,
  stretch: PropTypes.bool,
  small: PropTypes.bool,
}
