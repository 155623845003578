import * as React from 'react'
import PropTypes from 'prop-types'
import Url from 'url'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

export function ProofImage({ url, ...props }) {
  const i18n = I18n.useI18n()

  if (Url.parse(url).pathname.endsWith('.pdf')) {
    return (
      <Common.Box
        as="a"
        backgroundImage={`url(${i18n.translate(
          'my-identity.proof-image.pdf'
        )})`}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="contain"
        display="inline-block"
        href={url}
        target="_blank"
        {...props}
      />
    )
  }

  return <Common.Box as="img" src={url} {...props} />
}

ProofImage.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  url: PropTypes.string.isRequired,
}
