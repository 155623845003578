import * as Urql from 'urql'

const getConfiguration = `
  query Config {
    configuration {
      apgSealScriptId
      betbyScriptUrl
      betbyBrandId
      brand
      environment
      imgproxyUrl
      liveChatKey
      pusher {
        authEndpoint
        cluster
        enabledTransports
        encrypted
        key
      }
      country {
        alpha2
        enabled
        countryCode
      }
      countries {
        alpha2
        name
        countryCode
      }
      currencies {
        code
      }
      currency {
        code
      }
      liveChatDepartment {
        fallback
        primary
      }
      locale {
        code
        slug
        language
      }
    }
  }
`

export function useConfiguration() {
  const [config] = Urql.useQuery({ query: getConfiguration })

  if (config.data) {
    return config.data.configuration
  }

  return {}
}
