import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

import * as combinedSelectors from '../combined-selectors'
import * as lookup from '../store/lookup'
import NotFound from '../components/not-found'
import { SeoTextArea } from '../components/seo-text-area'
import { getClientType, isCasitabi } from '../store/app'

import GamesSection from './games-section'
import { tagPrefix } from './tag-prefix'

const Sections = styled.article`
  margin-left: auto;
  margin-right: auto;
  max-width: 1243px;
  margin-bottom: 15px;
`

const CATEGORY_ICON_MAPPING = {
  slots: 'slots',
  jackpot: 'jackpot',
  roulette: 'chip',
  blackjack: 'blackjack',
  'table-games': 'dice',
  'other-games': 'other-games',
}

function CategoryContainer(props) {
  if (
    !props.isExistingTranslation(`casino.categories.${props.params.category}`)
  ) {
    return <NotFound />
  }

  const categoryKey = `category${R.replace(/-/g, '', props.params.category)}`

  const categoryQuery = R.mergeDeepLeft(getCategoryParams(props), { limit: 24 })

  function addValues(key, left, right) {
    return R.is(Object, left) ? R.concat(left, right) : right
  }
  const favoritesQuery =
    categoryKey !== 'categorynew'
      ? R.mergeDeepWithKey(addValues, categoryQuery, {
          tags: [props.tagPrefix('sagaLikes')],
          limit: 6,
        })
      : { tags: [props.tagPrefix('sagaLikes')], limit: 6, order: 'sort_order' }

  return (
    <React.Fragment>
      <Common.Box minHeight={['calc(100vh - 100px)', 'calc(100vh - 140px)']}>
        <Sections>
          <GamesSection
            id={`${categoryKey}Favorites`}
            params={favoritesQuery}
            heading={props.translate('casino.categories.favorite-games')}
            icon="casinoheroes"
            tagPrefix={props.tagPrefix}
            placeholders
          />

          <GamesSection
            id={categoryKey}
            params={categoryQuery}
            heading={props.translate(`casino.categories.${props.category}`)}
            icon={CATEGORY_ICON_MAPPING[props.category]}
            loadMore
            tagPrefix={props.tagPrefix}
          />
        </Sections>
      </Common.Box>
      <SeoTextArea
        translationKey={`casino-${props.category}-page.seo-content`}
      />
    </React.Fragment>
  )
}

export default connect(mapStateToProps)(withTranslate(CategoryContainer))

function mapStateToProps(state, ownProps) {
  return {
    category: ownProps.params.category,
    clientType: getClientType(state.app),
    countryCode: lookup.getCountryCode(state.lookup),
    tagPrefix: tagPrefix(isCasitabi(state)),
    market: combinedSelectors.getMarket(state),
  }
}

function getCategoryParams(props) {
  if (props.category === 'jackpot') {
    return { jackpot: true, order: 'sort_order' }
  }

  if (props.category === 'new') {
    return { tags: ['new'], order: 'sort_order' }
  }

  return R.mergeDeepLeft(
    { tags: [props.tagPrefix(props.category)] },
    R.includes(props.category, ['roulette', 'blackjack', 'table-games']) && {
      order: 'sort_order',
    }
  )
}

CategoryContainer.propTypes = {
  application: PropTypes.object,
  category: PropTypes.string,
  clientType: PropTypes.string,
  countryCode: PropTypes.string,
  favorites: PropTypes.array,
  games: PropTypes.array,
  isExistingTranslation: PropTypes.func.isRequired,
  market: PropTypes.string,
  params: PropTypes.object,
  registrationCountryCode: PropTypes.string,
  tagPrefix: PropTypes.func,
  translate: PropTypes.func,
}
