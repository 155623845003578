import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'

import * as Colors from '../../constants/colors'
import Icon from '../../common/icon/icon'

function ReturningUsernameField(props) {
  return (
    <Common.Box
      isLoginPage={props.isLoginPage}
      onClick={props.onClear}
      isBig={props.isBig}
      position="relative"
      borderRadius="8px"
      borderColor={Colors.dark60}
      borderWidth="2px"
      borderStyle="solid"
      display="flex"
      alignItems="center"
      height={props.isBig ? '65px' : ['55px', '48px']}
      backgroundColor={Colors.white}
      cursor="pointer"
    >
      <Common.Box
        isLoginPage={props.isLoginPage}
        width={props.isBig ? '38px' : '34px'}
        height={props.isBig ? '38px' : '34px'}
        paddingLeft={props.isBig ? '15px' : ['12px', null, null, null, '6px']}
        paddingRight="8px"
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Common.Box
          as={Common.Image}
          src={props.returningUserAvatar}
          width="100%"
          height="100%"
          backgroundColor={Colors.white}
          borderRadius="50%"
          borderColor={Colors.darkBlue1}
          borderWidth="2px"
          borderStyle="solid"
        />
      </Common.Box>
      <Common.Box
        position="relative"
        color={Colors.black}
        fontSize="16px"
        fontWeight="500"
        fontFamily="'M PLUS 1p', sans-serif"
        textAlign="start"
        lineHeight="initial"
        width={
          props.isBig
            ? 'calc(100% - 114px)'
            : ['calc(100% - 100px)', null, null, null, 'calc(100% - 86px)']
        }
        overflowX="hidden"
      >
        {props.value}
      </Common.Box>
      <Common.Box
        as={Common.Text}
        display="flex"
        position="absolute"
        right={props.isBig ? '14px' : ['10px', null, null, '5px']}
        top="0"
        fontSize="16px"
        alignItems="center"
        justifyContent="center"
        width="28px"
        height="100%"
        color={Colors.dark40}
      >
        <Icon name="edit" />
      </Common.Box>
    </Common.Box>
  )
}

ReturningUsernameField.propTypes = {
  returningUserAvatar: PropTypes.string,
  translate: PropTypes.func,
  value: PropTypes.string,
  onClear: PropTypes.func,
  isLoginPage: PropTypes.bool,
  isBig: PropTypes.bool,
}

export default ReturningUsernameField
