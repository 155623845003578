import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

import * as combinedSelectors from '../combined-selectors'
import { SeoTextArea } from '../components/seo-text-area'
import { getClientType, isCasitabi } from '../store/app'

import GamesSection from './games-section'
import { tagPrefix } from './tag-prefix'

const Wrapper = styled.article`
  max-width: 1243px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.8vw;
`

const NUMBER_OF_GAMES_IN_SECTION = 6

function section(icon, heading, gameQuery, path) {
  return {
    icon,
    heading,
    gameQuery: R.assoc('limit', NUMBER_OF_GAMES_IN_SECTION, gameQuery),
    path,
  }
}

function getCategorySections(prefix) {
  const SECTIONS = [
    section('repeat', 'recently-played', { recent: true }),
    section('new', 'new-games', { tags: ['new'], order: 'sort_order' }),
    section('thumb-up', 'popular-games', {
      tags: [prefix('popular')],
      order: 'sort_order',
    }),
    section('featured', 'featured', {
      tags: [prefix('featured')],
      order: 'sort_order',
    }),
    section('casinoheroes', 'favorite-games', {
      tags: [prefix('sagaLikes')],
      order: 'sort_order',
    }),
    section('smiley', 'sexy', { tags: ['sexy'], order: 'sort_order' }),
    section('slots', 'pachislot', { tags: ['pachislot'], order: 'sort_order' }),
    section('slots', 'slots', { tags: [prefix('slots')] }, '/casino/slots'),
    section(
      'jackpot',
      'jackpot',
      { jackpot: true, order: 'sort_order' },
      '/casino/jackpot'
    ),
    section(
      'chip',
      'roulette',
      {
        tags: [prefix('roulette')],
        order: 'sort_order',
      },
      '/casino/roulette'
    ),
    section(
      'blackjack',
      'blackjack',
      {
        tags: [prefix('blackjack')],
        order: 'sort_order',
      },
      '/casino/blackjack'
    ),
    section(
      'dice',
      'table-games',
      {
        tags: [prefix('table-games')],
        order: 'sort_order',
      },
      '/casino/table-games'
    ),
    section(
      'money',
      'scratch',
      { tags: [prefix('scratch')] },
      '/casino/scratch'
    ),
    section(
      'other-games',
      'other-games',
      { tags: [prefix('other-games')] },
      '/casino/other-games'
    ),
  ]

  return R.reject((val) => !val, SECTIONS)
}

function CasinoHomeContainer(props) {
  return (
    <React.Fragment>
      <Common.Box minHeight={['calc(100vh - 100px)', 'calc(100vh - 140px)']}>
        <Wrapper>
          {R.map((section) => {
            return (
              <GamesSection
                key={R.replace(/-/g, '', section.heading)}
                id={R.replace(/-/g, '', section.heading)}
                params={section.gameQuery}
                heading={props.translate(
                  `casino.categories.${section.heading}`
                )}
                icon={section.icon}
                tagPrefix={props.tagPrefix}
                path={section.path}
                placeholders
              />
            )
          }, getCategorySections(props.tagPrefix))}
        </Wrapper>
      </Common.Box>
      <SeoTextArea translationKey="casino-page.seo-content" />
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    clientType: getClientType(state.app),
    tagPrefix: tagPrefix(isCasitabi(state)),
    market: combinedSelectors.getMarket(state),
  }
}

CasinoHomeContainer.propTypes = {
  authenticated: PropTypes.bool,
  clientType: PropTypes.string,
  countryCode: PropTypes.string,
  gamesFetched: PropTypes.bool,
  games: PropTypes.array,
  market: PropTypes.string,
  quickGames: PropTypes.array,
  registrationCountryCode: PropTypes.string,
  tagPrefix: PropTypes.func,
  translate: PropTypes.func,
}

export default withTranslate(connect(mapStateToProps)(CasinoHomeContainer))
