import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'

import isAtCasinoGame from '../../util/at-casino-game'
import { getCasinoHeaderLinks } from '../../store/selectors'
import { tagPrefix } from '../tag-prefix'

import { CasinoRootContainer as Component } from './casino-root-container'

function mapStateToProps(state, ownProps) {
  return {
    isAtCasinoGame: isAtCasinoGame(ownProps.location.pathname),
    license: jurisdiction.getLicense(state.jurisdiction),
    links: getCasinoHeaderLinks(state),
    tagPrefix: tagPrefix(true),
    depositNumber: state.player.depositNumber,
  }
}

export const CasinoRootContainer = connect(mapStateToProps)(
  withRouter(Component)
)
