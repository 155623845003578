import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Colors from './constants/colors'
import Button from './common/button'

export function DepositBanner() {
  const i18n = I18n.useI18n()

  return (
    <Common.Box
      width="100%"
      maxWidth="1243px"
      display="flex"
      justifyContent="center"
      mt={7}
      mb={6}
      mx="auto"
      bg={Colors.lightBlue1}
    >
      <Common.Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        px={4}
      >
        <Common.Box
          display={['none', null, null, 'block']}
          alignSelf="flex-end"
          width="180px"
          height="75px"
          mr={3}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundImage={`url(${i18n.translate('deposit-banner.image')})`}
        />
        <Common.Box
          textAlign={['start', null, 'center']}
          pr={4}
          py={3}
          fontSize={[2, null, 3]}
          fontFamily="mplus-1p"
          fontWeight="800"
          lineHeight="1.3"
          color={Colors.white}
        >
          {i18n.translate('deposit-banner.text')}
        </Common.Box>
        <Common.Box py={5}>
          <Common.Link to="/wallet/deposit">
            <Button minimalPadding variant="primary">
              {i18n.translate('deposit-banner.button')}
            </Button>
          </Common.Link>
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}
