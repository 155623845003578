import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import HtmlContent from '../common/html-content'
import PopupContainer from '../common/popup/popup-container'
import { PopupScrollable } from '../common/popup/popup-styles.js'
import { createFileFromString } from '../create-file-from-string'

function TermsConditions(props) {
  const contentRef = React.useRef(null)
  return (
    <PopupContainer
      id="terms-conditions"
      isOpen={props.visible}
      title={props.translate('register.form.terms-conditions')}
      onRequestClose={props.onClose}
    >
      <PopupScrollable>
        <Common.Space bottom={6}>
          <Common.Box ref={contentRef}>
            <HtmlContent
              html={{ __html: props.translate('terms.and.conditions.general') }}
            />
          </Common.Box>
        </Common.Space>
        <Common.Space bottom={8}>
          <Common.Box
            onClick={() =>
              createFileFromString(
                contentRef.current.innerText,
                `CasiTabi-general-terms-and-conditions`
              )
            }
            style={{
              margin: 'auto',
              width: 'fit-content',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            {props.translate('terms-and-condition.general.download')}
          </Common.Box>
        </Common.Space>
      </PopupScrollable>
    </PopupContainer>
  )
}

export default withTranslate(TermsConditions)

TermsConditions.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
