import * as FileSaver from 'file-saver'

export function createFileFromString(text, name) {
  // hack for browsers, this line will set character encoding
  // https://stackoverflow.com/questions/6672834/specifying-blob-encoding-in-google-chrome
  const BOM = new Uint8Array([0xef, 0xbb, 0xbf])

  const blob_BOM = new Blob([BOM, text])
  FileSaver.saveAs(blob_BOM, `${name}.txt`)
}
