import * as R from 'ramda'

import * as api from '@rushplay/api-client'
import * as websockets from '@rushplay/websockets'
import * as processes from '@rushplay/processes'

import * as processIds from '../constants/processes'
import toDepositCampaign from '../util/to-deposit-campaign'

const CLEAR_CAMPAIGNS = 'casino-heroes/campaigns/CLEAR_CAMPAIGNS'
const END_CAMPAIGN = 'casino-heroes/campaigns/END_CAMPAIGN'
const START_CAMPAIGN = 'casino-heroes/campaigns/START_CAMPAIGN'
const UPDATE_CAMPAIGNS = 'casino-heroes/campaigns/UPDATE_CAMPAIGNS'
const OPT_IN_STATUS_UPDATED = 'casino-heroes/campaigns/OPT_IN_STATUS_UPDATED'
const OPT_OUT_CAMPAIGN = 'casino-heroes/campaigns/OPT_OUT_CAMPAIGN'

export function clearCampaigns() {
  return { type: CLEAR_CAMPAIGNS }
}

export function updateCampaigns(campaigns) {
  return {
    type: UPDATE_CAMPAIGNS,
    payload: R.map(toDepositCampaign, campaigns),
  }
}

export function startCampaign(campaign) {
  return {
    type: START_CAMPAIGN,
    payload: toDepositCampaign(campaign),
  }
}

export function endCampaign(campaign) {
  return {
    type: END_CAMPAIGN,
    payload: campaign,
  }
}

export function optIn(campaignId) {
  return {
    type: OPT_IN_STATUS_UPDATED,
    payload: {
      id: campaignId,
      state: 'in',
    },
  }
}

export function optOut(campaignId) {
  return {
    type: OPT_OUT_CAMPAIGN,
    payload: {
      id: campaignId,
    },
  }
}

/**
 * Fetch all active campaigns and write them to state
 * @return {Object} Redux action
 */
export function fetch() {
  return [
    processes.start(processIds.FETCH_CAMPAIGNS),
    api.fetchAllActiveCampaigns({
      success: (res) => [
        processes.stop(processIds.FETCH_CAMPAIGNS),
        updateCampaigns(R.indexBy(R.prop('id'), res.value)),
      ],
      failure: () => processes.stop(processIds.FETCH_CAMPAIGNS),
      version: 2,
    }),
  ]
}

const initialState = {}

export function campaignsReducer(state = initialState, action) {
  switch (action.type) {
    case websockets.SESSION_EXPIRED:
    case CLEAR_CAMPAIGNS: {
      return initialState
    }
    case websockets.CAMPAIGNS_CHANGED:
    case UPDATE_CAMPAIGNS: {
      return R.mergeDeepRight(state, action.payload)
    }
    case websockets.CAMPAIGN_STARTED:
    case START_CAMPAIGN: {
      return R.assoc(
        action.payload.id,
        toDepositCampaign(action.payload),
        state
      )
    }
    case websockets.CAMPAIGN_ENDED:
    case END_CAMPAIGN: {
      return R.dissoc(action.payload, state)
    }
    case OPT_IN_STATUS_UPDATED: {
      return R.assocPath(
        [action.payload.id, 'optInState'],
        action.payload.state,
        state
      )
    }
    case OPT_OUT_CAMPAIGN: {
      const campaignId = action.payload.id
      // eslint-disable-next-line no-unused-vars
      const { [campaignId]: removedCampaign, ...updatedState } = state

      return updatedState
    }

    default:
      return state
  }
}

export function getActiveDepositCampaign(campaigns) {
  return R.find(
    R.allPass([
      R.propEq('rewardTrigger', 'deposit'),
      R.anyPass([
        R.pathEq(['optInState'], 'in'),
        R.pathEq(['optInState'], null),
      ]),
    ]),
    R.values(campaigns)
  )
}

export function getActiveRaceCampaign(campaigns) {
  return R.find(
    R.propSatisfies(R.test(/^aggregating_against_/), 'type'),
    R.values(campaigns)
  )
}

export function getActiveBoosterCampaign(campaigns) {
  return R.pipe(
    R.values(),
    R.pluck('ppMultiplier'),
    R.reduce(R.multiply(), 1)
  )(campaigns)
}

export function isBeatBossesCampaignActive(campaigns) {
  return R.any(
    R.propEq('rewardTrigger', 'player_boss_win'),
    R.values(campaigns)
  )
}
