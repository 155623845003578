import * as R from 'ramda'
import React from 'react'

import * as Common from '@rushplay/common'
import * as notifications from '@rushplay/notifications'
import * as I18n from '@rushplay/i18n'

import Toast from '../common/toast'

export function Notifications() {
  const i18n = I18n.useI18n()

  return (
    <notifications.Notifications mountPoint="notifications">
      {(notificationIds) => (
        <Common.Box
          position="fixed"
          zIndex="999"
          width={['100%', null, 'auto']}
          left="50%"
          transform="translateX(-50%)"
          style={{
            'box-sizing': 'border-box',
            transform: 'translateX(-50%)',
          }}
        >
          {R.map(
            (notificationId) => (
              <Common.Box
                as={Common.Flex}
                key={notificationId}
                column
                alignItems="center"
                wide
                padding="15px 15px 0 15px"
              >
                <Common.Constraint
                  as={Common.Box}
                  width="100%"
                  maxWidth="500px"
                  centered
                >
                  <notifications.Notification
                    id={notificationId}
                    mountPoint="notifications"
                  >
                    {(notification) => (
                      <Toast
                        text={
                          I18n.isExistingTranslation(notification.message)
                            ? i18n.translate(
                                notification.message,
                                notification.variables
                              )
                            : notification.message
                        }
                        variant={notification.level}
                        onDismiss={() => notification.onDismiss(notificationId)}
                      />
                    )}
                  </notifications.Notification>
                </Common.Constraint>
              </Common.Box>
            ),
            notificationIds
          )}
        </Common.Box>
      )}
    </notifications.Notifications>
  )
}
