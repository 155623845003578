import PropTypes from 'prop-types'

import styled from '@emotion/styled'

export const TextWithStroke = styled.div`
  position: relative;
  box-sizing: border-box;

  ${(props) => {
    if (props.textGradient) {
      return `
        background: ${props.textGradient};
        background-clip: border-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `
    }
    return `
      color: ${props.textColor};
      z-index: 0;
    `
  }}

  &:before {
    content: ${(props) => {
      if (typeof props.children === 'string') {
        return JSON.stringify(props.children)
      }
      return null
    }};
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    -webkit-text-stroke-width: ${(props) => props.borderWidth};
    -webkit-text-stroke-color: ${(props) => props.borderColor};

    @media (max-width: 800px) {
      -webkit-text-stroke-width: ${(props) => props.borderMobileWidth};
    }
  }
`

const checkTextPropTypes = (props, propName, componentName) => {
  if (!props.textColor && !props.textGradient) {
    return new Error(
      `One of props 'textColor' or 'textGradient' was not specified in '${componentName}'.`
    )
  }
  if (props.textColor && props.textGradient) {
    return new Error(
      `Only one of props 'textColor' or 'textGradient' should be specified in '${componentName}'.`
    )
  }
  if (typeof props[propName] !== 'string') {
    return new Error(`'${propName}' prop is expected to be a string.`)
  }
  return null
}

TextWithStroke.propTypes = {
  borderWidth: PropTypes.string,
  borderMobileWidth: PropTypes.string,
  borderColor: PropTypes.string,
  children: PropTypes.string,
  textColor: checkTextPropTypes,
  textGradient: checkTextPropTypes,
}
