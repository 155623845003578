import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Urql from 'urql'
import PropTypes, { func } from 'prop-types'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import {
  Field,
  FormPropTypes,
  actions as formActions,
  selectors,
} from '@rushplay/legacy-forms'

import InputField from '../common/input-field'
import validators from '../validators'

const getAddressPredictions = `
query PredictedAddresesQuery($zipCode: String!, $country: String!, $language: String) {
  predictedAddresses(zipCode: $zipCode, country: $country, language: $language) {
    id
    address
  }}
`

const getAddressDetails = `
query AddressDetailsQuery($id: ID!, $language: String) {
  detailedAddress(id: $id, language: $language) {
    id
    city
    state
    street
    district
    zipCode
  }
}
`

const PlacesList = styled.ul`
  position: absolute;
  list-style-type: none;
  border: 1px solid #000;
  border-radius: 3px;
  white-space: nowrap;
  background-color: #fff;
  z-index: 9999;
  ${css({
    top: ['80px', null, null, '85px'],
  })}
`

const PlaceItem = styled.li`
  display: flex;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:not(:last-child) {
    border-bottom: 1px solid lightgray;
  }
`
// workaround to pass autoComplete prop to InputField
function Input(props) {
  return <InputField autoComplete="off" {...props} />
}

// Google returns records with English words `Ward` and `City` it can be misleading for Japanese users that's why we replace these words with Japanese `ku` and `shi`
// We also do not want to show "日本、" which means Japan, as user has Japan already selected in the previous field
function replaceEnglishWords(str = '') {
  return str.replace('日本、', '').replace('Ward', 'ku').replace('City', '')
}

function usePrevious(value) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

function formatZipCode(prevValue) {
  return (zipCodeValue) => {
    const zipCode = zipCodeValue.replace(/\D/g, '')

    const regex = /^(\d{3}-)?$/
    if (regex.test([prevValue]) && zipCode.length === 3) {
      return zipCode
    }

    if (zipCode.length >= 3) {
      const formattedValue = zipCode.replace(/(\d{3})(\d{0,4})/, '$1-$2')
      return formattedValue
    }
    return zipCode
  }
}

export function AutocompleteInput(props) {
  const [placeId, setPlaceId] = React.useState('')
  const [displayPredictions, setDisplayPredictions] = React.useState(false)
  const dispatch = ReactRedux.useDispatch()
  const forms = ReactRedux.useSelector((state) => state.forms)
  const zipCodeValue = selectors.value(forms, {
    form: 'sign-up',
    field: 'zip',
  })
  const isZipCodeFocused = selectors.isFocused(forms, {
    form: 'sign-up',
    field: 'zip',
  })

  const countryCodeValue = selectors.value(forms, {
    form: 'sign-up',
    field: 'countryCode',
  })

  const prevZipCodeValue = usePrevious(zipCodeValue)

  const [
    { data: { predictedAddresses } = {}, fetching: fetchingPredictions },
    executePredictionsQuery,
  ] = Urql.useQuery({
    pause: true,
    query: getAddressPredictions,
    variables: {
      country: countryCodeValue,
      zipCode: zipCodeValue,
      language: 'ja',
    },
  })

  const [
    { data: { detailedAddress } = {}, fetching: fetchingAddress },
    executeDetailsQuery,
  ] = Urql.useQuery({
    pause: true,
    query: getAddressDetails,
    variables: {
      id: placeId,
      language: 'ja',
    },
  })

  function onPlaceChange(id) {
    return () => {
      setPlaceId(id)
      setDisplayPredictions(false)
    }
  }

  React.useEffect(() => {
    const hasPredictedAddresses =
      Array.isArray(predictedAddresses) &&
      Boolean(predictedAddresses.length) &&
      !fetchingPredictions

    if (hasPredictedAddresses && zipCodeValue.length >= 3 && isZipCodeFocused) {
      setDisplayPredictions(true)
    }
  }, [predictedAddresses, zipCodeValue, placeId, isZipCodeFocused])

  React.useEffect(() => {
    if (zipCodeValue.length >= 3) {
      executePredictionsQuery()
    }

    if (displayPredictions && zipCodeValue.length < 3) {
      setDisplayPredictions(false)
    }
  }, [zipCodeValue, zipCodeValue, displayPredictions])

  React.useEffect(() => {
    executeDetailsQuery()
  }, [placeId])

  React.useEffect(() => {
    if (!fetchingAddress && detailedAddress) {
      const { state, city, district, street, zipCode } = detailedAddress
      const streetFieldValue =
        district != null
          ? `${replaceEnglishWords(city)}, ${replaceEnglishWords(
              district
            )}, ${street}`
          : `${replaceEnglishWords(city)}, ${street}`
      dispatch([
        formActions.changeValue('sign-up', 'zip', zipCode),
        formActions.changeValue('sign-up', 'city', state),
        formActions.changeValue('sign-up', 'street', streetFieldValue),
        formActions.validate('sign-up', 'zip', { status: true, errors: [] }),
        formActions.validate('sign-up', 'city', { status: true, errors: [] }),
        formActions.validate('sign-up', 'street', { status: true, errors: [] }),
      ])
    }
  }, [detailedAddress, fetchingAddress])

  function onBlur() {
    if (displayPredictions) {
      // hack to avoid closing dropdown before option from PlacesList is chosen
      setTimeout(() => setDisplayPredictions(false), 100)
    }
  }

  return (
    <Common.Box position="relative">
      <Field
        component={Input}
        group={props.group}
        hasExternalLabel={props.hasExternalLabel}
        label={props.label}
        name={props.name}
        placeholder={props.placeholder}
        tooltip={props.tooltip}
        type={props.type}
        normalize={formatZipCode(prevZipCodeValue)}
        validator={props.validator}
        onBlur={onBlur}
      />
      {displayPredictions && predictedAddresses && (
        <PlacesList>
          {predictedAddresses.map((place) => (
            <PlaceItem key={place.id} onClick={onPlaceChange(place.id)}>
              <Common.Box padding="10px" whiteSpace="nowrap">
                {replaceEnglishWords(place.address)}
              </Common.Box>
            </PlaceItem>
          ))}
          <Common.Box
            display="flex"
            padding={1}
            alignItems="center"
            justifyContent="flex-end"
            color="#5F6368"
            fontFamily="Roboto"
          >
            {/* Styles for that should be applied are described in Google's Policies for API
                  and can be found there: https://developers.google.com/maps/documentation/places/web-service/policies#font
              */}
            <Common.Text
              fontSize={0}
              fontWeight="500"
              lineHeight="14px"
              letterSpacing="0.69px"
              textTransform="uppercase"
            >
              Powered by
            </Common.Text>
            <Common.Box ml={1} as="img" alt="Google" src={props.logo} />
          </Common.Box>
        </PlacesList>
      )}
    </Common.Box>
  )
}

AutocompleteInput.propTypes = {
  field: FormPropTypes.field,
  group: PropTypes.string,
  hasExternalLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  logo: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  type: PropTypes.string.isRequired,
  validator: PropTypes.arrayOf(func),
}

export function ZipCodeInputField(props) {
  const forms = ReactRedux.useSelector((state) => state.forms)
  const countryCode = selectors.value(forms, {
    form: 'sign-up',
    field: 'countryCode',
  })

  if (countryCode.toLowerCase() !== 'jp') {
    return (
      <Field
        component={InputField}
        group={props.group}
        hasExternalLabel={props.hasExternalLabel}
        label={props.label}
        name={props.name}
        placeholder={props.placeholder}
        tooltip={props.tooltip}
        type={props.type}
        validator={props.validator}
      />
    )
  }

  return (
    <AutocompleteInput
      component={Input}
      group={props.group}
      hasExternalLabel={props.hasExternalLabel}
      label={props.label}
      logo={props.logo}
      name={props.name}
      placeholder={props.placeholder}
      tooltip={props.tooltip}
      type={props.type}
      validator={[...props.validator, validators.japaneseZipCode]}
    />
  )
}

ZipCodeInputField.propTypes = {
  field: FormPropTypes.field,
  group: PropTypes.string,
  hasExternalLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  logo: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  type: PropTypes.string.isRequired,
  validator: PropTypes.arrayOf(func),
}
