import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'

import * as SignUp from '../store/sign-up'

import PrivacyPolicy from './privacy-policy'
import Step1 from './steps/step1'
import Step2 from './steps/step2'
// NOTE: No Step3 because we removed the select-avatar step.
// NOTE: No Step4 because we moved phone number to Step2
import Success from './steps/success'
import TermsConditions from './terms-conditions'
import formatUserData from './lib/format-user-data'
import { CasiTabiCampaignTracker } from './steps/casitabi-campaign-tracker'

function UNSAFE_getReferrals() {
  const referrals = localStorage.getItem('referrals')
  try {
    return JSON.parse(referrals)
  } catch (e) {
    return referrals || ''
  }
}

function stepIndex(steps, step) {
  return R.findIndex(R.equals(step), steps)
}

export function SignupContainer(props) {
  const dispatch = ReactRedux.useDispatch()
  const fingerprint = Herz.Seon.useFingerprint()

  React.useEffect(() => {
    dispatch(SignUp.fetchCountries())
  }, [])

  React.useEffect(() => {
    if (props.params.step == null) {
      props.onNavigateTo(`/register/create-account`)
    }
  }, [props.params.step])

  function handleNextStep() {
    const nextStepIndex = stepIndex(props.steps, props.params.step) + 1
    const nextStep = props.steps[nextStepIndex]
    if (nextStep) {
      props.onNavigateTo(`/register/${nextStep}`)
    } else {
      // Validate Phone before registering,
      props.onPhoneValidation(
        props.countryCallingCode,
        props.phoneNumber,
        handleRegister
      )
    }
  }

  function handlePreviousStep() {
    const prevStepIndex = stepIndex(props.steps, props.currentPage) - 1
    const prevStep = props.steps[prevStepIndex]

    props.onNavigateTo(`/register/${prevStep}`)
  }

  function handleRegister() {
    // TODO: replace with redux-effects-localstorage
    const referrals = UNSAFE_getReferrals()
    props.onPlayerRegister(
      formatUserData(
        props.language,
        props.userData,
        referrals,
        fingerprint.value,
        {
          utmCampaign: props.utmCampaign,
          utmMedium: props.utmMedium,
          utmSource: props.utmSource,
        },
        props.clickId,
        props.subID
      ),
      props.clientType,
      props.isPhoneVerificationRequired
    )
  }

  const stepTemplate = {
    backgroundImage: props.translate('signup.background'),
    backgroundOverlay: props.translate('signup.background.overlay'),
    titleBarBackgroundImage: props.translate('signup.title-bar'),
    titleBarText: props.translate('signup.bar.title'),
  }

  const steps = {
    'create-account': (
      <Step1
        stepTemplate={stepTemplate}
        fields={R.pick(['email', 'password'], props.fields)}
        isValid={props.isStepValid('step1')}
        isCasitabi={props.isCasitabi}
        key="1"
        onNextStep={handleNextStep}
      />
    ),
    'additional-information': (
      <Step2
        stepTemplate={stepTemplate}
        countries={props.countries}
        countryCallingCode={props.userData.countryCallingCode}
        countryCode={props.countryCode}
        fields={R.pick(
          [
            'firstName',
            'lastName',
            'day',
            'month',
            'year',
            'street',
            'building',
            'apartment',
            'zip',
            'city',
            'mobile',
          ],
          props.fields
        )}
        displayGdprContent={props.displayGdprContent}
        isValid={props.isStepValid('step2')}
        isCasitabi={props.isCasitabi}
        key="2"
        phoneVerificationRequired={props.isPhoneVerificationRequired}
        onBack={handlePreviousStep}
        onNextStep={handleNextStep}
        onTermsConditionsClick={props.onTermsAndConditionsToggle}
        onPrivacyPolicyClick={props.onPrivacyPolicyToggle}
        waitingRegister={props.waitingRegister}
      />
    ),
  }

  if (fingerprint.fetching) {
    return null
  }

  if (props.authenticated && !props.isPhoneVerificationRequired) {
    return (
      <Success onSuccess={() => props.onNavigateTo('/wallet/deposit')}>
        <CasiTabiCampaignTracker />
      </Success>
    )
  }

  return (
    <React.Fragment>
      {steps[props.currentPage]}

      {props.termsConditionsVisible && (
        <TermsConditions
          visible={props.termsConditionsVisible}
          onClose={props.onTermsAndConditionsToggle}
        />
      )}
      {props.privacyPolicyVisible && (
        <PrivacyPolicy
          visible={props.privacyPolicyVisible}
          onClose={props.onPrivacyPolicyToggle}
        />
      )}
    </React.Fragment>
  )
}

export default SignupContainer

SignupContainer.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  clickId: PropTypes.string,
  subID: PropTypes.string,
  clientType: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  countryCallingCode: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
  currentPage: PropTypes.string,
  fields: PropTypes.object,
  isCasitabi: PropTypes.bool,
  displayGdprContent: PropTypes.bool,
  isStepValid: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  manualRegistration: PropTypes.bool,
  params: PropTypes.object,
  phoneNumber: PropTypes.string,
  privacyPolicyVisible: PropTypes.bool.isRequired,
  isPhoneVerificationRequired: PropTypes.bool,
  selectedCountryCode: PropTypes.string,
  termsConditionsVisible: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  userData: PropTypes.object,
  utmCampaign: PropTypes.string,
  utmMedium: PropTypes.string,
  utmSource: PropTypes.string,
  waitingRegister: PropTypes.bool.isRequired,
  steps: PropTypes.array.isRequired,
  trustlySignUpCountries: PropTypes.array.isRequired,
  onDidMount: PropTypes.func.isRequired,
  onNavigateTo: PropTypes.func.isRequired,
  onPhoneValidation: PropTypes.func.isRequired,
  onPlayerRegister: PropTypes.func.isRequired,
  onPrivacyPolicyToggle: PropTypes.func.isRequired,
  onTermsAndConditionsToggle: PropTypes.func.isRequired,
}
