import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { rem } from 'polished'

import styled from '@emotion/styled'
import { Flex, Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import Text from '../../common/text'
import {
  baseFontSizeLarge,
  baseFontSizeSmall,
  headingFont,
} from '../../constants/typography'
import { getBrand } from '../../store/app'
import { withThemeForLightBG } from '../../theme/withThemeForLightBG'

import { Background, Panel, TitleBar } from './styles'

const Title = styled.p`
  font-family: ${headingFont};
  font-size: ${rem('20px', baseFontSizeSmall)};
  flex: 1;
  line-height: 30px;
  padding-left: ${(props) => (props.hasBackIcon ? '0' : '15px')};
  padding-right: ${(props) => (props.hasBackIcon ? '15px' : '0')};
  @media screen and (min-width: 768px) {
    font-size: ${rem('30px', baseFontSizeLarge)};
  }
`
function SignUpTemplate(props) {
  return (
    <Background
      image={props.backgroundImage}
      overlayImage={props.backgroundOverlay}
    >
      <Panel showTitleBar={props.isCasiTabi} center={props.isCasiTabi}>
        {props.titleBarBackgroundImage && props.titleBarText ? (
          <TitleBar image={props.titleBarBackgroundImage}>
            {props.titleBarText}
          </TitleBar>
        ) : null}
        <Text color="black" align="center">
          <Flex>
            {props.onBack && (
              <Button
                variant="secondary"
                icon="chevron-left"
                small
                onClick={props.onBack}
              />
            )}
            <Title hasBackIcon={props.onBack}>{props.title}</Title>
          </Flex>
          <Space top="27px" bottom={8}>
            {props.children}
          </Space>
          <Button
            stretch
            disabled={props.nextStepDisabled || props.waitingRegister}
            loading={props.waitingRegister && !props.nextStepDisabled}
            onClick={props.onNextStep}
            variant="primary"
          >
            {props.nextStepLabel}
          </Button>
        </Text>
      </Panel>
    </Background>
  )
}

const connector = R.compose(
  connect((state) => ({
    isCasiTabi: getBrand(state.app) === 'casitabi',
  })),
  withThemeForLightBG,
  withTranslate
)

export default connector(SignUpTemplate)

SignUpTemplate.propTypes = {
  backgroundImage: PropTypes.string,
  backgroundOverlay: PropTypes.string,
  children: PropTypes.element.isRequired,
  isCasiTabi: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  nextStepDisabled: PropTypes.bool,
  nextStepLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleBarBackgroundImage: PropTypes.string,
  titleBarText: PropTypes.string,
  translate: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onNextStep: PropTypes.func.isRequired,
  waitingRegister: PropTypes.bool,
}
