import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as Session from '@rushplay/session'
import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

import PageNav from '../../common/page-nav'
import ProgressionContainer from '../../common/progression/progression-container'
import SearchGamesInput from '../search-games/search-games-input'
import SearchGamesResult from '../search-games/search-games-result'
import { DepositBanner } from '../../deposit-banner'

const Wrapper = styled.div`
  overflow: hidden;
  ${(props) => {
    if (props.isAtCasinoGame) {
      return `
        flex: 1;
        display: flex;
        flex-direction: column;
      `
    }
  }}
`

const Children = styled.div`
  ${(props) => props.hide && 'display:none;'};
  ${(props) => {
    if (props.isAtCasinoGame) {
      return `
        flex: 1;
        display: flex;
        flex-direction: column;
      `
    }
  }}
`

function translateLinks(translate, links) {
  return R.map(
    R.pipe(
      R.assoc('onlyActiveOnIndex', true),
      R.over(R.lensProp('label'), translate)
    ),
    links
  )
}

function scratchCategory(links, license) {
  return R.reject(
    (link) => link.label === 'casino.nav.scratch' && license === 'sga',
    links
  )
}

export function CasinoRootContainer(props) {
  const i18n = useI18n()

  const searchParams = new URLSearchParams(props.location.search)
  const searchQuery = searchParams.get('search') || ''
  const isSearchValid = searchQuery.length > 1
  const authenticated = ReactRedux.useSelector((state) =>
    Session.isSessionActive(state.session)
  )

  function handleClear() {
    props.router.replace(props.location.pathname)
  }

  function handleChange(value) {
    if (!value) {
      return props.router.replace(props.location.pathname)
    }
    const searchParams = new URLSearchParams({ search: value }).toString()
    props.router.replace(`${props.location.pathname}?${searchParams}`)
  }

  return (
    <Wrapper isAtCasinoGame={props.isAtCasinoGame}>
      {!props.isAtCasinoGame && (
        <React.Fragment>
          <ProgressionContainer />
          <PageNav
            items={translateLinks(
              i18n.translate,
              scratchCategory(props.links, props.license),
              props.links
            )}
          >
            <SearchGamesInput
              value={searchQuery}
              validSearch={isSearchValid}
              onClear={handleClear}
              onChange={handleChange}
            />
          </PageNav>
          {isSearchValid && (
            <SearchGamesResult
              searchQuery={searchQuery}
              tagPrefix={props.tagPrefix}
            />
          )}
          {authenticated && props.depositNumber === 0 && <DepositBanner />}
        </React.Fragment>
      )}
      <Children
        hide={isSearchValid && !props.isAtCasinoGame}
        isAtCasinoGame={props.isAtCasinoGame}
      >
        {props.children}
      </Children>
    </Wrapper>
  )
}

CasinoRootContainer.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ),
  children: PropTypes.node,
  license: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }),
  searchQuery: PropTypes.string,
  showSearchResult: PropTypes.bool,
  isAtCasinoGame: PropTypes.bool,
  tagPrefix: PropTypes.func,
  depositNumber: PropTypes.number,
}
