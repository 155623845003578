import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import inventory from '@rushplay/inventory'
import styled from '@emotion/styled'
import { fetchPlayerRegion } from '@rushplay/api-client'
import { getUserAvatar, isSessionActive } from '@rushplay/session'
import { keyframes } from '@emotion/core'
import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import adventure from '../../store/adventure'
import regions from '../../store/adventure/regions'
import treasures from '../../store/adventure/treasures'
import worlds from '../../store/adventure/worlds'
import {
  getActiveBoosterCampaign,
  getActiveRaceCampaign,
  isBeatBossesCampaignActive,
} from '../../store/campaigns'
import { getActiveBoosterItem } from '../../store/player'

import ProgressBar from './progress-bar'
import SvgAvatar from './../../components/svg-avatar'
import { CampaignOverlay } from './campaign-overlay'

const ProgressionWrapper = styled.div`
  width: 100%;
  padding-left: 5px;
  margin: 0 auto; /* parent decides */
  display: flex;
  align-items: center;
  max-width: 1280px; /* parent decides */
  box-sizing: border-box;
  filter: brightness(${(props) => (props.loading === 'true' ? 0 : 1)});
  opacity: ${(props) => (props.loading === 'true' ? 0.2 : 1)};
  transition: filter 0.65s, opacity 0.65s;
  @media screen and (min-width: 1280px) {
    padding-left: 16px; /* coupled with Logo styles */
  }
`

const Progression = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  @media screen and (max-width: 1279px) {
    padding-right: 5px;
  }
`

const PlayerAvatarWrapper = styled.div`
  width: 42px;
  z-index: 1;
  position: relative;
`

const BossAvatarWrapper = styled.div`
  width: 48px;
  z-index: 1;
`

const ProgressBarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-right: -6px;
  margin-left: -6px;
  z-index: 0;
`

const TreasureWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const floating = keyframes`
  from { transform: scale(1); }
  to { transform: scale(1.05); }
`

const TreasureItem = styled.div`
  position: absolute;
  left: ${(props) => props.left}%;
  top: 50%;
  margin-top: -1em;
  filter: grayscale(100%);

  ${(props) =>
    props.playerProgress >= props.left &&
    `animation: ${floating} 1s alternate infinite; filter: none; cursor: pointer;`};

  @media screen and (max-width: 1279px) {
    display: ${(props) => props.invisible && 'none'};
  }
`

const Treasure = styled.div`
  width: 2em;
  height: 2em;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props) => props.background});
`

const TreasureButton = styled.div`
  @media screen and (min-width: 1280px) {
    position: absolute;
    transform: translate(-50%, -50%);
    left: ${(props) => props.left}%;
    top: 50%;
  }

  @media screen and (max-width: 1279px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const RaceCampaignWrapper = styled.div`
  position: absolute;
  left: 7px;
  bottom: 0;
  padding: 2px 4px;
  font-size: 10px;
  background-color: #f76b1c;
  border-radius: 2px;
  margin-bottom: 5px;
  cursor: pointer;

  &:after {
    right: 97%;
    top: 50%;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-style: solid;
    border-color: transparent;
    border-right-color: #f76b1c;
    border-width: 7px;
    margin-top: -7px;
  }
`

class ProgressionContainer extends Component {
  constructor(props) {
    super(props)

    this.handleOpenOverlay = this.handleOpenOverlay.bind(this)
    this.handleCloseOverlay = this.handleCloseOverlay.bind(this)
  }

  componentWillMount() {
    this.getPlayerRegion()
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.currentWorldKey &&
      nextProps.currentWorldKey !== this.props.currentWorldKey
    ) {
      this.getPlayerRegion()
    }
  }

  getPlayerRegion() {
    if (this.props.authenticated) {
      this.props.fetchPlayerRegion({
        success: ({ value }) => {
          const worldKey = value.worldKey
          const unfoundTreasures = R.path(
            ['unfoundTreasures', 'entities', 'treasures'],
            value
          )
          const treasuresKeys = R.path(['unfoundTreasures', 'result'], value)
          const worldImages = {
            [worldKey]: {
              prizeImageUrl: value.prizeImageUrl,
              treasureImageUrl: value.treasureImageUrl,
            },
          }
          const region = {
            [value.key]: { bossAvatar: value.bossAvatarUrl },
          }

          return [
            worlds.actions.selectWorld(worldKey),
            worlds.actions.selectRegion(worldKey, value.key),
            treasures.actions.update(unfoundTreasures),
            worlds.actions.storeTreasures(worldKey, treasuresKeys),
            worlds.actions.updateImages(worldImages),
            worlds.actions.updateRegionProgress(
              worldKey,
              value.progressPercent
            ),
            worlds.actions.updateDetails({
              [worldKey]: { isLastRegion: value.lastRegion },
            }),
            regions.actions.update(region),
          ]
        },
        failure: (err) =>
          Notifications.add({ message: err.value, level: 'error' }),
        version: 1,
        normalize: true,
      })
    }
  }

  handleOpenOverlay() {
    this.setState({ campaignOpen: true })
  }

  handleCloseOverlay() {
    this.setState({ campaignOpen: false })
  }

  render() {
    if (!this.props.authenticated) {
      return null
    }

    const loading =
      typeof this.props.completion === 'undefined' ||
      typeof this.props.bossImg === 'undefined'

    return (
      <React.Fragment>
        <ProgressionWrapper loading={loading.toString()}>
          <Progression>
            <PlayerAvatarWrapper>
              <SvgAvatar
                src={this.props.playerImg}
                multiplier={this.props.boosterMultiplier}
                onClick={() => this.props.redirectTo('/adventure')}
              />
            </PlayerAvatarWrapper>

            <ProgressBarWrapper>
              <ProgressBar
                completion={this.props.completion}
                boosted={this.props.boosterMultiplier > 1}
              />
              <TreasureWrapper>
                {!this.props.isLastRegion && this.props.completion === 100 ? (
                  <Button
                    small
                    variant="primary"
                    onClick={() => this.props.redirectTo('/adventure')}
                  >
                    {this.props.translate('progressbar.bossfight')}
                  </Button>
                ) : (
                  R.map((item) => {
                    if (item === this.props.claimableTreasure) {
                      return (
                        <TreasureButton
                          key={item.progressPercent}
                          left={item.progressPercent}
                        >
                          <Button
                            small
                            onClick={() => this.props.redirectTo('/adventure')}
                          >
                            {this.props.translate('progressbar.collect')}
                          </Button>
                        </TreasureButton>
                      )
                    }

                    return (
                      <TreasureItem
                        key={item.progressPercent}
                        left={item.progressPercent}
                        invisible={this.props.claimableTreasure}
                        playerProgress={this.props.completion}
                        onClick={() =>
                          this.props.completion >= item.progressPercent &&
                          this.props.redirectTo('/adventure')
                        }
                      >
                        <Treasure background={this.props.treasureImg} />
                      </TreasureItem>
                    )
                  }, this.props.treasures)
                )}
              </TreasureWrapper>
              {this.props.raceCampaignKey && (
                <RaceCampaignWrapper onClick={this.handleOpenOverlay}>
                  {this.props.translate('progressbar.race')}
                </RaceCampaignWrapper>
              )}
            </ProgressBarWrapper>

            <BossAvatarWrapper>
              <SvgAvatar
                src={this.props.bossImg}
                promo={this.props.beatBossesCampaign}
                onClick={() => this.props.redirectTo('/adventure')}
              />
            </BossAvatarWrapper>
          </Progression>
        </ProgressionWrapper>
        <CampaignOverlay
          isOpen={R.pathOr(false, ['campaignOpen'], this.state)}
          onRequestClose={this.handleCloseOverlay}
        />
      </React.Fragment>
    )
  }
}

ProgressionContainer.defaultProps = { treasures: [] }

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPlayerRegion,
      redirectTo,
    },
    dispatch
  )
}

function mapStateToProps(state) {
  const treasures =
    adventure.selectors.getTreasures(state.adventure) ||
    ProgressionContainer.defaultProps.treasures
  const currentWorld = worlds.selectors.getCurrentWorld(state.adventure.worlds)
  const completion = currentWorld.regionProgress
  const items = inventory.items.selectors.get(state.inventory)

  const claimableTreasure = R.find(
    (item) =>
      completion >= item.progressPercent &&
      completion < item.progressPercent + 1,
    treasures
  )

  const campaign = getActiveRaceCampaign(state.campaigns)

  return {
    authenticated: isSessionActive(state.session),
    boosterMultiplier: Math.max(
      getActiveBoosterItem(items),
      getActiveBoosterCampaign(state.campaigns)
    ),
    bossImg: adventure.selectors.getBossImage(state.adventure),
    beatBossesCampaign: isBeatBossesCampaignActive(state.campaigns),
    claimableTreasure,
    completion,
    currentWorldKey: worlds.selectors.getCurrentWorld(state.adventure.worlds)
      .key,
    isLastRegion: currentWorld.isLastRegion,
    items,
    playerImg: getUserAvatar(state.session),
    raceCampaignKey: R.prop('key', campaign),
    treasures,
    treasureImg: currentWorld.treasureImageUrl,
  }
}

ProgressionContainer.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  boosterMultiplier: PropTypes.number,
  bossImg: PropTypes.string,
  beatBossesCampaign: PropTypes.bool,
  claimableTreasure: PropTypes.object,
  completion: PropTypes.number,
  currentWorldKey: PropTypes.string,
  fetchPlayerRegion: PropTypes.func.isRequired,
  isLastRegion: PropTypes.bool,
  items: PropTypes.array,
  playerImg: PropTypes.string,
  raceCampaignKey: PropTypes.string,
  redirectTo: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  treasureImg: PropTypes.string,
  treasures: PropTypes.array,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslate(ProgressionContainer))
