import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import * as Colors from '../../constants/colors'
import { textFont } from '../../constants/typography'

const StyledInput = styled.input`
  margin: initial;
  ${(props) => props.externalLabel && 'padding: initial;'};

  ${(props) => props.small && 'padding-left: 4px;'};

  ${(props) => props.label && 'padding-top: 18px;'};

  ${(props) => !props.label && !props.small && 'padding: 9px 0;'};

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${Colors.disabledGray};
      color: ${Colors.dark40};
    `};
  box-sizing: border-box;
  width: 100%;
  font-size: ${(props) => (props.currency ? 19 : 16)}px;
  font-family: ${textFont};
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  border: none;
  &:focus {
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: inset 0 0 0 9999px white;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: ${Colors.dark40};
  }
`

export function Input(props) {
  const inputRef = React.useRef(null)
  const [touched, setTouched] = React.useState(false)
  const [focused, setFocused] = React.useState(props.autoFocus)
  const value = props.value ? props.value.toString() : ''
  const valid = props.valid && props.visited && !props.suppressVisualFeedback
  const invalid = !props.valid && props.visited && !props.suppressVisualFeedback

  React.useEffect(() => {
    if (touched) {
      props.onTouch()
    }
  }, [touched])

  React.useEffect(() => {
    // Handle focus state for wrapper styles
    if (focused && inputRef.current) {
      inputRef.current.focus()
      props.onFocus(true)
    } else {
      props.onFocus(false)
    }
  }, [focused, inputRef])

  function handleBlur() {
    setFocused(false)
    props.onBlur()
  }

  return (
    <Common.Box paddingRight={props.initialType === 'password' ? '25px' : 0}>
      <StyledInput
        ref={inputRef}
        autoComplete={props.autoComplete}
        autoCorrect={props.autoCorrect}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        id={props.id}
        inputMode={props.inputMode}
        maxLength={props.maxLength}
        minLength={props.minLength}
        placeholder={props.placeholder}
        type={props.type}
        value={value}
        hasStatus={valid || invalid}
        data-testid={`${props.id}.input-field`}
        onChange={(e) => {
          setTouched(true)
          props.onChange(e)
        }}
        onBlur={handleBlur}
        onFocus={() => setFocused(true)}
        small={props.small}
        dark={props.dark}
      />
    </Common.Box>
  )
}

Input.propTypes = {
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inputMode: PropTypes.oneOf(['numeric', 'email', 'decimal', 'tel']),
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  suppressVisualFeedback: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visited: PropTypes.bool,
  valid: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onTouch: PropTypes.func,

  dark: PropTypes.bool,
  small: PropTypes.bool,
  initialType: PropTypes.string,
}
