import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { InputField } from '../forms/input-field'
import { fullWidthToHalfWidth } from '../forms/full-width-to-half-width'
import { storeUsername } from '../../store/app'

import ReturningUsernameField from './returning-login-form'

function getReturningUserStyles(props) {
  if (props.returningUserAvatar) {
    return css`
      opacity: 1;
      pointer-events: auto;
      transform: scale(1, 1);
    `
  }

  return css`
    opacity: 0;
    pointer-events: none;
    transform: scale(1.05, 1.05);
  `
}

const ReturningUserWrapper = styled.div`
  ${(props) => getReturningUserStyles(props)};
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  width: 100%;
`

function UsernameField(props) {
  const i18n = I18n.useI18n()
  const dispatch = ReactRedux.useDispatch()

  return (
    <Common.Box>
      {props.returningUsername && props.returningUserAvatar ? (
        <Common.Box>
          <ReturningUserWrapper returningUserAvatar={props.returningUserAvatar}>
            <ReturningUsernameField
              returningUserAvatar={props.returningUserAvatar}
              value={props.returningUsername}
              isBig={props.isBig}
              onClear={() => {
                dispatch(storeUsername())
              }}
            />
          </ReturningUserWrapper>
          <InputField
            type="hidden"
            scope="#/properties/emailHidden"
            initialValue={props.returningUsername}
          />
        </Common.Box>
      ) : (
        <InputField
          small={!props.isBig}
          dark
          hasExternalLabel={false}
          autoComplete="email"
          inputMode="email"
          scope="#/properties/email"
          placeholder={i18n.translate('login-form.username')}
          normalize={fullWidthToHalfWidth}
          borderGrey
          initialValue=""
        />
      )}
    </Common.Box>
  )
}

UsernameField.propTypes = {
  returningUserAvatar: PropTypes.string,
  returningUsername: PropTypes.string,
  isBig: PropTypes.bool,
}

export default UsernameField
