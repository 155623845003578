import * as R from 'ramda'
import { bind } from 'redux-effects'
import { createActions, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { fetch } from 'redux-effects-fetch'

import * as Notifications from '@rushplay/notifications'

import { timestamp } from '../redux-effects-timestamp'

const initialState = { language: '', lastUpdate: Number.NEGATIVE_INFINITY }

export const actions = createActions({
  localization: {
    UPDATE: (timestamp, language, translations) => ({
      language,
      timestamp,
      translations,
    }),
  },
})

export const reducer = handleActions(
  {
    [actions.localization.update]: (state, action) => {
      if (state.lastUpdate < action.payload.timestamp) {
        return R.mergeRight(state, {
          config: action.payload.translations,
          language: action.payload.language,
          lastUpdate: action.payload.timestamp,
        })
      } else {
        return state
      }
    },
  },
  initialState
)

export function isCurrentTranslationPresent(state, currentLanguage) {
  return Boolean(state.language === currentLanguage && state.config)
}

export function requestTranslations(host, lang) {
  const url = `${host}/locales/${lang}.json`

  return bind(
    [timestamp(), fetch(url)],
    ([timestamp, res]) =>
      actions.localization.update(timestamp, lang, res.value),
    (error) =>
      Notifications.add({
        message: error.value.message || 'error.generic',
        level: 'error',
      })
  )
}

/**
 * @param {Object} state Translations state
 * @returns {Array.<string>}
 */
export function getKeys(state) {
  return R.keys(state)
}

/**
 * @param {Object} state Translations state
 * @returns {Array.<string>}
 */
export const getPromotionBackgrounds = createSelector(
  [getKeys],
  R.filter(R.startsWith('promotions.background.layer-'))
)
