import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import HtmlContent from '../../../common/html-content'

export function MyIdentityFaq() {
  const i18n = I18n.useI18n()

  return (
    <Common.Box borderTop="1px solid currentColor" pt={10} mt={10}>
      <HtmlContent
        html={{
          __html: i18n.translate('my-identity.faq'),
        }}
      />
    </Common.Box>
  )
}

//Default export for loadable
export default MyIdentityFaq
