import React from 'react'
import ReactDOM from 'react-dom'
import { browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import i18n, { connectTo as connectI18nTo } from '@rushplay/i18n'
import { fetchConfig } from '@rushplay/api-client'

import Root from './components/root'
import getPlayerCurrency from './util/get-player-currency'
import { GetModernBrowser } from './get-modern-browser'
import { configureStore } from './store'
import { updateConfig } from './store/app'

let store = configureStore({ history: browserHistory })
let history = syncHistoryWithStore(browserHistory, store)

connectI18nTo(store, 'translations')

i18n.attachDataResolver((state) => ({
  currencyCode: getPlayerCurrency(state),
}))

function render(Component, props) {
  /**
   * This if was added to specificly check if the browser the user is using is IE6+
   * If so our modern CSS is not supported. Which will not only cause a terrible experience
   * for the user, it will also create drama whenever we're audited by someone from the stone-age.
   */

  if (/*@cc_on!@*/ false || Boolean(document.documentMode)) {
    ReactDOM.render(<GetModernBrowser />, document.querySelector('#app'))
  } else {
    ReactDOM.render(<Component {...props} />, document.querySelector('#app'))
  }
}

store.dispatch(
  fetchConfig({
    success: (payload) => {
      store.dispatch(updateConfig(payload.value))

      render(Root, { history, store })
    },
    failure: (error) => {
      store.dispatch(
        Notifications.add({ message: error.value, level: 'error' })
      )
      alert(
        'Application failed to initialize. Reload the page or contact support'
      )
    },
    version: 1,
  })
)

if (module.hot) {
  document.body.style.transition = 'opacity .3s'

  module.hot.status((status) => {
    document.body.style.opacity = status === 'idle' ? '1' : '.15'
  })

  module.hot.accept('./components/root.js', () => {
    render(Root, { history, store })
  })
  module.hot.accept('./store/index.js', () => {
    const initialState = store.getState()

    store = configureStore({ history: browserHistory, initialState })
    history = syncHistoryWithStore(browserHistory, store)

    connectI18nTo(store, 'translations')

    render(Root, { history, store })
  })
}
