import * as R from 'ramda'

import prependZero from './prepend-zero'
import removeZero from './remove-zero'

export default function formatUserData(
  language,
  userData,
  referrals,
  seon_session,
  utmObject,
  clickId,
  subID
) {
  const result = R.mergeRight(userData, {
    birthdate: `${userData.year}-${prependZero(userData.month)}-${prependZero(
      userData.day
    )}`,
    day: removeZero(userData.day),
    gender: 'Female',
    inviterUsername: null,
    language,
    month: removeZero(userData.month),
    netrefererBTag: referrals,
    affiliateClickId: clickId,
    affiliateSubId: subID,
    passwordConfirmation: userData.password,
    allowEmail: !userData.allowMarketing,
    allowSms: !userData.allowMarketing,
    utmCampaign: utmObject.utmCampaign,
    utmMedium: utmObject.utmMedium,
    utmSource: utmObject.utmSource,
    seon_session,
  })

  return result
}
