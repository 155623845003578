import * as React from 'react'
import * as R from 'ramda'
import * as ReactRouter from 'react-router'
import * as ReactRedux from 'react-redux'
import * as ReactRouterRedux from 'react-router-redux'
import PropTypes from 'prop-types'

import * as ShuftiPro from '@rushplay/shufti-pro'
import * as Common from '@rushplay/common'

import * as Colors from '../../../constants/colors'
import Spinner from '../../../common/spinner'

import { AdditionalStatus } from './additional-status'
import { AddressAndIdentityStatus } from './address-and-identity-status'
import { MyIdentityFaq } from './faq'

function Component(props) {
  const [documents] = ShuftiPro.useDocuments()

  const filteredDocuments = R.filter(
    (document) => R.contains(document.type, props.documentTypes),
    documents || []
  )
  const searchParams = new URLSearchParams(props.location.search)
  const query = searchParams.get('search') || ''

  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    if (documents && !R.isNil(query.verified) && !R.isNil(query.type)) {
      const docs = R.indexBy(R.prop('type'), documents)

      if (
        R.includes(query.type, ['identity', 'address']) &&
        R.path(['identity', 'verified'], docs) &&
        R.path(['address', 'verified'], docs) &&
        props.onSuccessful
      ) {
        props.onSuccessful()
      }

      //We want to get rid of the "selected" and "verified" query props
      dispatch(ReactRouterRedux.push('?selected=status'))
    }
  }, [documents, query.verified, query.type, props.onSuccessful])

  if (!documents) {
    return (
      <Common.Box
        display="grid"
        gridTemplateColumns={[
          'repeat(1, 1fr)',
          null,
          `repeat(${props.documentTypes.length}, 1fr)`,
        ]}
        my="3"
        mb="1"
        gridGap={[3, null, 5]}
      >
        {props.documentTypes.map((type) => (
          <Common.Box
            key={type}
            display="flex"
            borderRadius="9px"
            justifyContent="center"
            alignItems="center"
            fontSize={['48px', '64px']}
            height="223px"
            flexGrow="1"
            mb="1"
            backgroundColor={Colors.blueGreyPale}
          >
            <Spinner />
          </Common.Box>
        ))}
      </Common.Box>
    )
  }

  return (
    <React.Fragment>
      <Common.Box
        display="grid"
        gridTemplateColumns={[
          'repeat(1, 1fr)',
          null,
          `repeat(${filteredDocuments.length}, minmax(0, 1fr))`,
        ]}
        gridGap={[3, null, 5]}
      >
        {filteredDocuments.map((document) => (
          <Common.Box
            key={document.type}
            backgroundColor={Colors.darkBlue2}
            borderRadius="9px"
            color="white"
            mb={1}
            padding={3}
            display="flex"
            flex="1 1 0px"
            alignItems="center"
            flexDirection="column"
            minHeight={[null, null, '223px']}
          >
            {document.type === ShuftiPro.TYPES.ADDITIONAL ? (
              <AdditionalStatus
                {...R.omit(['type'], document)}
                onContinue={() =>
                  dispatch(
                    ReactRouterRedux.push({
                      ...location,
                      search: `?selected=${document.type}`,
                    })
                  )
                }
              />
            ) : (
              <AddressAndIdentityStatus
                {...document}
                onContinue={() =>
                  dispatch(
                    ReactRouterRedux.push({
                      ...location,
                      search: `?selected=${document.type}`,
                    })
                  )
                }
              />
            )}
          </Common.Box>
        ))}
      </Common.Box>
      <MyIdentityFaq />
    </React.Fragment>
  )
}

Component.defaultProps = {
  documentTypes: ['identity', 'address', 'additional'],
}

Component.propTypes = {
  documentTypes: PropTypes.array.isRequired,
  location: PropTypes.object,
  onSuccessful: PropTypes.func,
}

export const MyIdentityStatus = ReactRouter.withRouter(Component)
