import * as R from 'ramda'

import * as Notifications from '@rushplay/notifications'
import { actions } from '@rushplay/legacy-forms'
import {
  validateEmail as requestValidateEmail,
  validateUsername as requestValidateUsername,
} from '@rushplay/api-client'

import errorKey from './error-key'

export const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const PASSWORD_REGEXP = /^(?=.*[A-Z])(?=.*?[0-9]).{8,}$/
const SPECIAL_CHARACTERS = /^[a-z0-9]*$/i
const JAPANEESE_CHAR_REGEXP =
  /\?![\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/
export const DIGITS_REGEXP = /^\d+$/
const JAPANESE_ZIP_CODE_REGEXP = /^\d{3}[-]\d{4}$/
const NAME_REGEX = /^[\u30A0-\u30FF\uFF01-\uFF64a-zA-Z0-9\s,.'-]+$/

const validators = {
  required(form, field, value) {
    if (!value) {
      return actions.validate(form, field, {
        errors: errorKey(form, field, 'required'),
        status: false,
        value,
      })
    }
  },
  max: R.curry((max, form, field, value) => {
    const val = Number.parseFloat(value)

    if (!isNaN(val) && value > max) {
      return actions.validate(form, field, {
        errors: errorKey(form, field, 'too-large'),
        status: false,
        value,
      })
    }
  }),
  min: R.curry((min, form, field, value) => {
    const val = Number.parseFloat(value)

    if (!isNaN(val) && value < min) {
      return actions.validate(form, field, {
        errors: errorKey(form, field, 'too-small'),
        status: false,
        value,
      })
    }
  }),
  maxLength: R.curry((maxLength, form, field, value) => {
    if (value.length > maxLength) {
      return actions.validate(form, field, {
        errors: errorKey(form, field, 'too-long'),
        status: false,
        value,
      })
    }
  }),
  minLength: R.curry((minLength, form, field, value) => {
    if (value.length < minLength) {
      return actions.validate(form, field, {
        errors: errorKey(form, field, 'too-short'),
        status: false,
        value,
      })
    }
  }),
  length: R.curry((length, form, field, value) => {
    //TODO: add translation key with length included
    if (value.length !== length) {
      return actions.validate(form, field, {
        errors: errorKey(form, field, 'incorrect-length'),
        status: false,
        value,
      })
    }
  }),
  email(form, field, value) {
    if (!EMAIL_REGEXP.test(value)) {
      return actions.validate(form, field, {
        errors: errorKey(form, field, 'invalid'),
        status: false,
        value,
      })
    }
  },
  password(form, field, value) {
    if (!PASSWORD_REGEXP.test(value)) {
      return actions.validate(form, field, {
        errors: errorKey(form, field, 'invalid'),
        status: false,
        value,
      })
    }
  },
  specialCharacters(form, field, value) {
    if (!SPECIAL_CHARACTERS.test(value)) {
      return actions.validate(form, field, {
        errors: errorKey(form, field, 'no-special-characters'),
        status: false,
        value,
      })
    }
  },
  noJapaneeseCharacters(form, field, value) {
    if (JAPANEESE_CHAR_REGEXP.test(value)) {
      return actions.validate(form, field, {
        errors: errorKey(form, field, 'no-japaneese-characters'),
        status: false,
        value,
      })
    }
  },
  name(form, field, value) {
    if (!NAME_REGEX.test(value)) {
      return actions.validate(form, field, {
        errors: errorKey(form, field, 'latin-and-katakana-characters'),
        status: false,
        value,
      })
    }
  },
  digitsOnly(form, field, value) {
    if (!DIGITS_REGEXP.test(value)) {
      return actions.validate(form, field, {
        errors: errorKey(form, field, 'digits-only'),
        status: false,
        value,
      })
    }
  },
  usernameAsync(form, field, value) {
    function success(response) {
      //TODO: remove this check when backend updates validation
      return response.value.errorCode === 1
        ? actions.validate(form, field, {
            errors: errorKey(form, field, `${field}-taken`),
            status: false,
            value,
          })
        : actions.validate(form, field, { status: true, value })
    }

    const config = {
      success,
      failure: (response) =>
        Notifications.add({ message: response.value.message, level: 'error' }),
      version: 1,
    }

    return requestValidateUsername(value, config)
  },
  emailAsync(form, field, value) {
    function success(response) {
      //TODO: remove this check when backend updates validation
      return response.value.errorCode === 1
        ? actions.validate(form, field, {
            errors: errorKey(form, field, `${field}-taken`),
            status: false,
            value,
          })
        : actions.validate(form, field, { status: true, value })
    }

    const config = {
      success,
      failure: (response) =>
        Notifications.add({ message: response.value.message, level: 'error' }),
      version: 1,
    }

    return requestValidateEmail(value, config)
  },
  japaneseZipCode(form, field, value) {
    if (!JAPANESE_ZIP_CODE_REGEXP.test(value)) {
      return actions.validate(form, field, {
        errors: errorKey(form, field, 'invalid'),
        status: false,
        value,
      })
    }
  },
}

export default validators
