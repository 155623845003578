import * as ReactRedux from 'react-redux'
import * as PropTypes from 'prop-types'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Session from '@rushplay/session'
import * as Store from '@rushplay/store'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import * as Colors from '../../constants/colors'
import * as Animations from '../../constants/animations'
import Amount from '../../common/amount'
import Coin from '../../images/menu-icons/coin.png'
import Icon from '../../common/icon/icon'
import Ruby from '../../images/menu-icons/ruby.png'
import withoutLanguage from '../../util/path-without-language'

const AnimatedMenuValueComponent = styled.div`
  padding-left: 2px;
  white-space: nowrap;

  ${(props) =>
    props.animated &&
    css`
      animation: ${Animations.pulse} 1s infinite linear;
    `}
`

function UserMenuItem(props) {
  return (
    <Common.Box
      padding={['2px', null, null, '4px']}
      cursor="pointer"
      borderRadius="4px"
      display={props.hideOnMobiles ? ['none', null, null, 'block'] : 'block'}
    >
      {props.children}
    </Common.Box>
  )
}

UserMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  hideOnMobiles: PropTypes.bool,
}

const StoreSale = styled.div`
  position: absolute;
  top: 31px;
  padding: 1px 4px;
  border-radius: 2px;
  width: fit-content;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${Colors.fabMagenta};
  text-align: center;

  &::before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    border-bottom-color: ${Colors.fabMagenta};
    border-width: 4px;
    margin-left: -4px;
  }

  @media screen and (min-width: 1000px) {
    top: 53px;
    padding: 5px 8px;
    border-radius: 4px;

    &::before {
      border-width: 6px;
      margin-left: -6px;
    }
  }
`

export function UserMenu() {
  const i18n = I18n.useI18n()
  const pathnameWithoutLanguage = withoutLanguage(location.pathname)
  const balanceCents = ReactRedux.useSelector((state) =>
    Session.getBalanceCents(state.session)
  )
  const isSale = ReactRedux.useSelector((state) => Store.isSale(state.store))
  const rubiesAmount = ReactRedux.useSelector((state) =>
    Session.getRubies(state.session)
  )
  const userAvatar = ReactRedux.useSelector((state) =>
    Session.getUserAvatar(state.session)
  )

  return (
    <Common.Box
      display="flex"
      alignItems="center"
      marginRight={[null, null, null, '32px']}
    >
      <UserMenuItem>
        <Common.Link to="/wallet/deposit">
          <Common.Box
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor={
              pathnameWithoutLanguage === '/wallet/deposit'
                ? Colors.blue
                : Colors.nightDark
            }
            borderRadius="50px"
            borderColor={balanceCents < 50 ? Colors.fabGreen : Colors.darkBlue1}
            borderStyle="solid"
            borderWidth="2px"
            paddingRight={[3, null, null, 5]}
            position="relative"
            minWidth="50px"
          >
            <Common.Box
              height={['25px', null, null, '32px']}
              width={['25px', null, null, '32px']}
              margin={['4px', null, null, '8px']}
              backgroundImage={`url('${Coin}')`}
              backgroundPosition="center"
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
            />
            <AnimatedMenuValueComponent animated={balanceCents < 100}>
              {balanceCents >= 50 ? (
                <Amount fixed>{balanceCents}</Amount>
              ) : (
                i18n.translate('top-bar.deposit')
              )}
            </AnimatedMenuValueComponent>
            <Common.Space left={3}>
              <Icon
                name="plus-green"
                font-size={['20px', null, null, '28px']}
              />
            </Common.Space>
          </Common.Box>
        </Common.Link>
      </UserMenuItem>

      <UserMenuItem>
        <Common.Link to="rubystore">
          <Common.Box
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor={
              pathnameWithoutLanguage === '/rubystore'
                ? Colors.blue
                : Colors.nightDark
            }
            borderRadius="50px"
            paddingRight={[3, null, null, 5]}
            position="relative"
            minWidth="50px"
          >
            <Common.Box
              height={['25px', null, null, '32px']}
              width={['25px', null, null, '32px']}
              margin={['4px', null, null, '8px']}
              backgroundImage={`url('${Ruby}')`}
              backgroundPosition="center"
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
            />
            <Common.Text>
              <Amount currency="RUBIES">{rubiesAmount}</Amount>
            </Common.Text>
            {isSale && (
              <StoreSale>{i18n.translate('top-bar.ruby-store.sale')}</StoreSale>
            )}
          </Common.Box>
        </Common.Link>
      </UserMenuItem>

      <UserMenuItem hideOnMobiles>
        <Common.Link to="/dashboard">
          <Common.Box
            width="46px"
            height="46px"
            border="solid 3px transparent"
            borderRadius="50%"
            marginLeft="4px"
            borderColor={
              pathnameWithoutLanguage === '/dashboard' && Colors.powderBlue
            }
          >
            <Common.Box
              backgroundImage={`url(${userAvatar})`}
              width="100%"
              height="100%"
              backgroundColor={Colors.white}
              borderRadius="50%"
              backgroundSize="contain"
              backgroundPosition="center"
            />
          </Common.Box>
        </Common.Link>
      </UserMenuItem>
    </Common.Box>
  )
}
