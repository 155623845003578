import { parsers } from '@rushplay/newForms'

/**
 * @param {string} [format] JSON schema format key
 * @param {Function} [parser] Parser
 * @returns {?Function} parser if exist, otherwise nothing
 */
export function getFieldParser(format, parser) {
  return format ? parsers[format] : parser
}
